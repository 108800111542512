import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonCardbrowseVendors = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div
          className="mt-5"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {Array(6)
            .fill()
            .map((_, index) => (
              <div
                key={index}
                style={{ flexBasis: '50%', marginBottom: '20px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>
                    {/* <Skeleton square width={200} height={200} /> */}
                    <Skeleton square width={300} height={200} />
                  </div>

                  <div>
                    {/* <Skeleton width={150} height={30} /> */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {/* {Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton
                          style={{ marginRight: '10px', marginTop: '2vh' }}
                          key={index}
                          width={50}
                        />
                      ))} */}
                    </div>

                    {/* {Array.from({ length: 4 }).map((_, index) => (
                      <Skeleton
                        style={{ marginTop: '1vh' }}
                        key={index}
                        width={200}
                      />
                    ))} */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '3vh',
                      }}
                    >
                      {/* {Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton
                          style={{ marginRight: '10px' }}
                          key={index}
                          width={65}
                          height={30}
                        />
                      ))} */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <hr></hr>
        <>
          <div
            className="mt-5"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {Array(4)
              .fill()
              .map((_, index) => (
                <div
                  key={index}
                  style={{ flexBasis: '25%', marginBottom: '20px' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginBottom: '10px' }}>
                      <Skeleton circle width={200} height={200} />
                    </div>
                    <Skeleton width={150} />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '1vh',
                      }}
                    >
                      {Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton
                          style={{ marginRight: '10px' }}
                          key={index}
                          width={35}
                        />
                      ))}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '1vh',
                      }}
                    >
                      {Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton
                          style={{ marginRight: '10px' }}
                          key={index}
                          width={65}
                          height={30}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <hr />
        </>
        <>
          <div
            className="mt-5"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {Array(27)
              .fill()
              .map((_, index) => (
                <div
                  key={index}
                  style={{
                    flexBasis: '33%',
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ marginRight: '20px' }}>
                    <Skeleton circle width={100} height={100} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Skeleton width={150} />
                  </div>
                </div>
              ))}
          </div>
        </>
      </div>
    </div>
  );
};

export default SkeletonCardbrowseVendors;
