import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { auth, google, facebook, apple } from "./firebase";

import axios from "axios";
import { signInWithPopup } from "firebase/auth";
import { Modal, ModalBody } from "reactstrap";
import "react-responsive-modal/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Rings } from 'react-loader-spinner';
import signInGoogle from "../assets/images/googlesocialsignin.png";
import signInApple from "../assets/images/applesigninsocial.png";
import { ToastContent } from "../components/Toast";
import AuthService from "../services/AuthService";
import "./home.css";
import { ApiRoute } from "../util/constants";
import CloseIcon from "../assets/images/closeButton.svg";

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signinTab: true,
      signupTab: false,
      loading: false,
      forgotPassword: false,
      // recaptchaToken: [],
    };
  }
  fetchData = () => {
    this.setState({ loading: true });

    //Faking API call here
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };

  handleForgotPasswordClick = () => {
    this.setState({ forgotPassword: true });
  };
  handleGoBackClick = () => {
    this.setState({ forgotPassword: false });
  };

  handleResetPasswordClick = async (event) => {
    toast.dismiss();
    event.preventDefault();

    var email = event.target.elements.email.value;

    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (validateEmail) {
      toast(() => ToastContent("Please wait...."), {
        toastId: "infoToast",
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
      let data = await AuthService.forgotPassword(email);
      toast.dismiss();
      if (data.success) {
        toast.success("Password reset instructions sent to your email");
        this.setState({ forgotPassword: false });
      } else {
        toast(() => ToastContent(data.error), {
          toastId: "dangerToast",
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } else {
      toast(() => ToastContent("Please enter valid Email"), {
        toastId: "dangerToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
    return;
  };

  // loginSocial = async (provider) => {
  //   signInWithPopup(auth, provider)
  //     .then((res) => {
  //       console.log('RESPONSE', res);
  //     })
  //     .catch((err) => {
  //       console.log('Error', err);
  //     });
  // };
  signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, google);
      const user = res.user;
      await axios
        .post(`${ApiRoute.login}`, {
          email: user.email,
          provider: "google",
        })
        .then((response) => {
          console.log("Response: ", response);
          // console.log('checking token in response ', response.data.token);
          if (response.status === 200) {
            toast(() => ToastContent("Successful logged in"), {
              toastId: "successToast",
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
            localStorage.setItem("userToken", btoa(response.data.token));
            localStorage.setItem("userData", btoa(JSON.stringify(response)));
            this.props.loginModalClose(1);
          } else {
            toast(() => ToastContent("NO USER FOUND"), {
              toastId: "dangerToast",
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            });
          }
          // if (response.status === 401) {
          //   toast(() => ToastContent('INVALID CREDENTIALS'), {
          //     toastId: 'dangerToast',
          //     hideProgressBar: false,
          //     type: toast.TYPE.ERROR,
          //     autoClose: 1000,
          //   });
          // }
        });
    } catch (err) {
      // CATCH ERROR SAYING SIGNUP POPUP CLOSED
      toast(() => ToastContent("NO USER FOUND"), {
        toastId: "dangerToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  signInWithFacebook = async () => {
    try {
      const res = await signInWithPopup(auth, facebook);
      toast.dismiss();
      const user = res.user;
      await axios
        .post(`${ApiRoute.login}`, {
          email: user.email,
          provider: "facebook",
        })

        .then((response) => {
          if (response.status === 200) {
            toast(() => ToastContent("Successful logged in"), {
              toastId: "successToast",
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
            localStorage.setItem("userToken", btoa(response.data.token));
            localStorage.setItem("userData", btoa(JSON.stringify(response)));
            this.props.loginModalClose(1);
          } else {
            toast(() => ToastContent("NO USER FOUND"), {
              toastId: "dangerToast",
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            });
          }
        });
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: "dangerToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  signInWithApple = async () => {
    try {
      const res = await signInWithPopup(auth, apple);
      toast.dismiss();
      const user = res.user;
      await axios
        .post(`${ApiRoute.login}`, {
          email: user.email,
          provider: "apple",
        })

        .then((res) => {
          if (res.status === 200) {
            toast(() => ToastContent("Successfully Logged In "), {
              toastId: "successToast",
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
            localStorage.setItem("userToken", btoa(res.data.token));
            localStorage.setItem("userData", btoa(JSON.stringify(res)));
            this.props.loginModalClose(1);
          } else {
            toast(() => ToastContent("NO USER FOUND"), {
              toastId: "dangerToast",
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            });
          }
        });
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: "dangerToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };
  submitLoginForm = async (event) => {
    toast.dismiss();
    event.preventDefault();

    var email = event.target.elements.email.value;
    var password = event.target.elements.password.value;

    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);
    // && this.state.recaptchaToken
    if (validateEmail && password) {
      toast(() => ToastContent("Please wait...."), {
        toastId: "infoToast",
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });

      let loginData = await AuthService.login(
        email,
        password
        // this.state.recaptchaToken
      );
      toast.dismiss();
      // if (loginData.success) {
      //   toast(() => ToastContent('Successfully Logged In'), {
      //     toastId: 'successToast',
      //     hideProgressBar: false,
      //     type: toast.TYPE.SUCCESS,
      //     autoClose: 1000,
      //   });
      //   console.log('TOKEN', loginData.token);
      //   await localStorage.setItem('userToken', btoa(loginData.token));
      //   await localStorage.setItem(
      //     'userData',
      //     btoa(JSON.stringify(loginData.user))
      //   );
      //   // localStorage.removeItem('_grecaptcha');
      //   this.props.loginModalClose(1);
      // }
      if (loginData.success) {
        toast(() => ToastContent("Successfully Logged In"), {
          toastId: "successToast",
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        console.log("TOKEN", loginData.token);

        // Encode the token using btoa
        const tokenBase64 = btoa(loginData.token);

        // Encode the user data using btoa after converting it to UTF-8
        const userDataUtf8 = unescape(
          encodeURIComponent(JSON.stringify(loginData.user))
        );
        const userDataBase64 = btoa(userDataUtf8);

        // Store the encoded token and user data in localStorage
        await localStorage.setItem("userToken", tokenBase64);
        await localStorage.setItem("userData", userDataBase64);

        // localStorage.removeItem('_grecaptcha');
        this.props.loginModalClose(1);
      } else {
        toast(() => ToastContent(loginData.error), {
          toastId: "dangerToast",
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } else {
      toast(() => ToastContent("Please enter valid credentials"), {
        toastId: "dangerToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
    return;
  };
  // handleRecaptchaChange = (token) => {
  //   this.setState({ recaptchaToken: token });
  // };

  // handleRecaptchaExpire = () => {
  //   this.setState({ recaptchaToken: null });
  // };

  componentDidMount() {}
  render() {
    const { loading, forgotPassword } = this.state;

    return (
      <>
        <ToastContainer />
        <Modal
          isOpen={this.props.signinupModal}
          id="sign-in-dialog"
          onClose={() => {
            this.props.loginModalClose();
            this.setState({
              signinTab: true,
              signupTab: false,
            });
          }}
        >
          <ModalBody style={{ backgroundColor: "#fff" }}>
            {/* {this.state.signinTab ? ( */}
            <div className="tab-content">
              {forgotPassword ? (
                <h1 className="text-center  vendorName">Forgot Password</h1>
              ) : (
                <h1 className="text-center  vendorName">
                  Login To Your Vendor Account{" "}
                </h1>
              )}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.props.loginModalClose();
                  this.setState({
                    signinTab: true,
                    signupTab: false,
                    forgotPassword: false,
                  });
                }}
              >
                <img src={CloseIcon} alt="closeButton" />
              </button>

              {forgotPassword ? (
                <>
                  <form onSubmit={this.handleResetPasswordClick}>
                    <img
                      className="d-flex mx-auto"
                      src={process.env.PUBLIC_URL + "/images/forgot.png"}
                      width={200}
                      height="auto"
                      alt="Forgot"
                    ></img>

                    <input
                      className="input-text w-75"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      defaultValue={""}
                      autoComplete="false"
                      onInput={(event) =>
                        (event.target.value = event.target.value.toLowerCase())
                      }
                    />
                    <button
                      className="bg-warning w-50 border-0 text-light fs-4 p-2 mt-3 justify-content-center d-flex mx-auto"
                      type="submit"
                      name="reset-password"
                      value="Reset Password"
                    >
                      Send Reset Link
                    </button>
                    <button
                      className="bg-dark w-50 border-0 text-light fs-4 p-2 mt-3 justify-content-center d-flex mx-auto"
                      onClick={this.handleGoBackClick}
                      value="Reset Password"
                    >
                      Back To Login Page
                    </button>
                  </form>
                </>
              ) : (
                <>
                  <form className="login" onSubmit={this.submitLoginForm}>
                    <p className="form-row form-row-wide">
                      <input
                        type="text"
                        className="input-text mt-4"
                        name="email"
                        id="email"
                        defaultValue={""}
                        placeholder="Enter Email"
                        autoComplete="false"
                        onInput={(event) =>
                          (event.target.value =
                            event.target.value.toLowerCase())
                        }
                      />
                    </p>

                    <div className="form-row form-row-wide">
                      <>
                        <input
                          className="input-text"
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Enter Password"
                          defaultValue={""}
                          autoComplete="false"
                        />
                      </>
                    </div>
                    {/* <div className="d-flex mx-auto justify-content-center mt-3">
                    <ReCAPTCHA
                      sitekey="6LcRwfglAAAAAPavkk-U_CgSm8EMRECqXdQQLzVH"
                      // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      // onChange={handleRecaptcha}
                      onChange={this.handleRecaptchaChange}
                      onExpired={this.handleRecaptchaExpire}
                    />
                  </div> */}
                    <div className="customFormGroup">
                      <div className="d-flex mx-auto w-100 mt-5">
                        <button
                          className="signUpBtn  justify-content-center  d-flex mx-auto"
                          type="submit"
                          name="Register"
                          value="Login"
                          onClick={this.fetchData}
                        >
                          {loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          )}

                          {!loading && <span>Login</span>}
                        </button>
                      </div>
                    </div>
                    <h4
                      className="lost_password text-center mt-2 text-dark "
                      href="#"
                      onClick={this.handleForgotPasswordClick}
                      style={{ cursor: "pointer" }}
                    >
                      Forgot Password?
                    </h4>
                    <p className="formShortInfo mx-5 ">
                      By logging in, you agree with the Terms of Service and
                      Privacy Policy
                    </p>

                    <div className="signUpWithBox ">
                      <span>Or</span>
                    </div>
                  </form>
                  <div className="socialLoginBoxes ">
                    <button
                      onClick={() => this.signInWithApple(apple)}
                      className="appleLogin"
                    >
                      <img width={300} src={signInApple} alt="" />
                    </button>
                    <button onClick={() => this.signInWithGoogle(google)}>
                      <img width={300} src={signInGoogle} alt="" />
                    </button>
                  </div>
                  <p className="formShortInfo mx-5 mt-5">
                    We dont post anything to your social media, they are just
                    used to create your account.
                  </p>
                </>
              )}
            </div>
            {/* <div className="socialLoginBoxes ">
              <button
                onClick={() => this.signInWithApple(apple)}
                className="appleLogin"
              >
                <img
                 
                  src="https://www.beta.eventmassi.com/_next/static/media/apple-icon.18d9951b.svg"
           
                  alt="Apple Logo"
                />
                <span>Apple ID</span>
              </button>
              <button
                onClick={() => this.signInWithGoogle(google)}
            
              >
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/g-plus-icon.c93f23d6.svg"
                  alt="Google Logo"
                />
                <span>Google</span>
              </button>
              <button
                      onClick={() => this.signInWithFacebook(facebook)}
                      className="facebookLogin"
                    >
                      <img
                        src="https://www.beta.eventmassi.com/_next/static/media/fb-fav-icon.0e844166.svg"
                        alt=""
                      />
                      <span>Facebook</span>
                    </button>
            </div> */}
            {/* ) : (
              {}
            )} */}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default LoginModal;
