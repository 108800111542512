import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeltonCardContactUs = () => {
  return (
    <div className="container margin-top-70 margin-bottom-50">
      <Skeleton className="mx-auto d-flex" width={400} height={200} />

      <h1 className="Massi">
        <Skeleton width={200} />
      </h1>
      <div className="container ">
        <p className="text-center">
          <Skeleton width={390} />
        </p>
      </div>
      <div className="row mt-5">
        <div className="col-md-4">
          <div className="sidebar-textbox mx-lg-4 text-light">
            <h4 className="headline text-light">
              <Skeleton width={200} />

              <Skeleton width={300} height={20} />
            </h4>

            <ul className="contact-details">
              <li>
                <i className="im text-light im-icon-Map-Marker2" />
                <h3 className="text-light mt-3">
                  <Skeleton width={200} />
                </h3>
              </li>
              <li>
                <i className="im text-light im-icon-Envelope"></i>
                <h3 className="text-light mt-3">
                  <Skeleton width={200} />
                </h3>
              </li>
              <li>
                <i className="im text-light im-icon-Telephone"></i>
                <h3 className="text-light mt-3">
                  <Skeleton width={200} />
                </h3>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-8 details">
          <section id="contact">
            <form>
              <div className="row mt-5">
                <div className="col-md-6">
                  <div>
                    <label>
                      First Name<sup>*</sup>
                    </label>
                  </div>
                  <Skeleton width={350} height={20} />
                </div>
                <div className="col-md-6">
                  <div>
                    <label>
                      Last Name<sup>*</sup>
                    </label>
                  </div>
                  <Skeleton width={350} height={20} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <label>
                      Email <sup>*</sup>
                    </label>
                  </div>
                  <Skeleton width={350} height={20} />
                </div>
                <div className="col-md-6">
                  <div>
                    <label>
                      Phone<sup>*</sup>
                    </label>
                  </div>
                  <Skeleton width={350} height={20} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="fw-bold">Category</label>
                  <div className="radioButtonGrp">
                    <label className="customRadioBtnContainer">
                      <input type="radio" name="category" />
                      <Skeleton width={120} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="customRadioBtnContainer">
                      <input type="radio" name="category" />
                      <Skeleton width={120} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="customRadioBtnContainer">
                      <input type="radio" name="category" />
                      <Skeleton width={120} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="customRadioBtnContainer">
                      <input type="radio" name="category" />
                      <Skeleton width={120} />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <label className="mt-5">
                  Message<sup>*</sup>
                </label>
                <Skeleton height={200} />
              </div>

              <input
                type="submit"
                className="submit button mt-5"
                id="submit"
                value="Submit Now!"
              />
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SkeltonCardContactUs;
