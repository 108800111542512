import React, { useState, useEffect } from "react";
import "./signup.css";
import Image from "./Rectangle 486.png";
import Divider from "./Divider.png";
import axios from "axios";
import { ToastContent } from "../components/Toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { ApiRoute } from "../util/constants";

import VendorService from "../services/VendorService";
function NewUserDataPage(props) {
  const [mobileNumber, setMobileNumber] = useState("");
  const [business, setBusiness] = useState("");
  const [vendorCategory, setVendorCategory] = useState("");
  const [vendorState, setVendorState] = useState("");

  const [categoryData, setCategoryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [selectedCategoryID, setSelectedCategoryID] = useState("");
  const [selectedStateID, setSelectedStateID] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    axios.get(`${ApiRoute.getAllCategories}`).then((response) => {
      setCategoryData(response.data.data);
      console.log(response.data.data);
    });
  }, []);
  useEffect(() => {
    axios.get(`${ApiRoute.getAllStates}`).then((response) => {
      setStatesData(response.data.data);
      console.log(response.data.data);
    });
  }, []);

  const getProfileData = async () => {
    try {
      let data = await VendorService.getLoginVendorProfileData();
      console.log("DATA", data);
      return data.data;
    } catch (err) {
      console.error("Profile error msg: ", err.message);
      return false;
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    toast.dismiss();

    if (!business.length) {
      toast(() => ToastContent("Vendor Name required."), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      setIsSubmitting(false); // Set isSubmitting to false
      return false;
    }
    if (!selectedCategoryID.length) {
      toast(() => ToastContent("Category required."), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      setIsSubmitting(false); // Set isSubmitting to false
      return false;
    }
    if (!selectedStateID.length) {
      toast(() => ToastContent("State required."), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      setIsSubmitting(false); // Set isSubmitting to false
      return false;
    }
    const profile_url = business
      .toLowerCase()
      .replace(/[^\w\s@]/gi, "")
      .replace(/\s+/g, "-")
      .replace(/-{2,}/g, "-");

    const params = {
      phone: mobileNumber,
      business,
      categories: selectedCategoryID,
      states: selectedStateID,
      profile_url,
    };

    console.log("DATA", params);
    // await VendorService.updateVendorInfo(params);
    try {
      let updateProfileInfo = await VendorService.updateVendorInfo(params);
      toast.dismiss();
      if (updateProfileInfo.success) {
        localStorage.setItem(
          "userData",
          btoa(JSON.stringify(updateProfileInfo.data))
        );

        // toast(() => ToastContent('Profile updated successfully'), {
        //   toastId: 'successToast',
        //   hideProgressBar: false,
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 1000,
        // });
        props.navigate("/signup/new/image");
      } else {
        toast(() => ToastContent("Error while updating profile"), {
          toastId: "dangerToast",
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          // autoClose: 2000,
        });
      }
    } catch (err) {
      toast.dismiss();
      toast(() => ToastContent("Error while updating profile"), {
        toastId: "dangerToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        // autoClose: 2000,
      });
    } finally {
      setIsSubmitting(false);
    }

    toast.dismiss();
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleVendorNameChange = (e) => {
    setBusiness(e.target.value);
  };

  // const handleVendorCategoryChange = (e) => {
  //   const selectedCategory = e.target.value;
  //   setVendorCategory(selectedCategory);

  //   // Find the category ID based on the selected category name
  //   const selectedCategoryObj = categoryData.find(
  //     (categoryItem) => categoryItem.title === selectedCategory
  //   );
  //   if (selectedCategoryObj) {
  //     setSelectedCategoryID(selectedCategoryObj._id);
  //   }
  // };
  const handleVendorCategoryChange = (selectedCategories) => {
    setVendorCategory(selectedCategories);

    // Find the IDs of the selected categories
    const selectedCategoryIDs = selectedCategories.map((selectedCategory) => {
      const selectedCategoryObj = categoryData.find(
        (categoryItem) => categoryItem.title === selectedCategory.label
      );
      return selectedCategoryObj ? selectedCategoryObj._id : null;
    });

    // Filter out any null values and store the selected IDs
    setSelectedCategoryID(selectedCategoryIDs.filter((id) => id !== null));
  };

  // const handleStateChange = (e) => {
  //   const selectedState = e.target.value;
  //   setState(selectedState);

  //   // Find the state ID based on the selected state name
  //   const selectedStateObj = statesData.find(
  //     (stateItem) => stateItem.name === selectedState
  //   );
  //   if (selectedStateObj) {
  //     setSelectedStateID(selectedStateObj._id);
  //   }
  // };
  const handleVendorStateChange = (selectedStates) => {
    setVendorState(selectedStates);

    // Find the IDs of the selected states
    const selectedStateIDs = selectedStates.map((selectedState) => {
      const selectedStateObj = statesData.find(
        (stateItem) => stateItem.name === selectedState.label
      );
      return selectedStateObj ? selectedStateObj._id : null;
    });

    // Filter out any null values and store the selected IDs
    setSelectedStateID(selectedStateIDs.filter((id) => id !== null));
  };

  return (
    <>
      <div className="container-fluid">
        <div className="text-container">
          <div className="text-side">
            <p
              style={{
                fontWeight: "600",
                fontSize: "23px",

                textAlign: "center",
                maxWidth: "39rem",
                margin: "0 auto",
              }}
            >
              Your next step to more leads and more bookings, create your Event
              Massi vendor Profile!
            </p>
            <form
              className="register"
              style={{ marginTop: "50px" }}
              onSubmit={handleSubmit}
            >
              <input
                type="number"
                className="input-text-signup"
                name="mobileNumber"
                id="mobileNumber"
                placeholder="Enter Mobile Number"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
              />

              <input
                type="text"
                className="input-text-signup"
                name="vendorName"
                id="vendorName"
                placeholder="Enter Vendor Name *"
                value={business}
                onChange={handleVendorNameChange}
              />

              {/* <select
                className="input-text-signup"
                name="vendorCategory"
                id="vendorCategory"
                value={vendorCategory}
                onChange={handleVendorCategoryChange}
              >
                <option value="">Select Vendor Category *</option>
                {categoryData.map((categoryItem) => (
                  <option key={categoryItem._id} value={categoryItem.title}>
                    {categoryItem.title}
                  </option>
                ))}
              </select> */}

              <Select
                className="input-text-signup-categories "
                isMulti
                name="vendorCategory"
                id="vendorCategory"
                options={categoryData.map((categoryItem) => ({
                  value: categoryItem.title,
                  label: categoryItem.title,
                }))}
                value={vendorCategory}
                onChange={handleVendorCategoryChange}
                placeholder="Select Vendor Category *"
              />
              <Select
                className="input-text-signup-categories"
                isMulti
                name="vendorState"
                id="vendorState"
                options={statesData.map((stateItem) => ({
                  value: stateItem.name,
                  label: stateItem.name,
                }))}
                value={vendorState}
                onChange={handleVendorStateChange}
                placeholder="Select Vendor State *"
              />

              {/* <select
                className="input-text-signup"
                name="state"
                id="state"
                value={state}
                onChange={handleStateChange}
              >
                <option value="">Select Vendor State *</option>
                {statesData.map((stateItem) => (
                  <option key={stateItem._id} value={stateItem.name}>
                    {stateItem.name}
                  </option>
                ))}
              </select> */}

              <div className="customFormGroup">
                <div className="">
                  <button
                    type="button"
                    className="signUpBtn-signup-NewUser bg-light text-dark border border-2 d-flex mx-auto"
                    onClick={() => {
                      // props.navigate('/dashboard');
                      window.location = "/dashboard";
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className=" ">
                  <button
                    type="submit"
                    className="signUpBtn-signup-NewUser d-flex mx-auto"
                    name="register"
                    value="Register"
                    disabled={isSubmitting} // Disable the button when submitting
                  >
                    {isSubmitting ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="box-container">
          <div className="centered-content">
            <img src={Image} alt="Image" className="box-image" />
          </div>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <img
          src={Divider}
          alt="Divider"
          style={{ width: "100%", position: "absolute", bottom: -3 }}
        />
      </div>
    </>
  );
}

// export default NewUserDataPage;

function HeaderNewUserDataPage(props) {
  let navigate = useNavigate();
  return <NewUserDataPage {...props} navigate={navigate} />;
}
export default HeaderNewUserDataPage;
