import React, { useState, useEffect } from 'react';
import AuthService from '../../services/AuthService';
import './Completion.css';
import Moment from 'react-moment';
import { ApiRoute } from '../../util/constants';
import SkeletonCardOrderCompletion from '../../components/Skeltons/SkeletonCardOrderCompletion';
const Completion = () => {
  const [APIData, setAPIData] = useState([]);
  const [APIData2, setAPIData2] = useState([]);
  const [APIData3, setAPIData3] = useState([]);
  const [APIData4, setAPIData4] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let userData = localStorage.getItem('userData')
      ? JSON.parse(atob(localStorage.getItem('userData')))
      : null;
    if (userData !== null) {
      userData.isPaid = true;
      localStorage.setItem('userData', btoa(JSON.stringify(userData)));
    }
  });
  useEffect(() => {
    setIsLoading(true);
    let token = AuthService.getToken();
    fetch(`${ApiRoute.getLoginVendorProfile}`, {
      headers: {
        Authorization: `Bearer ` + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setAPIData(json.payment);
        setAPIData2(json.payment.subscription);
        setAPIData3(json.payment.subscription.latest_invoice.lines.data[0]);
        setAPIData4(
          json.payment.subscription.latest_invoice.payment_intent
            .payment_method_types[0]
        );
        setIsLoading(false);
      });
  }, []);

  return (
    <div class="container rounded bg-white">
      {isLoading ? (
        <>
          <SkeletonCardOrderCompletion />
        </>
      ) : (
        <div class="bg row d-flex justify-content-center pb-5">
          <div class="col-sm-4 col-md-4 ml-1">
            <div class="bg-white p-2 d-flex flex-column">
              <div className="payment-confirm">
                <img
                  className="mt-3"
                  src="../images/payment.png"
                  alt="Payment Icon"
                  height="300"
                  width="300"
                />

                <h1>Payment succeeded!</h1>
                <a href="/">RETURN HOME</a>
                <span>
                  If you have any problems <a href="/contact_us">Contact-us</a>{' '}
                </span>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-sm-7 mt-5  mobile">
            <div class="bg-white shadow-lg border border-3 rounded-4  p-5 d-flex flex-column">
              <div class="pt-2">
                <h2 className="fw-bolder card-title mx-auto d-flex justify-content-center text-center">
                  Order Details
                </h2>
                <hr></hr>
              </div>
              <div class="d-flex">
                <h3 class="col-6 fw-bolder lh-lg">Email</h3>

                <div class="ml-auto">
                  <h3>{APIData.email}</h3>
                </div>
              </div>
              <div class="d-flex">
                {/* <h3 class="col-6 fw-bolder lh-lg">Package Name</h3> */}
                <h3 class="col-6 fw-bolder lh-lg">Subscription</h3>
                <div class="ml-auto">
                  <h3>{APIData3.description}</h3>
                </div>
              </div>
              <div class="d-flex">
                <h3 class="col-6 fw-bolder lh-lg">Order Date</h3>
                <div class="ml-auto">
                  <h3 className="lh-lg">
                    <Moment format="YYYY/MM/DD, HH:mm:ss [GMT]">
                      {new Date(APIData.start_date * 1000)}
                    </Moment>
                  </h3>
                </div>
              </div>

              <div class="d-flex">
                <h3 class="col-6 fw-bolder lh-lg"> Subscription Start Date</h3>
                <h3 className="lh-lg">
                  <Moment format="YYYY/MM/DD, HH:mm:ss [GMT]">
                    {new Date(APIData2.current_period_start * 1000)}
                  </Moment>
                </h3>
              </div>
              <div class="d-flex">
                <h3 class="col-6 fw-bolder lh-lg">Subscription End Date</h3>
                <h3 className="lh-lg">
                  <Moment format="YYYY/MM/DD, HH:mm:ss [GMT]">
                    {new Date(APIData2.current_period_end * 1000)}
                  </Moment>
                </h3>
              </div>
              <div class="d-flex">
                <h3 class="col-6 fw-bolder lh-lg">Payment Type</h3>
                <div class="ml-auto  ">
                  <h3 className="lh-lg text-capitalize"> {APIData4}</h3>
                </div>
              </div>
              <hr></hr>
              <div class="d-flex">
                <h2 class="col-6 fw-bolder ">Total</h2>
                <div class="ml-auto ">
                  <h2 className="fw-bolder ">${APIData.amount}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Completion;
