import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonCardOrderCompletion = () => {
  return (
    <div className="container rounded bg-white">
      <div style={{ display: 'flex', justifyContent: 'center' }}></div>
      <div className="bg row d-flex justify-content-center pb-5">
        <div className="col-sm-4 col-md-4 ml-1">
          <div className="bg-white p-2 d-flex flex-column">
            <div className="payment-confirm">
              <Skeleton height={300} width={300} className="mt-3" />
              <h1>Payment succeeded!</h1>
              <a href="/">RETURN HOME</a>
              <span>
                If you have any problems <a href="/contact_us">Contact-us</a>
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-7 mt-5  mobile">
          <div className="bg-white shadow-lg border border-3 rounded-4  p-5 d-flex flex-column">
            <div className="pt-2">
              <h2 className="fw-bolder card-title mx-auto d-flex justify-content-center text-center">
                Order Details
              </h2>
              <hr />
            </div>
            <div className="d-flex">
              <h3 className="col-6 fw-bolder lh-lg">Email</h3>
              <div className="ml-auto">
                <Skeleton height={30} width={200} />
              </div>
            </div>
            <div className="d-flex">
              <h3 className="col-6 fw-bolder lh-lg">Subscription</h3>
              <div className="ml-auto">
                <Skeleton height={30} width={200} />
              </div>
            </div>
            <div className="d-flex">
              <h3 className="col-6 fw-bolder lh-lg">Order Date</h3>
              <div className="ml-auto">
                <Skeleton height={30} width={200} />
              </div>
            </div>
            <div className="d-flex">
              <h3 className="col-6 fw-bolder lh-lg">Subscription Start Date</h3>
              <div className="ml-auto">
                <Skeleton height={30} width={200} />
              </div>
            </div>
            <div className="d-flex">
              <h3 className="col-6 fw-bolder lh-lg">Subscription End Date</h3>
              <div className="ml-auto">
                <Skeleton height={30} width={200} />
              </div>
            </div>
            <div className="d-flex">
              <h3 className="col-6 fw-bolder lh-lg">Payment Type</h3>
              <div className="ml-auto">
                <Skeleton height={30} width={200} />
              </div>
            </div>
            <hr />
            <div className="d-flex">
              <h2 className="col-6 fw-bolder">Total</h2>
              <div className="ml-auto">
                <Skeleton height={40} width={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCardOrderCompletion;
