import React from "react";
import { URLS } from "../util/constants";
import ReactPlayer from "react-player";
import ImageGallery from "react-image-gallery";
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import defaultVideoThumbnail from "./play2.png";

class VendorProfileGallery extends React.Component {
  state = {
    items: [],
  };

  componentDidMount() {
    let itemsArray = [];
    this.props?.assetsData?.forEach((asset) => {
      let url = "";
      if (asset.path && asset.type === "image") {
        url = URLS.S3BucketURL + asset.path;
        itemsArray.push({ original: url, thumbnail: url });
      } else if (asset.url && asset.type === "image") {
        url = URLS.S3BucketURL + asset.url;
        itemsArray.push({ original: url, thumbnail: url });
      } else if (asset.type === "video") {
        url = asset.path
          ? URLS.S3BucketURL + asset.path
          : asset.url
          ? URLS.S3BucketURL + asset.url
          : "";

        console.log("VIDEO PATH", url);

        if (url !== "") {
          const thumbnail = asset.Videothumbnail
            ? URLS.S3BucketURL + asset.Videothumbnail
            : defaultVideoThumbnail;

          itemsArray.push({
            original: url,
            thumbnail: thumbnail,
            renderItem: this.renderVideo.bind(this, url),
          });
        }
      }
    });
    this.setState({ items: itemsArray });
  }

  renderVideo = (url) => {
    return (
      <div className="image-gallery-image">
        <ReactPlayer
          url={url}
          controls={true}
          width={"100%"}
          height={"340px"}
        />
      </div>
    );
  };

  render() {
    const { assetsData } = this.props;

    return (
      <>
        {this.state.items.length > 0 && assetsData.length > 0 ? (
          <div className="mfp-gallery-container">
            <ImageGallery
              items={this.state.items}
              showLightboxThumbnails={true}
              showThumbnails={true}
              lazyLoad={true}
              showPlayButton={false}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default VendorProfileGallery;
