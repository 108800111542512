//local routes of the website
export const Local_routes = {
  home: "/",
  reset_password: "/reset-password/:token",
  explore: "/explore",
  massi: "/massi",
  blogs: "/blogs",
  about_us: "/about_us",
  policy: "/policy",
  vendors: "/browse-vendors",
  contact_us: "/contact_us",
  vendor_detail: "/browse-vendor",
  dashboard: "/dashboard",
  privacy: "/privacy",
  cookies: "/cookies",
  terms: "/terms",
  completion: "/completion",
  signup: "/signup",
  newUserData: "signup/new",
  newUserProfileImage: "signup/new/image",
  finishProfile: "signup/new/finish",
};

//api routes
const BaseUrl = "https://api.eventmassi.com/";
// const BaseUrl = 'http://localhost:5000/';
const ApiPoint = BaseUrl + "api/v2/";

const S3BucketURL = "https://massi-bucket.s3.amazonaws.com/";

export const ApiRoute = {
  massiTopPicks: ApiPoint + "top-vendors",
  browseVendors: ApiPoint + "browse-vendors",
  vendorProfile: ApiPoint + "vendor-profile",
  login: ApiPoint + "auth/login",
  register: ApiPoint + "auth/register",
  forgotPassword: ApiPoint + "auth/forgotpassword",
  resetPassword: ApiPoint + "auth/resetPassword",
  getCategories: ApiPoint + "get-all-categories",
  getStates: ApiPoint + "get-all-states",
  getVendorContactMessages: ApiPoint + "vendor-contact",
  getLoginVendorProfile: ApiPoint + "vendor-profile-me",
  getSubscriptionList: ApiPoint + "get-subscriptions",
  getAllKeywords: ApiPoint + "get-all-keywords",
  getAllSliders: ApiPoint + "get-all-sliders",
  getAllCategories: ApiPoint + "get-all-categories",
  getAllUsers: ApiPoint + "get-all-users",
  getAllFaqs: ApiPoint + "admin/faqs/get-all-faqs",
  getAllStates: ApiPoint + "get-all-states",
  getAllVendors: ApiPoint + "get-all-vendors",
  getAllBlogs: ApiPoint + "get-all-blogs-list",
  updateVendorProfile: ApiPoint + "update-vendor-profile",
  uploadVendorProfileImage: ApiPoint + "uploads/vendor-profile-image",
  uploadVendorAssets: ApiPoint + "uploads/vendor-media",
  exploreVendor: ApiPoint + "explore",
  uploadVendorVideos: ApiPoint + "uploads/video/vendor-media",
  createNewDeal: ApiPoint + "create-new-deal",
  deleteDeal: ApiPoint + "delete-deal/",
  subscribeNewsletter: ApiPoint + "subscribe-newsletter",
  contactUs: ApiPoint + "contact-us",
  explore: ApiPoint + "explore",
  exploreAssets: ApiPoint + "exploreAssets",
  paymentComplete: ApiPoint + "payment-complete",
  paymentIntent: ApiPoint + "payment-intent",
  cancelSubscription: ApiPoint + "cancel-subscription",
  updateSubscription: ApiPoint + "admin/payments/update-subscription",
  deleteVendor: (vendorId) => `${ApiPoint}admin/deleteVendor?id=${vendorId}`,
  getCategoryKeyword: (categoryId) =>
    `${ApiPoint}admin/categories/get-category-keyword?categoryId=${categoryId}`,
  getCategoryKeywordByValue: (categoryValue) =>
    `${ApiPoint}admin/categories/get-category-keyword?categoryId=${categoryValue}`,
};

export const URLS = {
  BaseUrl,
  ApiPoint,
  S3BucketURL,
};

export const CONSTANTS = {
  REQUEST_HEADERS: {
    Accept: "application/json , text/plain, */*",
    "Content-Type": "application/json",
  },
};
