import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonCardHomeVendorListSection = () => {
  const skeletonArray = Array.from({ length: 10 }); // Adjust the length as needed

  return (
    <div className="homeTopPicksWrap">
      <div className="container">
        {skeletonArray.map((_, index) => (
          <div className="topPicksBoxWrap" key={index}>
            <div className="topPicksContentWrap">
              <div className="topPickPrdImg">
                <Skeleton
                  style={{ borderRadius: '50%' }}
                  width={100}
                  height={100}
                />
              </div>

              <div className="topPickPrdContent col-6">
                <div className="cursor-pointers">
                  <h5 className="vendor">
                    <Skeleton width={200} height={20} />
                  </h5>
                  <span className="vendorCat">
                    <div
                      className="selectedCat"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Skeleton width={90} style={{ marginRight: '10px' }} />{' '}
                      <Skeleton width={90} />
                    </div>
                  </span>

                  <div className="topPickBoxBtnsWrap mt-4">
                    <div className="btn-container">
                      <button className="contactBtn">
                        <Skeleton width={20} />
                      </button>
                      <div className="vendorMetaWrapHomeListVendor d-flex">
                        <a href="#!" className="vendorMetaBoxWrap">
                          <Skeleton width={23} height={23} />
                        </a>
                        <a href="#!" className="vendorMetaBoxWrap">
                          <Skeleton width={23} height={23} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonCardHomeVendorListSection;
