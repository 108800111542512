import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonCardVendorDetail = () => {
  return (
    <div className=" vendor-profile-section">
      <div className="gallery-section">
        <div className="gallery-thumbnails">
          <div className="gallery-thumbnail">
            <Skeleton height={300} width={1500} />
          </div>
        </div>
        <div className="gallery">
          {Array.from({ length: 8 }).map((_, index) => (
            <div key={index} className="gallery-item">
              <Skeleton height={100} width={120} />
            </div>
          ))}
          <style jsx>{`
            .gallery {
              display: flex;
              justify-content: space-between; /* Add space between items */
              margin: 0 -10px; /* Adjust as needed */
            }

            .gallery-item {
              flex: 1;
              padding: 0 10px; /* Adjust as needed */
            }
          `}</style>
        </div>
      </div>
      <div className="row sticky-wrapper">
        <div className="col-lg-8 col-md-8 padding-right-30 margin-top-30">
          <div className="mobileSearchForm justify-content-center">
            <div className="row"></div>
          </div>
          <div className="listing-titlebar-title">
            <h1 className="name">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Skeleton circle height={100} width={100} />
                <span className="mx-3">
                  <Skeleton height={20} width={150} />
                </span>
              </div>
            </h1>
            <div className="vendorSep margin-top-20"></div>
          </div>

          <div className="listing-overview mt-4">
            <h3 className="listing-desc-headline margin-bottom-10 mt-5 ">
              <strong className="state ">
                <Skeleton width={100} />
              </strong>
            </h3>

            <strong className="state">
              {' '}
              <Skeleton width={150} />
            </strong>
            <h3 className="listing-desc-headline margin-bottom-10 mt-5">
              <strong className="state">
                <Skeleton width={100} />
              </strong>
            </h3>

            <strong className="state">
              {' '}
              <Skeleton width={150} />
            </strong>
            <h3 className="listing-desc-headline margin-bottom-10 mt-5">
              <strong className="state">
                <Skeleton width={100} />
              </strong>
            </h3>

            <strong className="state">
              {' '}
              <Skeleton width={150} />
            </strong>
            <strong className="state">
              {' '}
              <Skeleton width={150} />
            </strong>
            <h3 className="listing-desc-headline margin-bottom-10 mt-5">
              <strong className="state">
                <Skeleton width={100} />
              </strong>
            </h3>

            <strong className="state">
              {' '}
              <Skeleton width={400} />
              <Skeleton width={400} />
            </strong>

            <h3 className="listing-desc-headline margin-bottom-10 mt-5">
              <strong className="state">
                <Skeleton width={100} />
              </strong>
            </h3>

            <div className="gallery">
              {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="gallery-item">
                  <Skeleton height={30} width={80} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 margin-top-35 sticky">
          <div className="col-md-12 inquireVendor text-center">
            <div className="row">
              <div className="col-lg-12">
                {/* Skeleton for Massi Select */}
                <Skeleton height={24} width={300} />
              </div>
            </div>

            {/* <button
              className="book-vendor2 w-75 mt-4"
              style={{ fontSize: '14px', fontWeight: '400' }}
            >
              Inquire
            </button> */}
            <div className="gallery mt-5">
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="gallery-item">
                  <Skeleton height={30} width={100} />
                </div>
              ))}
              <style jsx>{`
                .gallery {
                  display: flex;
                  justify-content: space-between; /* Add space between items */
                  margin: 0 -10px; /* Adjust as needed */
                }

                .gallery-item {
                  flex: 1;
                  padding: 0 10px; /* Adjust as needed */
                }
              `}</style>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCardVendorDetail;
