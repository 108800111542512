import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonExplore = () => {
  return (
    <div className="contentContainer exploreBgWrap">
      <Skeleton width={500} height={200} className="mx-auto d-flex mt-2 " />

      <p className="text-center">
        <Skeleton width={1000} />
        <Skeleton width={500} />
      </p>

      <div className="exploreContentWrap">
        <div className="container">
          <div className="row">
            <div className="col-md-8 exploreLeftHeading">
              <h2>
                <Skeleton width={200} />
              </h2>
              <p>
                <Skeleton count={3} />
              </p>
              <section className="margin-bottom-20">
                <h3 className="listing-desc-headline margin-bottom-10">
                  <strong className="state ">State</strong>
                </h3>

                <Skeleton width={200} height={25} />
              </section>
              <h3 className="description">Price</h3>

              <Skeleton width={200} height={25} />

              <div className="vendorCat">
                <div className="selectedCat">
                  <span className="MainCatLabel">Category</span>

                  <div className="categoryList">
                    <Skeleton width={200} height={25} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 exploreRightTopWrap">
              <div className="rightSideDetailsWrap">
                <div className="col-md-12 inquireVendorExplore text-center">
                  <div className="row explorePosition">
                    <h3 style={{ fontSize: '18px', fontWeight: '600' }}>
                      Interested In <Skeleton width={150} />
                    </h3>
                  </div>

                  <ul className="share-buttons  margin-top-10 margin-bottom-20">
                    <div className="vendorMetaWrapInquireExplore">
                      <Skeleton
                        width={50}
                        height={40}
                        style={{ margin: '0 10px' }}
                      />
                      <Skeleton
                        width={50}
                        height={40}
                        style={{ margin: '0 10px' }}
                      />
                      <Skeleton
                        width={50}
                        height={40}
                        style={{ margin: '0 10px' }}
                      />
                    </div>
                  </ul>
                  <div className="clearfix"></div>
                  {/* <button
                    className="book-vendor2 w-75"
                    style={{ fontSize: '14px', fontWeight: '400' }}
                  >
                    <Skeleton width={100} />
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="exploreContentWrapGallery">
          <div className="row text-center">
            {Array.from({ length: 4 }).map((_, index) => (
              <div className="col-md-3 text-center" key={index}>
                <Skeleton width={320} height={200} />
              </div>
            ))}
          </div>
        </div>
      </>

      <div className="exploreCantFindWrap">
        <div className="container">
          <p>Cant find what you're looking for?</p>
          <button className="cantFindBtn">
            <Skeleton width={200} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SkeletonExplore;
