import React from 'react';
import withRouter from '../../helper/withRouter';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';
import VendorService from '../../services/VendorService';
import VendorDetail from '../../components/VendorDetail';
import { URLS } from '../../util/constants';

class VendorProfilePage extends React.Component {
  state = {
    vendorProfile: null,
    vendorId: null,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    let vendorId = this.props.params.vendorId;
    this.setState({ vendorId: vendorId });
    this.getVendorProfileById(vendorId);
  }

  getVendorProfileById = async (profile_url) => {
    let vendorProfile = await VendorService.getVendorProfileById(profile_url);
    this.setState({ vendorProfile: vendorProfile });
    window.scrollTo(0, 0);
  };

  render() {
    let vendorName = this.state.vendorProfile?.data?.business
      ? this.state.vendorProfile.data?.business.toUpperCase()
      : 'Event Massi';
    let vendorDescription = this.state.vendorProfile?.data?.description
      ? this.state.vendorProfile.data?.description
      : 'Event Massi';
    let vendorPic = this.state.vendorProfile?.data?.pic_path
      ? this.state.vendorProfile.data?.pic_path
      : 'Event Massi';
    return (
      <>
        <HeaderDocumentDetail
          title={vendorName + ' | Vendor Profile'}
          description={vendorDescription + ' | Vendor Description'}
          picture={URLS.S3BucketURL + vendorPic}
        />
        <VendorDetail
          vendorProfile={this.state.vendorProfile}
          loginFormShow={(value) => this.props.loginFormShow(value)}
        />
      </>
    );
  }
}

export default withRouter(VendorProfilePage);
