import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Local_routes } from "../../util/constants";
import LoginSignupModal from "../../components/LoginSignupModal";
import SignupModal from "../../components/LoginSignupModal2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { ToastContent } from "../../components/Toast";
import SearchBar from "./Search";
import Logo from "../../assets/images/Massi Header Logo.svg";
import VendorTopRightLogo from "../../assets/images/VendorTopRightLogo.svg";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: null,
      Drawer: false,
      signinModal: false,
      signupModal: false,
      userAuth: localStorage.getItem("userToken")
        ? atob(localStorage.getItem("userToken"))
        : null,
      userData: localStorage.getItem("userData")
        ? JSON.parse(atob(localStorage.getItem("userData")))
        : null,
      headerTab: window.location.pathname,
      showSearchBar: false,
    };
    this.toggleSearchBar = this.toggleSearchBar.bind(this);
  }
  toggleSearchBar() {
    this.setState({ showSearchBar: !this.state.showSearchBar });
  }

  componentDidMount() {
    console.log("USERDATA", this.state.userData);
    const header = document.querySelector(".topHeader");
    // check if the user is on the Browse Vendors page
    if (window.location.pathname === Local_routes.vendors) {
      // add the sticky class to the header element
      header.classList.add("stickys");
    }
    // handle scrolling to remove the sticky class when the user scrolls past the header
    window.addEventListener("scroll", function () {
      if (window.scrollY > header.offsetTop) {
        header.classList.add("stickys");
      } else {
        header.classList.remove("stickys");
      }
    });
  }

  toogledrawer = (value) => {
    this.setState({ Drawer: value });
  };

  loginModalClose = (redirect = false) => {
    this.props.closeLoginModal();
    this.setState({
      signinModal: false,
      signupModal: false,
      userAuth: localStorage.getItem("userToken")
        ? atob(localStorage.getItem("userToken"))
        : null,
      userData: localStorage.getItem("userData")
        ? JSON.parse(atob(localStorage.getItem("userData")))
        : null,
    });

    if (redirect) {
      this.props.navigate("/dashboard", {
        userAuth: this.state.userAuth,
        userData: this.state.userData,
      });
    }
  };

  logoutUser = () => {
    this.setState({ userAuth: null, userData: null });
    localStorage.removeItem("userToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("isPaid");

    this.props.navigate("/");
  };

  setActiveHeaderTab = (tabname) => {
    this.setState({ headerTab: tabname });
  };

  render() {
    const { userData, userAuth } = this.state;

    const ProfileUrl = userData?.profile_url;
    console.log("PROFILE URL", ProfileUrl);
    return (
      <>
        <header className="topHeader ">
          <div className="container">
            <nav className="navbar navbar-expand-lg namobileHidevbar-light">
              <a
                className="navbar-brand "
                href="/"
                onClick={() => this.setActiveHeaderTab(Local_routes.home)}
              >
                <LazyLoadImage
                  effect="blur"
                  width={170}
                  height={50}
                  src={Logo}
                  alt="img"
                />
              </a>
              <div className="desktopHide desktopHideHeaderRightLink">
                {/* mx-4 */}
                {userAuth && userData ? (
                  <Link
                    to={Local_routes.dashboard}
                    className="headerRightLink "
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={VendorTopRightLogo}
                      alt="img"
                    />
                  </Link>
                ) : null}
              </div>
              <div>
                <button
                  style={{ border: "none" }}
                  className="navbar-toggler mobileView mx-3"
                  onClick={this.toggleSearchBar}
                >
                  <LazyLoadImage
                    src="../../../images/VectorYellow.svg"
                    alt="Search"
                  ></LazyLoadImage>
                </button>
                <button
                  style={{ border: "none", fontSize: "20px" }}
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <LazyLoadImage
                    src="../../../images/toggle.svg"
                    alt="Search"
                  ></LazyLoadImage>
                  {/* <span className="navbar-toggler-icon"></span> */}
                </button>
              </div>

              {this.state.showSearchBar ? (
                <form className="headerSearchForms">
                  <SearchBar />
                </form>
              ) : null}
              <div
                className="collapse  navbar-collapse"
                id="navbarSupportedContent"
              >
                {Local_routes.vendors !== window.location.pathname &&
                  !this.state.showSearchBar && (
                    <div className=" headerSearchForm desktopSearchForm">
                      <SearchBar />
                    </div>
                  )}

                <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                    <Link
                      className="nav-link  cursor-pointer"
                      to={Local_routes.home}
                      onClick={() => this.setActiveHeaderTab(Local_routes.home)}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item cursor-pointer ">
                    <Link
                      className="nav-link "
                      to={Local_routes.vendors}
                      onClick={() =>
                        this.setActiveHeaderTab(Local_routes.vendors)
                      }
                    >
                      Browse Vendors
                    </Link>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <Link
                      className="nav-link"
                      to={Local_routes.explore}
                      onClick={() =>
                        this.setActiveHeaderTab(Local_routes.explore)
                      }
                    >
                      Explore
                    </Link>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <a
                      className="nav-link"
                      href="https://blog.eventmassi.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        this.setActiveHeaderTab(Local_routes.blogs)
                      }
                    >
                      Blog
                    </a>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <Link
                      className="nav-link"
                      to={Local_routes.massi}
                      onClick={() =>
                        this.setActiveHeaderTab(Local_routes.massi)
                      }
                    >
                      Why Massi?
                    </Link>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <Link
                      className="nav-link"
                      to={Local_routes.contact_us}
                      onClick={() =>
                        this.setActiveHeaderTab(Local_routes.contact_us)
                      }
                    >
                      Contact Us
                    </Link>
                  </li>
                  {/* 
                  {userAuth && userData ? (
                    <>
                      <li className="nav-item cursor-pointer">
                        {' '}
                        <Link
                          to={Local_routes.vendor_detail + '/' + ProfileUrl}
                          className="nav-link"
                        >
                          View Profile
                        </Link>
                      </li>
                    </>
                  ) : null} */}
                  {userAuth && userData ? (
                    <>
                      {ProfileUrl ? (
                        <li className="nav-item cursor-pointer">
                          <a
                            href={Local_routes.vendor_detail + "/" + ProfileUrl}
                            className="nav-link"
                          >
                            View Profile
                          </a>
                        </li>
                      ) : (
                        <li className="nav-item">
                          <span
                            className="nav-link cursor-pointer"
                            onClick={() => {
                              toast(
                                () =>
                                  ToastContent("Please Complete Your Profile"),
                                {
                                  toastId: "infoToast",
                                  hideProgressBar: false,
                                  type: toast.TYPE.ERROR,
                                  autoClose: 1500,
                                }
                              );
                            }}
                          >
                            View Profile
                          </span>
                        </li>
                      )}
                    </>
                  ) : null}
                </ul>
                <div className="topHeaderRightSide">
                  <ul className="topHeaderRightNav">
                    <li>
                      {/* User Menu */}
                      {userAuth && userData ? (
                        <>
                          <Link
                            className={""}
                            to={Local_routes.dashboard}
                            onClick={() =>
                              this.setActiveHeaderTab(Local_routes.home)
                            }
                          >
                            <div className="user-menu">
                              <div className="user-name">
                                <span>
                                  {userData.pic_path === "" ||
                                  userData.pic_path === null ||
                                  userData.pic_path === "undefined" ? (
                                    <LazyLoadImage
                                      src={VendorTopRightLogo}
                                      alt={"User Profile pic"}
                                    />
                                  ) : (
                                    <LazyLoadImage
                                      // src={`${URLS.S3BucketURL}${userData.pic_path}`}
                                      src={VendorTopRightLogo}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "./images/default.jpeg";
                                      }}
                                      alt={"User Profile pic"}
                                    />
                                  )}
                                </span>
                              </div>
                            </div>
                          </Link>
                          <Link
                            to={Local_routes.dashboard}
                            onClick={() =>
                              this.setActiveHeaderTab(Local_routes.home)
                            }
                            className="mx-3"
                          >
                            DashBoard
                          </Link>

                          <a href="#!" onClick={() => this.logoutUser()}>
                            Logout
                          </a>
                        </>
                      ) : (
                        <a
                          href="#!"
                          onClick={() =>
                            this.setState({
                              signinModal: true,
                              signupModal: false,
                            })
                          }
                        >
                          Log In
                        </a>
                      )}
                    </li>
                    {/* <a
                          className="signUp"
                          href="#!"
                          onClick={() =>
                            this.setState({
                              signinModal: false,
                              signupModal: true,
                            })
                          }
                        >
                          <span>Sign Up</span>
                        </a> */}
                    <li>
                      {!userAuth && !userData ? (
                        <>
                          <Link
                            className="signUp"
                            to={Local_routes.signup}
                            onClick={() =>
                              this.setActiveHeaderTab(Local_routes.signup)
                            }
                          >
                            Get Started Free
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            {/* {Local_routes.vendors !== window.location.pathname && (
              <div className="mobileSearchForm ">
                <form className="headerSearchForm ">
                  <SearchBar />
                </form>
              </div>
            )} */}
          </div>
        </header>

        <LoginSignupModal
          signinupModal={this.state.signinModal}
          loginModalClose={(redirect) => this.loginModalClose(redirect)}
        />
        <SignupModal
          signinupModal={this.state.signupModal}
          loginModalClose={(redirect) => this.loginModalClose(redirect)}
        />
        <ToastContainer />
      </>
    );
  }
}

function HeaderRoute(props) {
  let navigate = useNavigate();
  return <Header {...props} navigate={navigate} />;
}

export default HeaderRoute;
