import React, { Component } from 'react';
import './signup.css';
import Image from './Rectangle 486.png';
import Divider from './Divider.png';
import { Link, useNavigate } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import signInGoogle from '../assets/images/googlesocialsignin.png';
import signInApple from '../assets/images/applesigninsocial.png';
import { auth, google, facebook, apple } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import { ToastContent } from '../components/Toast';
import AuthService from '../services/AuthService';
import ReCAPTCHA from 'react-google-recaptcha';
import { ApiRoute } from '../util/constants';

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      recaptchaToken: [],
    };
  }
  fetchData = () => {
    this.setState({ loading: true });

    //Faking API call here
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  componentDidMount() {
    // Scroll to the top when the component is mounted

    window.scrollTo(0, 0);
  }

  signUpWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, google);
      const user = res.user;
      console.log('====================================');
      console.log('user', user);
      console.log('====================================');
      await axios
        .post(`${ApiRoute.register}`, {
          email: user.email,
          provider: 'google',
        })
        .then((response) => {
          // console.log('checking token in response ', response.data.token);

          if (response.status === 200) {
            toast(() => ToastContent('Successfully Signed Up'), {
              toastId: 'successToast',
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
            localStorage.setItem('userToken', btoa(response.data.token));
            localStorage.setItem('userData', btoa(JSON.stringify(response)));
            // this.props.loginModalClose(1);
            // setTimeout(() => {
            //   window.location = '/dashboard';
            // }, 1200);
            this.props.navigate('/signup/new');
          } else {
            toast(() => ToastContent('USER ALREADY EXIST'), {
              toastId: 'dangerToast',
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            });
          }
        })
        .catch((e) => {
          toast(() => ToastContent(e?.response?.data?.message), {
            toastId: 'dangerToast',
            hideProgressBar: false,
            type: toast.TYPE.ERROR,
            autoClose: 1000,
          });
        });
    } catch (err) {
      // CATCH ERROR SAYING SIGNUP POPUP CLOSED
      toast(() => ToastContent(err.message), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };
  signUpWithFacebook = async () => {
    try {
      const res = await signInWithPopup(auth, facebook);
      const user = res.user;
      console.log('====================================');
      console.log('user', user);
      console.log('====================================');
      await axios
        .post(`${ApiRoute.register}`, {
          email: user.email,
          provider: 'facebook',
        })
        .then((response) => {
          // console.log('checking token in response ', response.data.token);

          if (response.status === 200) {
            toast(() => ToastContent('Successfully Signed Up'), {
              toastId: 'successToast',
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
            localStorage.setItem('userToken', btoa(response.data.token));
            localStorage.setItem('userData', btoa(JSON.stringify(response)));
            // setTimeout(() => {
            //   window.location = '/dashboard';
            // }, 1200);
            this.props.navigate('/signup/new');
          }
          if (response.status === 400) {
            toast(() => ToastContent('USER ALREADY EXIST'), {
              toastId: 'dangerToast',
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            });
          }
        });
    } catch (err) {
      // CATCH ERROR SAYING SIGNUP POPUP CLOSED
      toast(() => ToastContent(err.message), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };
  signUpWithApple = async () => {
    try {
      const res = await signInWithPopup(auth, apple);
      const user = res.user;
      console.log('====================================');
      console.log('user', user);
      console.log('====================================');
      await axios
        .post(`${ApiRoute.register}`, {
          email: user.email,
          provider: 'apple',
        })
        .then((response, err) => {
          // console.log('checking token in response ', response.data.token);

          if (response.status === 200) {
            toast(() => ToastContent('Successfully Signed Up'), {
              toastId: 'successToast',
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
            localStorage.setItem('userToken', btoa(response.data.token));
            localStorage.setItem('userData', btoa(JSON.stringify(response)));
            this.props.navigate('/signup/new');
            // setTimeout(() => {
            //   window.location = '/dashboard';
            // }, 1200);
          }
          if (response.status === 400) {
            toast(() => ToastContent(err.message), {
              toastId: 'dangerToast',
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            });
          }
        })
        .catch((e) => {
          toast(() => ToastContent(e?.response?.data?.message), {
            toastId: 'dangerToast',
            hideProgressBar: false,
            type: toast.TYPE.ERROR,
            autoClose: 1000,
          });
        });
    } catch (err) {
      // CATCH ERROR SAYING SIGNUP POPUP CLOSED
      toast(() => ToastContent(err.message), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };
  submitRegisterForm = async (event) => {
    toast.dismiss();
    event.preventDefault();
    var email = event.target.elements.registeremail.value;
    var password = event.target.elements.registerpassword.value;
    // var repeatpassword = event.target.elements.repeatpassword.value;
    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (!validateEmail) {
      toast(() => ToastContent('Please enter valid email address'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }

    if (!password) {
      toast(() => ToastContent('Please enter Password'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }
    if (password.length < 6) {
      toast(
        () => ToastContent('Password Should not be Shorter than 6 letters'),
        {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1500,
        }
      );
      return;
    }

    if (!this.state.recaptchaToken || this.state.recaptchaToken.length === 0) {
      toast(() => ToastContent('Please solve the reCAPTCHA'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }
    if (validateEmail && password && this.state.recaptchaToken) {
      toast(() => ToastContent('Please wait....'), {
        toastId: 'infoToast',
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
      let loginData = await AuthService.register(
        email,
        password,
        this.state.recaptchaToken
      );
      toast.dismiss();
      if (loginData.success) {
        toast(() => ToastContent('Successfully Registered'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        await localStorage.setItem('userToken', btoa(loginData.token));
        await localStorage.setItem(
          'userData',
          btoa(JSON.stringify(loginData.user))
        );
        localStorage.removeItem('_grecaptcha');
        this.setState({ recaptchaToken: null });
        this.props.navigate('/signup/new');
        // window.location = 'signup/new';
        // setTimeout(() => {
        //   window.location = '/dashboard';
        // }, 1200);
      } else {
        toast(() => ToastContent(loginData.message), {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    }
  };

  handleRecaptchaChange = (token) => {
    this.setState({ recaptchaToken: token });
  };

  handleRecaptchaExpire = () => {
    this.setState({ recaptchaToken: null });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <div className="container-fluid  ">
          <div className="text-container ">
            <div className="text-side ">
              <h1
                style={{
                  fontWeight: '600',
                  fontSize: '23px',

                  textAlign: 'center',
                  maxWidth: '39rem',
                  margin: '0 auto',
                }}
              >
                Your next step to more leads and more bookings, create your
                Event Massi vendor Profile!
              </h1>
              <form
                className="register "
                onSubmit={this.submitRegisterForm}
                // style={{ marginTop: '50px' }}
              >
                <input
                  type="text"
                  className="input-text-signup"
                  name="email"
                  id="registeremail"
                  placeholder="Enter Email "
                  defaultValue=""
                  onInput={(event) =>
                    (event.target.value = event.target.value.toLowerCase())
                  }
                />

                <input
                  className="input-text-signup"
                  type="password"
                  name="registerpassword"
                  placeholder="Create password"
                  id="registerpassword"
                  defaultValue=""
                />

                <div className="customFormGroup">
                  <div className="d-flex mx-auto w-100 ">
                    <button
                      type="submit"
                      className="signUpBtn-signup d-flex mx-auto"
                      name="register"
                      value="Register"
                      onClick={this.fetchData}
                    >
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: '5px' }}
                        />
                      )}

                      {!loading && <span>Create Account</span>}
                    </button>
                  </div>
                </div>
                <div className="d-flex mx-auto justify-content-center mt-3">
                  <ReCAPTCHA
                    sitekey="6LcRwfglAAAAAPavkk-U_CgSm8EMRECqXdQQLzVH"
                    // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    // onChange={handleRecaptcha}
                    onChange={this.handleRecaptchaChange}
                    onExpired={this.handleRecaptchaExpire}
                  />
                </div>

                <p className="formShortInfo mx-5">
                  By logging in, you agree with the Terms of Service and Privacy
                  Policy
                </p>
              </form>
              <div className="signUpWithBox signupNew">
                <span>Or</span>
              </div>
              {/* <div className="socialLoginBoxesSignup mt-3">
                <button
                  onClick={() => this.signUpWithApple(apple)}
                  className="appleLogin"
                >
                  <img
                    src="https://www.beta.eventmassi.com/_next/static/media/apple-icon.18d9951b.svg"
                
                    alt=""
                  />
                  <span>Apple ID</span>
                </button>
                <button onClick={() => this.signUpWithGoogle(google)}>
                  <img
                    src="https://www.beta.eventmassi.com/_next/static/media/g-plus-icon.c93f23d6.svg"
               
                    alt=""
                  />
                  <span>Google</span>
                </button>
              </div> */}
              <div className="customFormGroup">
                <div className="">
                  <button
                    onClick={() => this.signUpWithApple(apple)}
                    className="signUpBtn-signup-NewUser-Signup  d-flex mx-auto"
                  >
                    <img
                      width={250}
                      // src="https://www.beta.eventmassi.com/_next/static/media/g-plus-icon.c93f23d6.svg"
                      src={signInApple}
                      alt=""
                    />
                  </button>
                </div>
                <div className=" ">
                  <button
                    className="signUpBtn-signup-NewUser-Signup d-flex mx-auto"
                    onClick={() => this.signUpWithGoogle(google)}
                  >
                    <span>
                      {' '}
                      <img
                        width={250}
                        // src="https://www.beta.eventmassi.com/_next/static/media/g-plus-icon.c93f23d6.svg"
                        src={signInGoogle}
                        alt=""
                      />
                    </span>
                  </button>
                </div>
              </div>

              <p className="formShortInfo mx-5 ">
                We don't post anything to your social media; they are just used
                to create your account.
              </p>
              <span className="already">
                Already have an account?
                <NavLink className="mx-3 fw-bolder text-dark text-decoration-underline ">
                  Sign in
                </NavLink>
              </span>
            </div>
          </div>

          <div className="box-container ">
            <div className="centered-content">
              <img src={Image} alt="ImageBox" className="box-image" />
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <img
            src={Divider}
            alt="Divider"
            style={{ width: '100%', position: 'absolute', bottom: -3 }}
          />
        </div>
      </>
    );
  }
}

function HeaderRouteSignup(props) {
  let navigate = useNavigate();
  return <SignUpPage {...props} navigate={navigate} />;
}
export default HeaderRouteSignup;
