import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonCardSubscription = () => {
  return (
    <div className="container margin-bottom-35">
      <div className="col-md-12">
        <div className="row padding-top-15">
          <div className="col-lg-12 text-center">
            <Skeleton height={40} width={200} />
          </div>
        </div>
        <div className="row text-center subscription-duration">
          <label className="switch mx-auto d-flex mt-3">
            <span className="slider round"></span>
          </label>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="pricing-container margin-top-10">
              {[1, 2, 3].map((pData) => (
                <div className="plan" key={pData}>
                  <div className="plan-price text-left">
                    <Skeleton height={20} width={150} />
                    <Skeleton height={40} width={200} />
                    <Skeleton height={30} width={80} />
                  </div>
                  <div className="plan-features">
                    <h4 className="text-left mb-2 fw-bold">
                      <Skeleton height={20} width={200} />
                    </h4>
                    <ul>
                      {[1, 2, 3].map((services) => (
                        <li className="text-left fw-bold" key={services}>
                          <Skeleton height={20} width={30} />
                          <Skeleton height={20} width={150} />
                        </li>
                      ))}
                    </ul>
                    <Skeleton height={40} width={150} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCardSubscription;
