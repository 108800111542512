import React from "react";
import { Input, FormGroup } from "reactstrap";
import CommonService from "../services/CommonService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContent } from "../components/Toast";
import InstaFeed from "../pages/layouts/InstaFeed";
import fbIcon from "../assets/images/fbicon.svg";
import instaIcon from "../assets/images/instaicon.svg";
import tiktokIcon from "../assets/images/tiktokicon.svg";
import twitterIcon from "../assets/images/twittericon.svg";
import People from "../assets/images/People.svg";

import "./home.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
class HomeFollowUsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  fetchData = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };

  subscribeNewLetter = async (e) => {
    e.preventDefault();

    toast.dismiss();
    var email = e.target.elements.email.value;
    if (email === "" || email === null) {
      toast(() => ToastContent("Please enter valid email."), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
      return false;
    }
    try {
      var send = await CommonService.subscribeNewsLetter(email);

      if (send?.success) {
        toast(() => ToastContent(send.message), {
          toastId: "successToast",
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        });
      } else {
        toast(() => ToastContent(send.message), {
          toastId: "errorToast",
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1500,
        });
      }
    } catch (err) {
      toast(() => ToastContent("You have not subscribed to Newsletter."), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
    e.target.reset();
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <section className="fullwidth padding-top-30 padding-bottom-70">
          <div className="container">
            <div
              style={{ paddingLeft: "5px", paddingRight: "5px" }}
              className="col-lg-4 col-sm-12"
            >
              <div className="homeSecondSectionBoxWrap">
                <h1>Join our facebook group</h1>
                <div className="fbGrpWrap">
                  <LazyLoadImage effect="blur" src={People} alt="" />
                  <h5>Follow @eventmassi</h5>
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/eventmassi"
                        rel="noopener noreferrer"
                        target={"_blank"}
                      >
                        <LazyLoadImage effect="blur" src={fbIcon} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/eventmassi"
                        rel="noopener noreferrer"
                        target={"_blank"}
                      >
                        <LazyLoadImage
                          effect="blur"
                          src={instaIcon}
                          alt="img"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/eventmassi"
                        rel="noopener noreferrer"
                        target={"_blank"}
                      >
                        <LazyLoadImage
                          effect="blur"
                          src={tiktokIcon}
                          alt="img"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.twitter.com/eventmassi"
                        rel="noopener noreferrer"
                        target={"_blank"}
                      >
                        <LazyLoadImage
                          effect="blur"
                          src={twitterIcon}
                          alt="img"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  */}

            <div
              style={{ paddingRight: "5px", paddingLeft: "5px" }}
              className="col-lg-4 col-sm-12"
            >
              <div className="homeSecondSectionBoxWrap">
                <h1>Check us out on Instagram!</h1>

                <InstaFeed />
              </div>
            </div>

            <div
              style={{ paddingRight: "5px", paddingLeft: "5px" }}
              className="col-lg-4 col-sm-12"
            >
              <div
                style={{
                  padding: "33px",
                }}
                className="homeSecondSectionBoxWrap subscribeBoxWrap"
              >
                <h1>Subscribe To The Event Massi Newsletter!</h1>
                <form
                  className="newsLetterForm"
                  onSubmit={this.subscribeNewLetter}
                >
                  <FormGroup className="margin-top-10 ">
                    <Input
                      type="email"
                      id="email"
                      className="customFormControl"
                      placeholder="Enter Email "
                    />
                  </FormGroup>
                  <button onClick={this.fetchData} className="subscribeBtn ">
                    {loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}

                    {!loading && <span>Subscribe!</span>}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomeFollowUsSection;
