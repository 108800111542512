import React from "react";
import { Rings } from "react-loader-spinner";
import { URLS } from "../util/constants";
import VendorService from "../services/VendorService";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContent } from "../components/Toast";
import "react-responsive-modal/styles.css";
import { Editor } from "@tinymce/tinymce-react";
import SkeletonCardDeals from "./Skeltons/SkeletonCardDeals";
import DeleteIcon from "../assets/images/delete-bin.svg";
import UploadIcon from "../assets/images/uploadIcon.svg";
import "./home.css";

const buttonStyle = {
  width: "30px",
  background: "none",
  border: "0px",
  color: "#fff",
  fontWeight: "bold",
};

class VendorDashboardDeals extends React.Component {
  // state = {
  //   dealsData: null,
  //   showDealModal: false,
  //   isPaid: localStorage.getItem('isPaid'),
  // };

  constructor(props) {
    super(props);
    this.state = {
      dealsData: null,
      showDealModal: false,
      userData: localStorage.getItem("userData")
        ? JSON.parse(atob(localStorage.getItem("userData")))
        : null,
      isPaid: localStorage.getItem("isPaid"),
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dealsData !== prevProps.dealsData) {
      this.setState({ dealsData: this.props.dealsData });
    }
  }

  handleEditorChange = (content, editor) => {
    console.log("Content was updated:", content);
  };

  confirmDeleteMessage = async (id) => {
    confirmAlert({
      title: "Confirm to Delete This Deal",

      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteDeal(id);
          },
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };

  deleteDeal = async (id) => {
    try {
      var dealdelete = await VendorService.deleteVendorDeal(id);
      if (dealdelete) {
        const newList = this.state.dealsData.filter((item) => item.id !== id);
        this.setState((this.state.dealsData = newList));
        toast(() => ToastContent("Deal has been deleted successfully"), {
          toastId: "successToast",
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
      } else {
        toast(() => ToastContent("Error on deleting the deal"), {
          toastId: "errorToast",
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };
  uploadNewDeal = async (e) => {
    e.preventDefault();
    try {
      var name = e.target.elements.dealname.value;
      var description = e.target.elements.dealdescription.value;
      var dealMedia = await VendorService.uploadVendorProfileMedia(
        this.state.dealImage
      );
      var params = {
        name,
        description,
        assets: { type: "photo", url: dealMedia.imagePath },
      };
      var uploadDeal = await VendorService.uploadVendorDeal(params);
      if (uploadDeal.success) {
        var oldDeals = this.state.dealsData;
        oldDeals.push(uploadDeal.deal);
        this.setState({ dealsData: oldDeals, showDealModal: false });
        toast(() => ToastContent("Deal has been created successfully"), {
          toastId: "successToast",
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        e.target.elements.dealname.value = "";
        e.target.elements.dealdescription.value = "";
        this.setState({ dealImage: null });
        document.getElementById("preview").src = "";
      } else {
        toast(() => ToastContent("Deal not created."), {
          toastId: "errorToast",
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } catch (err) {
      toast(() => ToastContent("Deal not created."), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  onChangeDealImage = async (event) => {
    let imagePath = event.target.files[0];
    this.setState({ dealImage: imagePath });
    let preview = document.getElementById("preview");
    preview.src = URL.createObjectURL(imagePath);
  };
  render() {
    const { dealsData } = this.state;
    return (
      <>
        {dealsData == null ? (
          <div
            className="col-lg-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <Rings
              height="200"
              width="200"
              radius="9"
              color="#edb300"
              ariaLabel="three-dots-loading"
            /> */}
            <SkeletonCardDeals />
          </div>
        ) : (
          <>
            {this.state.userData?.isPaid === false ? (
              <div className="tabTopBar ">
                <p>
                  <strong>Subscribe!</strong> to preferred membership &amp;
                  enhance your business. To subscribe{" "}
                  <a href="#_">Click Here</a>
                </p>
              </div>
            ) : (
              <div className="row margin-top-25 margin-bottom-50">
                <div className="dealsTopContentWrap">
                  <h6>Deals &amp; Specials</h6>
                  <p>Upload your deals &amp; specials from here</p>
                </div>

                {/*  */}
                <div className="dealsFormWrap">
                  <form className="dealsForm" onSubmit={this.uploadNewDeal}>
                    <div className="dealFormGrp">
                      <label className="">Deal Name</label>
                      {/* <Editor
                        apiKey="1ausnzpznit2slgbdbs476puc2wt1bllt99rw6d6zvjflaqh"
                        name="dealname"
                        id="dealname"
                        init={{
                          branding: false,
                          height: 150,
                          width: '100%',
                          menubar: false,
                          statusbar: false,
                          // plugins: [
                          //   'advlist autolink lists link image charmap print preview anchor',
                          //   'searchreplace visualblocks code fullscreen',
                          //   'insertdatetime media table paste code help wordcount',
                          //   'fontsize',
                          // ],
                          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',

                          toolbar:
                            'undo redo | formatselect | bold italic underline forecolor backcolor  fontselect fontsize',
                        }}
                        onEditorChange={this.handleEditorChange}
                      /> */}
                      <input
                        type="text"
                        className="dealFormControl"
                        name="dealname"
                        id="dealname"
                        defaultValue={""}
                        placeholder=""
                      />
                    </div>
                    <div className="dealFormGrp">
                      <label>Description</label>
                      <Editor
                        apiKey="1ausnzpznit2slgbdbs476puc2wt1bllt99rw6d6zvjflaqh"
                        id="dealdescription"
                        textareaname="dealdescription"
                        init={{
                          branding: false,
                          height: 200,
                          width: "100%",
                          menubar: false,
                          statusbar: false,
                          // plugins: [
                          //   'advlist autolink lists link image charmap print preview anchor',
                          //   'searchreplace visualblocks code fullscreen',
                          //   'insertdatetime media table paste code help wordcount',
                          //   'fontsize',
                          // ],
                          fontsize_formats:
                            "8pt 10pt 12pt 14pt  18pt 24pt 36pt",

                          toolbar:
                            "undo redo | formatselect | bold italic underline forecolor backcolor fontselect fontsize",
                        }}
                        onEditorChange={this.handleEditorChange}
                      />
                      {/* <textarea
                        className="dealFormControl"
                        textareaname="dealdescription"
                        cols="40"
                        rows="3"
                        id="dealdescription"
                        spellCheck="true"
                      ></textarea> */}
                    </div>
                    <div className="text-center justify-content-center mx-auto">
                      <img
                        id="preview"
                        style={{
                          width: "80%",
                          // height: '180px',
                          border: "none",
                        }}
                        alt="Preview Icon"
                      />
                    </div>
                    <div className="upload-btn-wrapper mt-3">
                      <button className="btn">
                        <img src={UploadIcon} alt="Upload Button" />
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        className="upload_deal_image"
                        accept="image/*"
                        onChange={(e) => this.onChangeDealImage(e)}
                      />
                    </div>
                    <div className="dealFormGrp dealBtnGrp">
                      <button className="dealUploadBtn">Upload Now!</button>
                    </div>
                  </form>
                </div>
                {/*  */}

                <div className="col-lg-12 col-md-12 col-sm-12">
                  {/* <div className="row text-right margin-bottom-25">
                  <button
                    className="button"
                    onClick={() => this.props.showDealModal(true)}
                  >
                    <i className="fa fa-plus"></i>Add New Deal
                  </button>
                </div> */}

                  <div
                    className="dashboard-list-box margin-top-20"
                    style={{ boxShadow: "0 0 4px 0 rgb(0 0 0 / 6%)" }}
                  >
                    {/* <h4>Your deals</h4> */}
                    <ul>
                      {dealsData.length > 0 ? (
                        dealsData.map((deal, data) => {
                          return (
                            <li key={data}>
                              <div className="list-box-listing">
                                <div className="list-box-listing-img ">
                                  {deal.assets.length > 0 ? (
                                    <div>
                                      {deal.assets.map((asset, datas) => {
                                        return (
                                          <a href="#!" key={datas}>
                                            <img
                                              src={URLS.S3BucketURL + asset.url}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                  "./images/default.jpeg";
                                              }}
                                              alt={deal.name}
                                            />
                                          </a>
                                        );
                                      })}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="list-box-listing-content">
                                  <div className="inner">
                                    {/* <Editor
                                    apiKey="1ausnzpznit2slgbdbs476puc2wt1bllt99rw6d6zvjflaqh"
                                    initialValue={`${deal.name}\n\n${deal.description}`}
                                    init={{
                                      height: 180,
                                      toolbar: false,
                                      menubar: false,
                                      branding: false,
                                      // plugins: ['paste', 'autoresize'],
                                    }}
                                    disabled
                                  ></Editor> */}

                                    <h3>{deal.name}</h3>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: deal.description,
                                      }}
                                    >
                                      {/* {deal.description} */}
                                    </span>
                                    <div
                                      className="star-rating"
                                      data-rating="5.0"
                                    >
                                      <div className="rating-counter">
                                        <Moment format="DD MMMM YYYY, dddd">
                                          {deal.createdAt}
                                        </Moment>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="buttons-to-right">
                                <a
                                  href="#!"
                                  // className="button gray"
                                  onClick={() =>
                                    this.confirmDeleteMessage(deal.id)
                                  }
                                >
                                  <img src={DeleteIcon} alt="Delete Icon" />
                                  {/* <i className="sl sl-icon-close"></i> Delete */}
                                </a>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <li>No deal found</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default VendorDashboardDeals;
