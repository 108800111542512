import React from "react";
import { Rings } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import VendorProfileGallery from "./VendorProfileGallery";
import SocialShareVendor from "./SocialShareVendor";
import ContactVendorFormModal from "./ContactVendorFormModal";
import { URLS } from "../util/constants";
import "./home.css";
import Facebook from "../assets/images/fb.svg";
import Instagram from "../assets/images/insta.svg";
import Twitter from "../assets/images/twitter.svg";
import Tiktok from "../assets/images/tiktok.svg";
import Pinterest from "../assets/images/pinterest.svg";
import Website from "../assets/images/website.png";
import LinkedIn from "../assets/images/linkedin.png";
import Youtube from "../assets/images/youtube.svg";
import AdsComponent from "../Ads";
import SkeletonCardVendorDetail from "./Skeltons/SkeletonCardVendorDetail";

let navbar;
let sticky;
class VendorDetail extends React.Component {
  state = {
    showContactForm: false,
    showShareModal: false,
  };
  htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  closeBtn = () => (
    <i
      className="fa fa-close cursor-pointer fs-2"
      size={15}
      onClick={() =>
        this.setState({ showContactForm: false, showShareModal: false })
      }
    />
  );

  // componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vendorProfile !== null) {
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleSticky);
  }

  handleSticky = () => {
    console.log(window.scrollY, sticky);
    if (window.scrollY > sticky) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  };

  render() {
    const { vendorProfile } = this.props;
    console.log("PROFILE PROPS COMING", vendorProfile);
    const decodedDescription = decodeURIComponent(
      vendorProfile?.data?.description
    );
    return (
      <>
        {vendorProfile == null ? (
          <>
            <SkeletonCardVendorDetail />
          </>
        ) : null}

        {vendorProfile != null ? (
          <>
            <hr></hr>

            <div className="clearfix"></div>

            {/* GALLERY */}

            <VendorProfileGallery assetsData={vendorProfile?.data?.assets} />
            {vendorProfile?.data?.business != null ? (
              <div className="container vendor-profile-section">
                <div className="row sticky-wrapper">
                  <div className="col-lg-8 col-md-8 padding-right-30 margin-top-30">
                    <div className="mobileSearchForm justify-content-center">
                      <div className="row">
                        {vendorProfile.data?.position === 0 ? (
                          <div className="col-lg-12">
                            <h2 className="selectedHeadingYellow">
                              Massi Select
                            </h2>
                          </div>
                        ) : vendorProfile.data?.position === 1 ? (
                          <div className="col-lg-12">
                            <h2 className="selectedHeadingSilver">
                              Massi Preferred
                            </h2>
                          </div>
                        ) : null}
                      </div>
                      <span>
                        {vendorProfile.deals?.length > 0 ? (
                          <img
                            style={{
                              position: "absolute",
                              top: "4px",
                              right: "25px",
                              width: "80px",
                            }}
                            src="../../images/dealmassi.png"
                            alt="Deal Icon"
                          />
                        ) : null}
                      </span>
                    </div>
                    {vendorProfile.data?.business?.length > 0 ? (
                      <div className="listing-titlebar-title ">
                        <h1 className="name">
                          <>
                            {vendorProfile.data?.pic_path === "" ||
                            vendorProfile.data?.pic_path === null ||
                            vendorProfile.data?.pic_path === "undefined" ? (
                              <img
                                style={{
                                  width: "100px",
                                  border: "1px solid gainsboro",
                                  borderRadius: "100%",
                                }}
                                src="../../images/noImage.svg"
                                alt={vendorProfile.data?.business}
                              />
                            ) : (
                              <img
                                style={{
                                  width: "100px",
                                  border: "1px solid gainsboro",
                                  borderRadius: "100%",
                                  height: "100px",
                                  objectFit: "contain",
                                }}
                                src={`${
                                  URLS.S3BucketURL +
                                  vendorProfile.data?.pic_path
                                }`}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "./images/noImage.svg";
                                }}
                                alt={"User Profile pic"}
                              />
                            )}
                          </>

                          <span
                            style={{ fontSize: "18px", fontWeight: "600" }}
                            className="mx-3"
                          >
                            {vendorProfile.data?.business}
                          </span>
                          <div className="d-flex justify-content-center mt-4">
                            <div className="mobileSearchForm inquireVendor">
                              {/* col-md-4 col-sm-6 */}
                              <div className="col-lg-4 col-sm-12    ">
                                <div className="col-12 text-center">
                                  <h3
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Interested In{" "}
                                    {` ${vendorProfile.data?.business}?`}
                                  </h3>
                                  {/* <button
                      className=" book-vendor bg-dark col-md-12"
                      onClick={() => this.setState({ showContactForm: true })}
                    >
                      Inquire
                    </button> */}
                                  <ul className="share-buttons   d-flex  margin-left-50 margin-top-10 margin-bottom-20">
                                    <div className="vendorMetaWrapInquire fs-3 ">
                                      <a
                                        href={`tel:${vendorProfile.data?.phone}`}
                                        className="phone"
                                      >
                                        <img
                                          src="../../images/call.svg"
                                          alt=""
                                        />
                                        {/* <span className=" mx-3  text-dark">Call Now</span> */}
                                      </a>
                                      {/* <a
                          className="vendorMetaBoxWrap"
                          href={`mailto:${vendorProfile.data.email}?cc=support@eventmassi.com`}
                        >
                          <img
                            src="../../images/mailer.jpg"
                            alt="Mail"
                            width={25}
                          />
                        </a> */}
                                      <button
                                        className="vendorMetaBoxWrap border border-0 bg-dark "
                                        onClick={() =>
                                          this.setState({
                                            showContactForm: true,
                                          })
                                        }
                                      >
                                        <img
                                          src="../../images/sms.svg"
                                          alt="Mail"
                                          width={25}
                                        />
                                      </button>
                                      <a
                                        href="#!"
                                        onClick={() =>
                                          this.setState({
                                            showShareModal: true,
                                            selectedVendor: vendorProfile,
                                          })
                                        }
                                        className="vendorMetaBoxWrap border border-1 bg-white"
                                      >
                                        <img
                                          src="../../images/share.svg"
                                          alt=""
                                          width={25}
                                        />
                                      </a>
                                    </div>
                                    <ReactTooltip place={"left"} />
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vendorSep"></div>
                        </h1>
                      </div>
                    ) : null}

                    <div id="listing-overview" className="listing-section ">
                      {vendorProfile.data?.states?.length > 0 ? (
                        <>
                          <section className="margin-bottom-20 ">
                            <h3 className="listing-desc-headline margin-bottom-10">
                              <strong className="state ">State</strong>
                            </h3>

                            {vendorProfile.data?.states.map((state, check) => {
                              return (
                                <div
                                  key={check}
                                  className="listing-address text-dark "
                                >
                                  {state.name}
                                </div>
                              );
                            })}
                          </section>
                        </>
                      ) : null}

                      <h3 className="description">Price</h3>
                      <div
                        className="listing-price text-dark "
                        style={{
                          color: "black",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {vendorProfile.data?.costly === "1D"
                          ? "$"
                          : vendorProfile.data?.costly === "2D"
                          ? "$$"
                          : vendorProfile.data?.costly === "3D"
                          ? "$$$"
                          : null}
                      </div>

                      {vendorProfile.data?.categories?.length > 0 ? (
                        <>
                          <div className="vendorCat">
                            <div className="selectedCat">
                              <span className="MainCatLabel">Category</span>
                              <div className="selectCatName">
                                <div className="categoryList">
                                  {vendorProfile.data?.categories.map(
                                    (category, index) => (
                                      <span
                                        key={index}
                                        className="categoryItem"
                                      >
                                        {category?.title}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      {vendorProfile.data?.keywords?.length > 0 ? (
                        <>
                          <div className="vendorCat">
                            <div className="selectedCat">
                              <span className="MainCatLabel">Keywords</span>
                              <div className="selectCatName">
                                <div className="keywordList">
                                  {vendorProfile.data?.keywords.map(
                                    (keyword, index) => (
                                      <span
                                        key={index}
                                        className="categoryItem"
                                      >
                                        {keyword.name}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vendorSep margin-top-10 margin-bottom-20"></div>
                        </>
                      ) : null}

                      {vendorProfile.data?.description ? (
                        <div
                          id="listing-description"
                          className="listing-section"
                        >
                          <h3 className="description">Description</h3>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: vendorProfile.data?.description,
                            }}
                          ></p>

                          <div className="clearfix"></div>
                        </div>
                      ) : null}

                      {vendorProfile.data?.socials &&
                      (vendorProfile.data?.socials.facebook ||
                        vendorProfile.data?.socials.instagram ||
                        vendorProfile.data?.socials.linkedIn ||
                        vendorProfile.data?.socials.pinterest ||
                        vendorProfile.data?.socials.tiktok ||
                        vendorProfile.data?.socials.twitter ||
                        vendorProfile.data?.socials.youtube ||
                        vendorProfile.data?.socials?.website) ? (
                        <section>
                          <strong className="state">Social Media</strong>
                          <h3 className="listing-desc-headline margin-top-10">
                            <ul className="social-icons">
                              {vendorProfile.data?.socials.website ? (
                                <li>
                                  <div className="social-icon-box">
                                    <p>Website</p>
                                    <a
                                      href={vendorProfile.data?.socials.website}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img src={Website} alt="Website link" />
                                    </a>
                                  </div>
                                </li>
                              ) : null}

                              {vendorProfile.data?.socials.facebook ? (
                                <li>
                                  <div className="social-icon-box">
                                    <p>Facebook</p>
                                    <a
                                      href={
                                        vendorProfile.data?.socials.facebook
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={Facebook}
                                        alt="Facebook profile"
                                      />
                                    </a>
                                  </div>
                                </li>
                              ) : null}
                              {vendorProfile.data?.socials.instagram ? (
                                <li>
                                  <div className="social-icon-box">
                                    <p>Instagram</p>
                                    <a
                                      href={
                                        vendorProfile.data?.socials.instagram
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img src={Instagram} alt="Instagram" />
                                    </a>
                                  </div>
                                </li>
                              ) : null}
                              {vendorProfile.data?.socials.linkedIn ? (
                                <li>
                                  <div className="social-icon-box">
                                    <p>Linkedin</p>
                                    <a
                                      href={
                                        vendorProfile.data?.socials.linkedIn
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img src={LinkedIn} alt="Linkedin" />
                                    </a>
                                  </div>
                                </li>
                              ) : null}
                              {vendorProfile.data?.socials.pinterest ? (
                                <li>
                                  <div className="social-icon-box">
                                    <p>Pinterest</p>
                                    <a
                                      href={
                                        vendorProfile.data?.socials.pinterest
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img src={Pinterest} alt="Pinterest" />
                                    </a>
                                  </div>
                                </li>
                              ) : null}
                              {vendorProfile.data?.socials.tiktok ? (
                                <li>
                                  <div className="social-icon-box">
                                    <p>Tiktok</p>
                                    <a
                                      href={vendorProfile.data?.socials.tiktok}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img src={Tiktok} alt="TikTok" />
                                    </a>
                                  </div>
                                </li>
                              ) : null}
                              {vendorProfile.data?.socials.twitter ? (
                                <li>
                                  <div className="social-icon-box">
                                    <p>Twitter</p>
                                    <a
                                      href={vendorProfile.data?.socials.twitter}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img src={Twitter} alt="Twitter" />
                                    </a>
                                  </div>
                                </li>
                              ) : null}
                              {vendorProfile.data?.socials.youtube ? (
                                <li>
                                  <div className="social-icon-box">
                                    <p>Youtube</p>
                                    <a
                                      href={vendorProfile.data?.socials.youtube}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img src={Youtube} alt="Youtube" />
                                    </a>
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          </h3>
                          <div className="vendorSep"></div>
                        </section>
                      ) : null}
                    </div>

                    {/* Massi Deal */}
                    {vendorProfile.deals.length > 0 ? (
                      <div
                        id="listing-deals"
                        className="listing-section margin-bottom-30 margin-top-20 "
                      >
                        <img
                          className="border-bottom   border-3 border-warning"
                          src="../../images/MassiDeal.svg"
                          alt=""
                        />

                        <ul style={{ listStyle: "none" }}>
                          {vendorProfile.deals.map((deal, deals) => {
                            return (
                              <li key={deals}>
                                <div id="deal" className="vendorDeals">
                                  <img
                                    src="/_next/static/media/massi-deals-heading.3f401064.svg"
                                    alt=""
                                  />

                                  {deal.assets.map((asset, key) => {
                                    return (
                                      <div className="dealBoxWrap" key={key}>
                                        <div className="dealContentDetails">
                                          <span
                                            className="fw-bold fs-4 mx-auto text-center"
                                            href="#!"
                                            key={asset.id}
                                          >
                                            <img
                                              className="imageRight"
                                              height={"auto"}
                                              width="33%"
                                              src={URLS.S3BucketURL + asset.url}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                  "./images/default.jpeg";
                                              }}
                                              alt={deal.name}
                                            />
                                            <div className="textRight">
                                              <h4
                                                className="fw-bold "
                                                dangerouslySetInnerHTML={{
                                                  __html: deal.name,
                                                }}
                                              ></h4>
                                              <span
                                                className="fw-lighter "
                                                dangerouslySetInnerHTML={{
                                                  __html: deal.description,
                                                }}
                                              ></span>
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                    {/* Massi deal end */}
                  </div>

                  <div className="col-lg-4 col-md-4 margin-top-35 sticky">
                    <div className="col-md-12  inquireVendor text-center">
                      <div className="row">
                        {vendorProfile.data.position === 0 ? (
                          <div className="col-lg-8">
                            <h2 className="selectedHeadingYellowBrowse">
                              Massi Select
                            </h2>
                          </div>
                        ) : vendorProfile.data.position === 1 ? (
                          <div className="col-lg-8">
                            <h2 className="selectedHeadingSilver">
                              Massi Preferred
                            </h2>
                          </div>
                        ) : null}
                      </div>
                      <span>
                        {vendorProfile.deals.length > 0 ? (
                          <img
                            style={{
                              position: "absolute",
                              top: "15px",
                              right: "20px",
                              width: "90px",
                            }}
                            src="../../images/deal-icon.svg"
                            alt="Deal Icon"
                          />
                        ) : null}
                      </span>
                      {vendorProfile.data.position === 0 ? (
                        <hr></hr>
                      ) : vendorProfile.data.position === 1 ? (
                        <hr></hr>
                      ) : null}

                      <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
                        Interested In {` ${vendorProfile.data.business}?`}
                      </h3>
                      {/* <button
                        className=" book-vendor2 w-75"
                        style={{ fontSize: '14px', fontWeight: '400' }}
                        onClick={() => this.setState({ showContactForm: true })}
                      >
                        Inquire
                      </button> */}
                      <div className="listing-share2 mt-3 no-border col-12">
                        <ul className="share-buttons d-flex mx-auto ">
                          <div className="vendorMetaWrapInquire fs-3 d-flex mx-auto ">
                            <a
                              href={`tel:${vendorProfile.data.phone}`}
                              className="phone"
                            >
                              <img src="../../images/call.svg" alt="Call" />
                              {/* <span className=" mx-3  text-dark">Call Now</span> */}
                            </a>
                            {/* <a
                              className="vendorMetaBoxWrap"
                              href={`mailto:${vendorProfile.data.email}?cc=support@eventmassi.com`}
                            >
                              <img
                                src="../../images/mailer.jpg"
                                alt="Mail"
                                width={25}
                              />
                            </a> */}
                            <button
                              className="vendorMetaBoxWrap border border-0 bg-dark "
                              style={{ fontSize: "14px", fontWeight: "400" }}
                              onClick={() =>
                                this.setState({ showContactForm: true })
                              }
                            >
                              <img
                                src="../../images/sms.svg"
                                alt="Mail"
                                width={25}
                              />
                            </button>
                            <a
                              href="#!"
                              onClick={() =>
                                this.setState({
                                  showShareModal: true,
                                  selectedVendor: vendorProfile,
                                })
                              }
                              className="vendorMetaBoxWrap border border-1 bg-white"
                            >
                              <img
                                src="../../images/share.svg"
                                alt="Share"
                                width={25}
                              />
                            </a>
                          </div>
                          <ReactTooltip place={"left"} />
                        </ul>
                        <div className="clearfix"></div>
                      </div>
                    </div>

                    {/* Contact Form vendor */}
                    <ContactVendorFormModal
                      showContactForm={this.state.showContactForm}
                      closeBtn={this.closeBtn()}
                      vendorProfile={vendorProfile}
                      closeModal={() =>
                        this.setState({ showContactForm: false })
                      }
                    />
                    {/* Contact form vendor end */}

                    {/* Share popup modal */}
                    <SocialShareVendor
                      showShareModal={this.state.showShareModal}
                      closeBtn={this.closeBtn()}
                      title={vendorProfile.data.business}
                      url={window.location.href}
                      image={URLS.S3BucketURL + vendorProfile.data.pic_path}
                      description={vendorProfile.data.description}
                      keywords={vendorProfile.data.keywords}
                      email={vendorProfile.data.email}
                    />
                    {/* Share poup modal end */}
                  </div>
                </div>
              </div>
            ) : null}

            <div>
              {" "}
              {vendorProfile?.data?.position === 2 ||
              vendorProfile?.data?.position === 100 ? (
                <AdsComponent />
              ) : null}
            </div>
          </>
        ) : null}
      </>
    );
  }
}

export default VendorDetail;
