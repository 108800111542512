import React from 'react';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';
import VendorService from '../../services/VendorService';
import CategoriesService from '../../services/CategoriesService';
import KeywordsService from '../../services/KeywordsService';
import StateService from '../../services/StateService';
import VendorSearchForm from '../../components/VendorSearchForm';
import MassiSelectedUserList from '../../components/MassiSelectedUserList';
import MassiNormalUserList from '../../components/MassiNormalUserList';
import BottomPagination from '../../components/BottomPagination';
import MassiPreferredUserList from '../../components/MassiPreferredUserList';
import '../layouts/layout.css';
import { ReactComponent as MySVG } from '../../assets/images/back-to-top.svg';
import ScrollToTop from 'react-scroll-to-top';
import SkeletonCardbrowseVendors from '../../components/Skeltons/SkeletonCardbrowseVendors';
class BrowseVendors extends React.Component {
  state = {
    vendorList: [],
    massiPrefferedVendorList: [],
    massiSelectedVendorList: [],
    massiNormalVendorList: [],
    selectedCategory: null,
    selectedKeyword: null,
    categoriesList: [],
    statesList: [],
    keywordsList: [],
    currentPage: 1,
    dataFetch: false,
    totalPages: 1,
    noResults: false,
    search: null,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    console.log(this.state.selectedCategory);
    let categorieslist = await this.getCategoriesList();

    let statelist = await this.getStateList();
    let keywordslist = await this.getKeywordsList();

    let search = await this.searchSubmit();
    await this.getVendorList(1, search);
    this.setState({ categoriesList: categorieslist });
    this.setState({ statesList: statelist });
    this.setState({ keywordsList: keywordslist });
  }
  searchSubmit = () => {
    const searchParams = new URLSearchParams(document.location.search);
    console.log('SEARCHED ITEM', searchParams);
    const obj = {
      category: searchParams.get('category')
        ? searchParams.get('category')
        : '',
      keyword: searchParams.get('keyword') ? searchParams.get('keyword') : '',

      state: this.state.selectedState?.value
        ? this.state.selectedState?.value
        : '',
      costly: this.state.selectedCostly?.value
        ? this.state.selectedCostly?.value
        : '',
      query: '',
    };

    const result = '&' + new URLSearchParams(obj).toString();

    return result;
  };

  getVendorList = async (page = 1, search = null) => {
    if (search !== null) this.setState({ vendorList: [], dataFetch: false });
    this.setState({ search: search, currentPage: page });
    console.log('page: ', page);
    let vendorlist = await VendorService.getBrowseVendorList(page, search);
    if (vendorlist?.users.length > 0) {
      var massiSelectedVendorList = [];
      var massiPrefferedVendorList = [];
      var massiNormalVendorList = [];
      vendorlist?.users.map((vendor) => {
        return vendor.position === 0
          ? massiSelectedVendorList.push(vendor)
          : vendor.position === 1
          ? massiPrefferedVendorList.push(vendor)
          : massiNormalVendorList.push(vendor);
      });

      this.setState({
        vendorList: massiNormalVendorList,
        massiSelectedVendorList: massiSelectedVendorList,
        massiPrefferedVendorList: massiPrefferedVendorList,
        dataFetch: true,
        totalPages: vendorlist.pagination.totalPages,
        noResults: false,
      });
    } else {
      this.setState({
        noResults: true,
      });
    }
  };

  getCategoriesList = async () => {
    let categorieslist = await CategoriesService.getCategories();
    return categorieslist;
  };
  getKeywordsList = async () => {
    let keywordslist = await KeywordsService.getAllKeywords();
    return keywordslist;
  };

  getStateList = async () => {
    let statelist = await StateService.getStates();
    return statelist;
  };

  handlePagination = async (page) => {
    await this.setState({ vendorList: [], dataFetch: false });
    await this.setState({ currentPage: page });
    window.scrollTo(0, 0);

    await this.getVendorList(page, this.state.search);
  };

  //render method
  render() {
    const styles = {
      container: {
        position: 'fixed',
        bottom: 90,
        width: 40,
        height: 62,
      },
    };
    return (
      <>
        <HeaderDocumentDetail
          title="EventMassi | Vendor List"
          description="Welcome to the Event Massi Vendor List! Here, you will find a comprehensive selection of vendors categorized into three types: selected, preferred, and basic vendors.

At Event Massi, we strive to provide you with a diverse range of vendors to cater to your event planning needs. Our selected vendors are handpicked for their exceptional expertise and track record of delivering high-quality services. Preferred vendors have been recommended by our platform users and have demonstrated their reliability and professionalism. Basic vendors offer a solid foundation of services and are suitable for those seeking essential event solutions."
        />

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <VendorSearchForm
                  categoriesList={this.state.categoriesList}
                  statesList={this.state.statesList}
                  keywordsList={this.state.keywordsList}
                  getVendorList={(search, page = this.state.currentPage) =>
                    this.getVendorList(page, search)
                  }
                  selectedCategory={this.state.selectedCategory}
                  selectedKeyword={this.state.selectedKeyword}
                />
              </div>
            </div>
          </div>
        </section>
        {this.state.noResults ? (
          <div className="d-flex mx-auto text-center justify-content-center mb-5 mt-5">
            <h1> No Vendors Found</h1>
          </div>
        ) : (
          <div>
            {' '}
            <section className="margin-top-15">
              <div className="container">
                {!this.state.dataFetch ? (
                  <>
                    <SkeletonCardbrowseVendors />
                  </>
                ) : (
                  <>
                    {this.state.massiSelectedVendorList.length > 0 ? (
                      <>
                        <div className="row">
                          <div className="col-lg-12 ">
                            <h2 className="selectedHeadingYellow ">
                              Massi Select
                            </h2>
                          </div>
                        </div>

                        <section>
                          <div className="row">
                            <div className="col-lg-12">
                              <MassiSelectedUserList
                                vendorList={this.state.massiSelectedVendorList}
                              />
                            </div>
                          </div>
                        </section>
                      </>
                    ) : null}

                    {this.state.massiPrefferedVendorList.length > 0 ? (
                      <>
                        <hr></hr>{' '}
                        <section className="listings-container margin-bottom-15">
                          <div className="row">
                            <div className="col-lg-12">
                              <h2 className="selectedHeadingSilver ">
                                Massi Preferred
                              </h2>
                            </div>
                          </div>
                          {/* test */}

                          <div className="row ">
                            <div className="col-md-12 border border-0 ">
                              <MassiPreferredUserList
                                vendorList={this.state.massiPrefferedVendorList}
                              />
                            </div>
                          </div>
                        </section>
                      </>
                    ) : null}
                    <div className="row">
                      <div className="col-md-12">
                        <MassiNormalUserList
                          vendorList={this.state.vendorList}
                          dataFetch={this.state.dataFetch}
                        />
                      </div>
                    </div>
                    {this.state.vendorList.length !== 0 ? (
                      <div className="row">
                        <div className="col-md-12  ">
                          <div className="pagination-container text-dark margin-top-20 margin-bottom-40 ">
                            <nav className="pagination justify-content-center">
                              <BottomPagination
                                currentPage={this.state.currentPage}
                                totalPages={this.state.totalPages}
                                handlePagination={(page) =>
                                  this.handlePagination(page)
                                }
                              />
                            </nav>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </section>
          </div>
        )}

        <div>
          <ScrollToTop component={<MySVG />} smooth style={styles.container} />
        </div>
      </>
    );
  }
}

export default BrowseVendors;
