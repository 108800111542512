import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './vendor.css';
import { Editor } from '@tinymce/tinymce-react';

class VendorCompanyAndServiceInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      website: this.props.vendorProfile?.socials?.website || '',
      description: decodeURIComponent(this.props.vendorProfile?.description || ''),
      
    };
  }
  handleInputChange = (e) => {
    const value = e.target.value.trim();
    if (value === 'https://') {
      e.target.value = '';
    }
  };
  // selectedKeywords = [];
  handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
  };
  costly = '';
  handleSubmit = (e) => {
    e.preventDefault();
    // var description = e.target.elements.notes.value;
    const description = encodeURIComponent(e.target.elements.notes.value);
    // var description = encodeURIComponent(e.target.elements.notes.value);

    var socials = {
      website: e.target.elements.website.value,
      facebook: this.props.vendorProfile?.socials?.facebook,
      instagram: this.props.vendorProfile?.socials?.instagram,
      pintrest: this.props.vendorProfile?.socials?.pintrest,
      youtube: this.props.vendorProfile?.socials?.youtube,
      tiktok: this.props.vendorProfile?.socials?.tiktok,
      twitter: this.props.vendorProfile?.socials?.twitter,
    };

    var costly = this.costly;
    var params = {
      description,
      socials,

      costly,
    };
    this.props.updateVendorInfo(params);
  };

  render() {
    const { vendorProfile, allKeywords } = this.props;

    this.costly = vendorProfile?.costly;

    return (
      <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form
            onSubmit={this.handleSubmit}
            className="generalFormWrap myInformationForm"
          >
            <h3 className="editFormHeading">
              Tell us about your company &amp; services
            </h3>
            <div className="customFormGrp">
              <label>Description</label>
              <div className="formControlWrap">
                <Editor
                  apiKey="1ausnzpznit2slgbdbs476puc2wt1bllt99rw6d6zvjflaqh"
                  name="notes"
                  id="notes"
                  initialValue={vendorProfile?.description}
                    // initialValue={this.state.description}
                  init={{
                    branding: false,
                    height: 300,
                    menubar: false,
                    statusbar: false,

                    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',

                    toolbar:
                      'undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | fontselect fontsize',
                  }}
                  onEditorChange={this.handleEditorChange}
                />
              </div>
            </div>

            <div className="customFormGrp">
              <label>Vendor Price</label>
              <div className="radio-wrapper">
                <input
                  type="radio"
                  name="costly"
                  id="option-1"
                  defaultValue={'1D'}
                  defaultChecked={vendorProfile?.costly === '1D'}
                  onChange={() => (this.costly = '1D')}
                />
                <input
                  type="radio"
                  name="costly"
                  id="option-2"
                  defaultValue={'2D'}
                  defaultChecked={vendorProfile?.costly === '2D'}
                  onChange={() => (this.costly = '2D')}
                />
                <input
                  type="radio"
                  name="costly"
                  id="option-3"
                  defaultValue={'3D'}
                  defaultChecked={vendorProfile?.costly === '3D'}
                  onChange={() => (this.costly = '3D')}
                />
                <label htmlFor="option-1" className="option option-1">
                  <span>$</span>
                </label>
                <label htmlFor="option-2" className="option option-2">
                  <span>$$</span>
                </label>
                <label htmlFor="option-3" className="option option-3">
                  <span>$$$</span>
                </label>
              </div>
            </div>
            <div className="customFormGrp">
              <label>Vendor Website</label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  // placeholder="Enter Email"
                  // defaultValue={vendorProfile?.socials?.website}
                  // id="website"
                  id="website"
                  value={this.state.website}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value) {
                      this.setState({ website: '' });
                      return;
                    }
                    if (
                      !value.startsWith('http://') &&
                      !value.startsWith('https://')
                    ) {
                      value = 'https://' + value;
                    }
                    this.setState({ website: value });
                  }}
                />
              </div>
            </div>
            <div className="customFormGrp btnFormGrp">
              <button className="col-lg-3 editProfileSubmitBtn">
                Update About Us
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default VendorCompanyAndServiceInfo;
