import React from 'react';
import { URLS } from '../util/constants';
import { Rings } from 'react-loader-spinner';

class VendorUploadedAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { assets } = this.props;
    console.log(assets, 'ASSETS');
    return (
      <>
        {assets === undefined || assets === null || assets === '' ? (
          <div
            className="col-lg-12"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Rings
              height="200"
              width="200"
              radius="9"
              color="#edb300"
              ariaLabel="three-dots-loading"
            />
          </div>
        ) : assets.length > 0 ? (
          assets.map((asset) => {
            return asset.type === 'image' ? (
              <div
                className="col-lg-4 col-md-6 col-12 mx-auto d-flex "
                key={asset._id}
              >
                <a href="#!" className="listing-item-container">
                  <div className="listing-item-vendor" key={asset.id}>
                    <img src={`${URLS.S3BucketURL + asset.url}`} alt="Assets" />
                    <span
                      className="trash-icon"
                      onClick={() => this.props.deleteVendorAsset(asset)}
                    ></span>
                  </div>
                </a>
              </div>
            ) : (
              <div
                className="col-lg-4 col-md-6 col-12 mx-auto d-flex"
                key={asset._id}
              >
                <a href="#!" className="listing-item-container">
                  <div className="listing-item" key={asset.id}>
                    <video
                      controls
                      src={`${URLS.S3BucketURL + asset.url}`}
                      poster={`${URLS.S3BucketURL + asset.Videothumbnail}`}
                      alt="Assets"
                      style={{ width: '100%', height: 'auto%' }}
                    />
                    <span
                      className="trash-icon"
                      onClick={() => this.props.deleteVendorAsset(asset)}
                    ></span>
                  </div>
                </a>
              </div>
            );
          })
        ) : null}
      </>
    );
  }
}

export default VendorUploadedAssets;
