import React from 'react';
import './cookies.css';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';
import { useEffect } from 'react';

const Cookies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderDocumentDetail
        title="EventMassi | Cookies Policy"
        description="Our Cookies Policy explains how EventMassi uses cookies and similar tracking technologies to enhance your experience on our event management platform. Cookies are essential for providing personalized content, improving functionality, and analyzing site traffic. By using EventMassi, you consent to the use of cookies in accordance with our policy. Please review our Cookies Policy for detailed information on how we use cookies and how you can manage your preferences."
      />

      <div className="contentContainer">
        <div className="privacyTopBoxWrap">
          <h2 className="privacyTopBoxHeading">www.eventmassi.com</h2>
          <p className="privacyTaglineText"> COOKIES</p>
        </div>
        <div className="privacyContentWrap">
          <div className="insideGridContainer">
            <h3>INTRODUCTION</h3>
            <p>
              EVENT MASSI LLC (“we” or “us” or “our”) may use cookies, web
              beacons, tracking pixels, and other tracking technologies when you
              visit our website [eventmassi.com], including any other media
              form, media channel, mobile website, or mobile application related
              or connected thereto (collectively, the “Site”) to help customize
              the Site and improve your experience. <br></br>The website,
              eventmassi.com uses cookies as described herein. By using our
              website, you are agreeing to our use of cookies in accordance with
              this Cookie Policy and to the underlying personal data processing,
              unless you have disabled cookies in your browser settings.{' '}
              <br></br>
              If you do not agree to our use of cookies in this way, you should
              set your browser settings accordingly. You will always be able to
              change your choice by amending your browser settings in the
              future. If you disable cookies that we use, this may impact your
              user experience while on eventmassi.com.<br></br> We reserve the
              right to make changes to this Cookies Policy at any time and for
              any reason. We will alert you about any changes by updating the
              “Last Updated” date of this Cookie Policy. Any changes or
              modifications will be effective immediately upon posting the
              updated Cookie Policy on the Site, and you waive the right to
              receive specific notice of each such change or modification.
              <br></br>You are encouraged to periodically review this Cookie
              Policy to stay informed of updates. You will be deemed to have
              been made aware of, will be subject to, and will be deemed to have
              accepted the changes in any revised Cookie Policy by your
              continued use of the Site after the date such revised Cookie
              Policy is posted.
            </p>
            <h3>WHAT ARE COOKIES? </h3>
            <p>
              Cookies are files or pieces of information that may be stored on
              your computer (or other internet enabled devices, such as a
              smartphone or tablet) when you visit EVENT MASSI Website. A cookie
              will usually contain the name of the website from which the cookie
              has come from, the "lifetime" of the cookie (i.e. how long the
              cookie will remain on your device), and a value, which is usually
              a randomly generated unique number. <br></br>Two types of cookies
              may be used, "session cookies" and "persistent cookies". Session
              cookies are automatically deleted at the end of your browsing
              session. Persistent cookies remain longer on your device, for the
              duration of each specific cookie, and will remain valid until its
              set expiry date (unless deleted by the user before the expiry
              date).<br></br> Cookies can be used by web servers to identify and
              track users as they navigate different pages on a website and
              identify users returning to a website. Cookies do not contain any
              information that personally identifies you, but personal
              information that we store about you may be linked to the
              information stored in and obtained from cookies.
            </p>
            <h3>USE OF COOKIES </h3>
            <p>
              A “cookie” is a string of information which assigns you a unique
              identifier that we store on your computer. Your browser then
              provides that unique identifier to use each time you submit a
              query to the Site. We use cookies on the Site to, among other
              things, keep track of services you have used, record registration
              information, record your user preferences, keep you logged into
              the Site, facilitate purchase procedures, and track the pages you
              visit. Cookies help us understand how the Site is being used and
              improve your user experience.{' '}
            </p>
            <h3>TYPES OF COOKIES</h3>
            <p>
              The following types of cookies may be used when you visit the
              Site:
            </p>
            <h4 className="bolder">Advertising Cookies</h4>
            <p>
              Advertising cookies are placed on your computer by advertisers and
              ad servers in order to display advertisements that are most likely
              to be of interest to you. These cookies allow advertisers and ad
              servers to gather information about your visits to the Site and
              other websites, alternate the ads sent to a specific computer, and
              track how often an ad has been viewed and by whom. These cookies
              are linked to a computer and do not gather any personal
              information about you.{' '}
            </p>
            <h4>Analytics Cookies</h4>
            <p>
              Analytics cookies monitor how users reached the Site, and how they
              interact with and move around once on the Site. These cookies let
              us know what features on the Site are working the best and what
              features on the Site can be improved.
            </p>
            <h4>Third-party cookies</h4>
            <p>
              Third-party cookies may be place on your computer when you visit
              the Site by companies that run certain services we offer. These
              cookies allow the third parties to gather and track certain
              information about you. These cookies can be manually disabled in
              your browser.{' '}
            </p>
            <h3>HOW CAN I DISABLE OR DELETE COOKIES?</h3>
            <p>
              If you no longer want cookies to be stored on your device, you can
              withdraw your consent at any time by adjusting your browser
              settings, so that your browser refuses all cookies or the cookies
              from third parties. You can also delete the cookies that have
              already been placed on your device.<br></br> If the cookies are
              disabled, our Websites will continue to work. However, some parts
              of our Websites, such as the shipping tool or the tracking and
              tracing tool of packages, might not work when cookies are
              disabled. Moreover, disabling cookies does not mean that you will
              no longer see advertisements. This only means that advertisements
              will not be adjusted to your interests or that they will be
              repeated more often. If you want to disable cookies from specific
              parties, you can do so via www.youronlinechoices.com.<br></br> How
              you manage your cookie settings, including deletion, may differ
              per browser. Please use the “Help” function in your browser.
            </p>
            <h3>WHAT IF YOU HAVE OTHER QUESTIONS OR COMPLAINTS?</h3>
            <p>
              Questions or complaints regarding the use of cookies can be
              directed to EVENT MASSI by using the contact information provided
              at the top of this Cookie Notice.
            </p>
            <h3>WILL THERE BE UPDATES TO THIS COOKIE POLICY?</h3>
            <p>
              EVENT MASSI LLC may update this Cookie Policy from time to time.
              If an amendment will have a serious impact, EVENT MASSI LLC will
              endeavor to actively inform you about such amendments.
            </p>
            <h3>CONTROL OF COOKIES</h3>
            <p>
              For more information on how to control cookies, check your browser
              or device’s settings for how you can control or reject cookies, or
              visit the following links: Apple Safari Google Chrome Microsoft
              Edge Microsoft Internet Explorer Mozilla Firefox Opera Android
              (Chrome) Blackberry Iphone or Ipad (Chrome) Iphone or Ipad
              (Safari) In addition, you may opt-out of some third-party cookies
              through the Network Advertising Initiative’s Opt-Out Tool.
            </p>
            <h3>FOR WHAT PURPOSES DOES EVENT MASSI USES COOKIES?</h3>
            <p>
              Many cookies on the EVENT MASSI Website perform essential
              functions or services your have requested or for security
              purposes, as further described in the table below. We use cookies
              and pixels to make eventmassi.com Website easier to use and to
              better tailor our Website and our products to your interests and
              needs. Cookies may also be used to help speed up your future
              activities and experience on eventmassi.com Website. We also use
              cookies to compile anonymous, aggregated statistics that allow us
              to understand how users use eventmassi.com Website and to help us
              improve the structure and content of our Website (for example, by
              monitoring which features of the Website are being used, which
              pages are visited, how often, and what information is downloaded).
            </p>

            <table className="cookie-table">
              <thead>
                <tr>
                  <th>Cookie Name</th>
                  <th>Purpose of the Cookie</th>
                  <th>Duration of the Cookie</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Google Analytics</td>
                  <td>
                    We use Google Analytics to optimize our user experience on
                    our website, Google Analytics is a web analytics service
                    provided by Google, Inc. ("Google"). These cookies are used
                    to collect information about how visitors use our Website.
                    We use the information to compile reports and to help us
                    improve the experience on our website. The cookies collect
                    information in an anonymous form, including the number of
                    visitors to the site, where visitors have come to the site
                    from and the pages visited.
                    <br />
                    <a href="http://www.google.com/analytics/learn/privacy.html">
                      More information
                    </a>
                  </td>
                  <td>Persistent</td>
                </tr>
                <tr>
                  <td>Hubspot</td>
                  <td>
                    We use Hubspot for our live chat service. If a visitor
                    chooses to minimize a chatflow, HubSpot will respect that
                    visitor behavior across pages and chatflows until the
                    hs-messages-hide-welcome-message cookie expires after 24
                    hours. You also do have the option to enable the cookie
                    consent text for the live chat widget, click to toggle the
                    Consent to collect chat cookies switch on. However, you
                    cannot customize the consent to cookies text that appears in
                    the banner. More information can be found at:
                    <br />
                    <a href="https://knowledge.hubspot.com/chatflows/create-a-live-chat">
                      More information
                    </a>
                  </td>
                  <td>Session</td>
                </tr>
                <tr>
                  <td>Facebook Pixel</td>
                  <td>
                    We use Facebook Pixel to serve relevant ads and get insights
                    into how many people visit our website, and how our users
                    interact with it. Facebook pixel is used to help us to
                    better estimate the number of individual anonymous visitors
                    to our website
                    <br />
                    <a href="https://www.facebook.com/business/learn/facebook-ads-pixel">
                      More information
                    </a>
                  </td>
                  <td>Persistent</td>
                </tr>
              </tbody>
            </table>

            <h3>DOES EVENT MASSI USE THIRD PARTY COOKIES? </h3>
            <p>
              We use a number of suppliers that may also set cookies on your
              device on our behalf when you visit eventmassi.com Website to
              allow them to deliver the services they are providing. If you
              would like more information about these cookies, as well as
              information about how to opt-out of receiving such cookies, please
              see their privacy policies listed below: When you visit
              eventmassi.com Website, you may receive cookies from third party
              websites or domains. We do not control the placing of these
              cookies and you should check the relevant third party's website
              for more information about these cookies. The relevant third party
              is responsible for providing you with information regarding the
              cookies they place and obtaining your consent before placing
              cookies on your device.
            </p>
            <h3>PRIVACY POLICY</h3>
            <p>
              For more information about how we use information collected by
              cookies and other tracking technologies, please refer to our
              Privacy Policy on the website. By using the Site, you agree to be
              bound by this Cookie Policy and our Privacy Policy.
            </p>
            <h3>CONTACT US</h3>
            <p>
              If you have questions or comments about this Cookie Policy, please
              contact us at: support@eventmassi.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookies;
