import React from 'react';
import withRouter from '../../helper/withRouter';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';
import VendorService from '../../services/VendorService';
import ExploreVendorDetail from '../../components/ExploreVendorDetail';
import SkeletonExplore from '../../components/Skeltons/SkeltonCardExplore';

class ExploreVendor extends React.Component {
  state = {
    vendorProfile: null,
  };

  async componentDidMount() {
    this.getExploreVendorProfile();
  }

  getExploreVendorProfile = async () => {
    window.scrollTo(0, 0);
    try {
      let vendorProfile = await VendorService.getExploreVendorProfile();
      if (vendorProfile.success) {
        console.log('VENDOR PROFILE DATA', vendorProfile);
        var item = {
          data: vendorProfile.data[
            Math.floor(Math.random() * vendorProfile.data.length)
          ],
        };
        // var item = {
        //   data: vendorProfile.data[40],
        // };
        // console.log('item: ', item);
        this.setState({ vendorProfile: item });
      }
    } catch (err) {}
  };

  //render method
  render() {
    const { vendorProfile } = this.state;
    return (
      <>
        <HeaderDocumentDetail
          title="EventMassi|Explore"
          description="Welcome to the Event Massi Explore page! Here, you will discover a curated selection of preferred vendors and selected vendors who are experts in their respective fields and have been chosen to enhance your event planning experience.

At Event Massi, we understand the importance of partnering with reliable and trusted vendors to ensure the success of your events. We have carefully handpicked a diverse range of vendors who excel in providing top-notch products and services to meet your event needs."
        />

        {vendorProfile?.data ? (
          <ExploreVendorDetail
            vendorProfile={this.state.vendorProfile}
            loginFormShow={(value) => this.props.loginFormShow(value)}
          />
        ) : (
          <SkeletonExplore />
        )}
      </>
    );
  }
}

export default withRouter(ExploreVendor);
