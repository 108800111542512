import React, { useState, useEffect } from 'react';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';
import { ApiRoute } from '../../util/constants';
import SkeltonCardFaqs from '../../components/Skeltons/SkeltonCardFaqs';

import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
const Massi = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    axios
      .get(`${ApiRoute.getAllFaqs}`)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      <HeaderDocumentDetail
        title="EventMassi | FAQ"
        description="Welcome to the EventMassi FAQ page! Here you will find a comprehensive list of frequently asked questions and their answers regarding EventMassi, a popular event management platform. Whether you're an event organizer, attendee, or simply curious about EventMassi, this page will provide you with all the information you need."
      />

      {isLoading ? (
        <>
          <SkeltonCardFaqs />
        </>
      ) : (
        <div>
          <img
            className=" mx-auto d-flex"
            width={400}
            src="../../../images/FaqImage.svg"
            alt="Faq Logo"
          />
          <h1 className="questions" style={{ fontSize: '30px' }}>
            Do You Have Any Questions About Event Massi?
          </h1>
          <h3 className="text-center">
            Learn More About Event Massi Below, Or Contact Us!
          </h3>
          <h1 className="fs-1 fw-bolder mt-5 mx-5 ">
            Frequently Asked Questions
          </h1>

          {data.map((data, index) => (
            <div className="mx-5 mt-5" key={index}>
              <Accordion defaultActiveKey="" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p>{`${data.title}`}</p>
                  </Accordion.Header>

                  <Accordion.Body>
                    <h2> {`${data.description}`}</h2>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ))}

          <div className="container mb-5 mt-5 feedback p-5">
            <h1 className="display-4 ">Give Us Your Feedback</h1>
            <h3 className="text-center mt-3 opacity-50">
              Do you have any questions or concerns?
            </h3>

            <a href="/contact_us" className="contact ">
              Contact Us
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Massi;
