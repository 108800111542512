import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import { Link } from 'react-router-dom';
import { Local_routes } from '../util/constants';
import Search from '../pages/layouts/SearchHomePageBanner';
import { Carousel } from 'react-bootstrap';
import LoginSignupModal from '../components/LoginSignupModal';
import SignupModal from '../components/LoginSignupModal2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ApiRoute } from '../util/constants';
import SkeletonCardHomeBannerSlider from './Skeltons/SkeletonCardHomeBannerSlider';
import './home.css';

class HomeBannerSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Drawer: false,
      signinModal: false,
      signupModal: false,
      userAuth: localStorage.getItem('userToken')
        ? atob(localStorage.getItem('userToken'))
        : null,
      userData: localStorage.getItem('userData')
        ? JSON.parse(atob(localStorage.getItem('userData')))
        : null,

      data: [],
      loading: true,
    };
  }

  async componentDidMount() {
    await fetch(`${ApiRoute.getAllSliders}`)
      .then((res) => res.json())
      .then(async (data) => {
        await this.preloadImages(data.data);
        // this.setState({
        //   data: data.data,
        //   loading: false,
        // });
      });
  }

  preloadImages = async (data) => {
    // Create an array to store image objects
    const imageObjects = [];

    // Iterate through data and create Image objects for each image
    // data.forEach((item) => {
    //   const image = new Image();
    //   image.src = 'https://massi-bucket.s3.amazonaws.com/' + item.imagePath;
    //   imageObjects.push(image);
    // });

    let images = data.map(
      (image) =>
        new Promise((rs, rj) => {
          console.log(image);
          const img = new Image();
          img.src = 'https://massi-bucket.s3.amazonaws.com/' + image.imagePath;
          img.onload = () => rs(img);
          img.onerror = () => rj();
        })
    );
    console.log('Images: ', images);
    images = await Promise.all(images);
    console.log('Images: 2', images);
    this.setState({
      data: images,
      loading: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginFormShow !== this.props.loginFormShow) {
      this.setState({ signupModal: this.props.loginFormShow });
    }
  }

  //handle drawer navigation
  toogledrawer = (value) => {
    this.setState({ Drawer: value });
  };

  loginModalClose = (redirect = false) => {
    this.props.closeLoginModal();
    this.setState({
      signinModal: false,
      signupModal: false,
      userAuth: localStorage.getItem('userToken')
        ? atob(localStorage.getItem('userToken'))
        : null,
      userData: localStorage.getItem('userData')
        ? JSON.parse(atob(localStorage.getItem('userData')))
        : null,
    });
    if (redirect) {
      this.props.navigate('/dashboard', {
        userAuth: this.state.userAuth,
        userData: this.state.userData,
      });
    }
  };
  state = {};
  render() {
    const { userData, userAuth, data, loading } = this.state;

    if (loading) {
      return (
        <div
          className="col-lg-12"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <SkeletonCardHomeBannerSlider />
        </div>
      );
    }
    return (
      <>
        <div className="contentContainer container">
          <div className="homeFirstContainerWithImg">
            <div className="container col-lg-12">
              <Carousel
                className="w-100  border-0  "
                indicators={false}
                interval={null}
              >
                {data.map((item, index) => (
                  <Carousel.Item key={index}>
                    {console.log('Item:', item)}
                    <div className="carslider-wrapper">
                      <LazyLoadImage
                        style={{ width: '100%', height: '100%' }}
                        className="carslider"
                        effect="blur"
                        src={item.src}
                        alt={`Slide ${index + 1}`}
                        threshold={100}
                      />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              <Carousel.Caption>
                <h2>
                  Need help finding Nearby&nbsp;
                  <span className="typed-words">
                    <ReactTypingEffect
                      text={[
                        'Event Vendors?',
                        ' Photographers?',
                        'Venues',
                        ' Makeup Artists?',
                      ]}
                      speed={80}
                      eraseSpeed={80}
                    />
                  </span>
                </h2>
                <div className="headerSearchFormBanner mx-auto ">
                  <Search />
                </div>{' '}
              </Carousel.Caption>
            </div>
          </div>
          <div className="homeSubFirstContainer">
            <div className="container">
              {userAuth && userData ? (
                <button className="subFirstSectionBtns col-lg-2 col-sm-4 ">
                  <Link className="text-light" to={Local_routes.dashboard}>
                    Visit My Vendor DashBoard
                  </Link>
                </button>
              ) : (
                <button className="subFirstSectionBtns col-lg-2 col-sm-4 ">
                  <Link className="text-light" to={Local_routes.signup}>
                    Create an account now!
                  </Link>
                </button>
              )}
            </div>
          </div>
          <LoginSignupModal
            signinupModal={this.state.signinModal}
            loginModalClose={(redirect) => this.loginModalClose(redirect)}
          />
          <SignupModal
            signinupModal={this.state.signupModal}
            loginModalClose={(redirect) => this.loginModalClose(redirect)}
          />
        </div>
      </>
    );
  }
}

export default HomeBannerSlider;
