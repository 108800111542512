import React, { useState, useEffect } from 'react';
import './BrowseLiveSearch.css';
import { Link } from 'react-router-dom';

import { DebounceInput } from 'react-debounce-input';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Local_routes } from '../../util/constants';
import { ApiRoute } from '../../util/constants';

function BrowseLiveSearch() {
  const [APIData, setAPIData] = useState([]);
  const [keyData, setkeyData] = useState([]);
  const [catData, setCatData] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();

  const [catFilteredData, setcatFilteredData] = useState([]);
  const [catEntered, setcatEntered] = useState('');
  const [keyfilteredData, setKeyFilteredData] = useState([]);
  const [keyEntered, setKeyEntered] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState('');

  useEffect(() => {
    axios.get(`${ApiRoute.getAllUsers}`).then((response) => {
      setAPIData(response.data.data);
    });
  }, []);
  useEffect(() => {
    axios.get(`${ApiRoute.getAllCategories}`).then((response) => {
      setCatData(response.data.data);
    });
  }, []);
  useEffect(() => {
    axios.get(`${ApiRoute.getAllKeywords}`).then((response) => {
      setkeyData(response.data.data);
    });
  }, []);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = APIData.filter((value) => {
      return Object.values(value)
        .join('')
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });

    const catWord = event.target.value;

    setcatEntered(catWord);
    const catFilter = catData.filter((value) => {
      return Object.values(value)
        .join('')
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });

    const keyWord = event.target.value;
    setKeyEntered(keyWord);
    const keyFilter = keyData.filter((value) => {
      return Object.values(value)
        .join('')
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });

    if (searchWord === '') {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
    if (catWord === '') {
      setcatFilteredData([]);
    } else {
      setcatFilteredData(catFilter);
    }
    if (keyWord === '') {
      setKeyFilteredData([]);
    } else {
      setKeyFilteredData(keyFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered('');
    setcatFilteredData([]);
    setcatEntered('');
    setKeyFilteredData([]);
    setKeyEntered('');
  };

  return (
    <div className="search  ">
      <div className="searchInputs">
        {/* <div className="searchIcon">
          {filteredData.length === 0 ? (
            <SearchIcon className="text-dark w-75" />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div> */}
        <DebounceInput
          // minLength={4}
          style={{ border: '2px solid #ccc' }}
          // debounceTimeout={500}
          type="text"
          placeholder="Search"
          className="vendorSearch text-dark"
          value={(wordEntered, catEntered, keyEntered)}
          // stateEntered
          onChange={handleFilter}
        />
      </div>

      <div className="BrowseVendordataResult">
        {filteredData.length !== 0 && (
          <>
            <h3 className="mx-3">Vendors</h3>
            {filteredData.slice(0, 15).map((value, vendors) => {
              return (
                <Link
                  onClick={clearInput}
                  target="_self"
                  to={Local_routes.vendor_detail + '/' + value.profile_url}
                  className="vendorInsideContentDetails dataItem"
                  key={vendors}
                >
                  <p>{value.business}</p>
                </Link>
              );
            })}
          </>
        )}

        {catFilteredData.length !== 0 && (
          <>
            <h3 className="mx-3 text-success">Category</h3>
            {catFilteredData.slice(0, 15).map((value, cat) => {
              return (
                <a
                  onClick={() => {
                    setSearchParams({ category: value._id });
                  }}
                  key={cat}
                  className="dataItem"
                  target="_self"
                  href={Local_routes.vendors + '/?' + searchParams}
                >
                  <p>{value.title} </p>
                </a>
              );
            })}
          </>
        )}
        {keyfilteredData.length !== 0 && (
          <>
            <div style={{ maxHeight: 200 }}>
              <h3 className="mx-3 text-primary ">Keywords</h3>
              {keyfilteredData.slice(0, 15).map((value, kword) => {
                return (
                  <a
                    onClick={() => {
                      setSearchParams({ keyword: value._id });
                    }}
                    className="dataItem"
                    href={Local_routes.vendors + '/?' + searchParams}
                    target="_self"
                    key={kword}
                  >
                    <p>{value.name} </p>
                  </a>
                );
              })}
            </div>
          </>
        )}
        {/* Conditionally render when no results are found */}
        {wordEntered !== '' && keyEntered !== '' && catEntered !== '' ? (
          <div className="noResultsFound p-1 border border-1 ">
            <h4 className=" text-center">
              Can't find what you are looking for?
            </h4>

            <a href={Local_routes.vendors} className=" text-center">
              <h3>Browse Vendors</h3>
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default BrowseLiveSearch;
