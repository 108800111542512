import React from 'react';
import { Link } from 'react-router-dom';
import { URLS, Local_routes } from '../util/constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class MassiNormalUserList extends React.Component {
  render() {
    const { vendorList, dataFetch } = this.props;
    console.log('Props coming', this.props);
    return (
      <>
        {dataFetch && vendorList.length > 0 ? (
          <>
            <section className="listings-container ">
              <div className="row fs-listings">
                {vendorList
                  .filter(
                    (vendor) =>
                      vendor.business !== null && vendor.profile_url !== null
                  )
                  .map((vendor, normal) => {
                    return (
                      <div
                        key={normal}
                        // col-md-3
                        className="col-lg-4  col-sm-6 normal-vendor-list-item"
                      >
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          to={
                            Local_routes.vendor_detail +
                            '/' +
                            vendor.profile_url
                          }
                          className="listing-item-container border-0"
                        >
                          {' '}
                          <div className="listing-item">
                            {vendor.pic_path === '' ||
                            vendor.pic_path === null ||
                            vendor.pic_path === 'undefined' ? (
                              <LazyLoadImage
                                effect="blur"
                                src="../../images/noImage.svg"
                                alt={vendor.business}
                              />
                            ) : (
                              <LazyLoadImage
                                effect="blur"
                                src={`${URLS.S3BucketURL + vendor.pic_path}`}
                                alt={vendor.business}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = '../../images/noImage.svg';
                                }}
                              />
                            )}
                          </div>
                          <div className="star-rating normal-vendor-list">
                            <div className="rating-counter">
                              {vendor.business}
                              <br></br>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </section>
          </>
        ) : null}
      </>
    );
  }
}

export default MassiNormalUserList;
