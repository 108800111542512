import React from 'react';
import './vendor.css';
import Swal from 'sweetalert2';
import AuthService from '../services/AuthService';
import { useNavigate } from 'react-router-dom';
import { ApiRoute } from '../util/constants';
import { useState } from 'react';
const VendorDeleteMyProfile = (props) => {
  console.log('PROPS', props.vendorProfile?._id);

  const navigate = useNavigate();
  const [userAuth, setUserAuth] = useState(null);
  const [userData, setUserData] = useState(null);

  async function deleteVendor(_id) {
    let token = AuthService.getToken();
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        // in result you have the answer given by user from modal
        if (result.isConfirmed) {
          // if the user give yes answer then it means we need to call delete api
          const deleteVendorUrl = ApiRoute.deleteVendor(_id);
          fetch(deleteVendorUrl, {
            method: 'DELETE',
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }).then((result) => {
            result.json().then((res) => {
              console.log('RESPONSE', res);
              if (res.success) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Your Account Has Been Successfully Deleted',
                  showConfirmButton: false,
                  timer: 1500,
                });
                setUserAuth(null);
                setUserData(null);
                localStorage.removeItem('userToken');
                localStorage.removeItem('userData');
                localStorage.removeItem('isPaid');
                setTimeout(() => {
                  window.location.href = '/';
                }, 2000);
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'SERVER ERROR',
                  showConfirmButton: false,
                });
              }
            });
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="editProfileContentWrap mt-5">
      <div className="container">
        <form
          className="generalFormWrap myInformationForm"
          onSubmit={(event) => event.preventDefault()}
        >
          <h3 className="editFormHeading">Delete My Profile</h3>
          <p className="text-center mx-auto ">
            This will delete your business listing from Event Massi, you will no
            longer be listed in our directory.
          </p>
          <div className="customFormGrp btnFormGrp">
            <button
              onClick={() => deleteVendor(props.vendorProfile._id)}
              className="col-lg-3 editProfileSubmitBtn"
            >
              Delete My Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VendorDeleteMyProfile;
