import { ApiRoute, CONSTANTS } from '../util/constants';

const authModule = {
  login,
  register,
  getToken,
  forgotPassword,
};

export default authModule;

async function login(email, password) {
  let data = { email, password };
  let response = await fetch(`${ApiRoute.login}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();

  if (response) return response;
  throw new Error(`Could not Initialize login: ${response}`);
}

async function register(email, password, recaptchaToken) {
  let data = { email, password, recaptchaToken };
  let response = await fetch(`${ApiRoute.register}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Initialize Register: ${response}`);
}

async function forgotPassword(email) {
  let data = { email };
  let response = await fetch(`${ApiRoute.forgotPassword}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Initialize ForgotPassword: ${response}`);
}

function getToken() {
  let token = localStorage.getItem('userToken');
  if (token) {
    token = atob(token);
    return token;
  }
  return null;
}
