import React from 'react';
import { Helmet } from 'react-helmet';
import Icon from '../assets/images/EMIcon.svg';
class HeaderDocumentDetail extends React.Component {
  render() {
    const canonicalUrl = window.location.href;

    console.log('THIS<PROPS', this.props);
    return (
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.title}</title>
          <meta name="description" content={this.props.description}></meta>
          <link rel="canonical" href={canonicalUrl} />
          <link rel="icon" href={this.props.picture || Icon} />
          <link rel="apple-touch-icon" href={this.props.picture || Icon} />
          <link
            rel="icon"
            type="image/x-icon"
            href={this.props.picture || Icon}
            alt=" Event Massi Logo"
          ></link>

          <meta property="og:title" content={this.props.title} />
          <meta property="og:description" content={this.props.description} />
          <meta property="og:image" content={this.props.picture || Icon} />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={this.props.picture || Icon} />
          <meta name="twitter:site" content={canonicalUrl} />
          <meta name="apple-mobile-web-app-title" content={this.props.title} />
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="black-translucent"
          />
          <link rel="apple-touch-icon" href={this.props.picture || Icon} />

          <meta name="twitter:title" content={this.props.title} />
          <meta name="twitter:description" content={this.props.description} />
          <meta name="twitter:image" content={this.props.picture || Icon} />
          <meta name="twitter:card" content={this.props.picture || Icon} />

          <meta itemprop="name" content={this.props.title} />
          <meta itemprop="description" content={this.props.description} />
          <meta itemprop="image" content={this.props.picture || Icon} />
        </Helmet>
      </div>
    );
  }
}

export default HeaderDocumentDetail;
