import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Accordion from 'react-bootstrap/Accordion';

const SkeltonCardFaqs = () => {
  return (
    <div>
      <Skeleton className="mx-auto d-flex" width={400} height={250} />

      <h1 className="questions" style={{ fontSize: '30px' }}>
        <Skeleton width={400} height={30} />
      </h1>
      <h3 className="text-center">
        <Skeleton width={200} height={20} />
      </h3>
      <h1 className="fs-1 fw-bolder mt-5 mx-5 ">
        <Skeleton width={300} height={40} />
      </h1>

      {Array.from({ length: 5 }).map((_, index) => (
        <div className="mx-5 mt-5" key={index}>
          <Accordion defaultActiveKey="" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Skeleton width={250} height={20} />
              </Accordion.Header>

              <Accordion.Body>
                <Skeleton count={2} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ))}

      <div className="container mb-5 mt-5 feedback p-5">
        <h1 className="display-4 ">
          <Skeleton width={200} height={40} />
        </h1>
        <h3 className="text-center mt-3 opacity-50">
          <Skeleton width={300} height={20} />
        </h3>

        <a href="/contact_us" className="contact ">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default SkeltonCardFaqs;
