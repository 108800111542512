import React from 'react';
import Swal from 'sweetalert2';
class VendorVideoAssetUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  componentDidMount() {}

  // uploadVideo = (e) => {
  //   this.setState({ isLoading: true });
  //   this.props.uploadVendorAssetVideo(e).then(() => {
  //     this.setState({ isLoading: false });
  //   });
  // };
  uploadVideo = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      // Handle the case where no file is selected
      return;
    }

    if (!selectedFile.type.startsWith('video/')) {
      // Check if the selected file is a video
      Swal.fire({
        title: 'Invalid File Type',
        text: 'Please select a valid video file.',
        icon: 'error',
      });
      return;
    }

    this.setState({ isLoading: true });
    this.props.uploadVendorAssetVideo(e).then(() => {
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { videoProgress, videoUploadInProgress } = this.props;
    return (
      <>
        <div className="row margin-top-20 margin-right-10 margin-left-20">
          <div className="add-listing-section ">
            <div className="submit-section">
              <form className="dropzone">
                <img src="../images/XMLID_8_.svg" alt="Upload Asset" />
                <input
                  type="file"
                  className="upload_deal_image upload_vendor_asset_image"
                  accept="video/*"
                  onChange={(e) => this.uploadVideo(e)}
                />
              </form>
            </div>
          </div>

          {videoUploadInProgress && (
            <div
              style={{
                width: '100%',
                backgroundColor: '#f5f5f5',
                padding: '10px',
              }}
            >
              <div className="progress" style={{ height: '30px' }}>
                <div
                  className="progress-bar progress-bar-striped active"
                  role="progressbar"
                  aria-valuenow={videoProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                    width: `${videoProgress}%`,
                    backgroundColor: '#007bff',
                    color: '#fff',
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  {`${videoProgress}%`}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default VendorVideoAssetUploader;
