import React, { useState, useEffect } from 'react';
import './blogs.css';
import axios from 'axios';
import { Rings } from 'react-loader-spinner';
import { ApiRoute } from '../../util/constants';

const Blogs = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${ApiRoute.getAllBlogs}`)
      .then((res) => {
        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '200',
          }}
        >
          <Rings visible={true} height="200" width="200" color="#edb300" />
        </div>
      ) : (
        <div className="container-fluid">
          <hr></hr>
          <div>
            {data.map((product, index) => (
              <div key={index}>
                {/*  */}
                <div className="blogPostWrap firstBlogPost">
                  <div className="insideBlogPost">
                    <div className="blogImgWrap">
                      <img src={product.image} alt="" />
                    </div>
                    <div className="insideBlogDetails">
                      <h4>{`${product.title}`}</h4>
                      <p>{`${product.description}`}</p>
                      <span className="blogPostTime">
                        <span>3 Min Ago</span>
                      </span>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            ))}
          </div>
          <br></br>
          <br></br>
        </div>
      )}
    </div>
  );
};

export default Blogs;
