import React, { useState, useEffect } from "react";
import axios from "axios";
import "./InstaFeed.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import Feed from "./Feed";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  appendDots: (dots) => {
    return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={40} />;
  },
  responsive: [
    {
      // breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
      },
    },
  ],
};

const InstaFeed = (props) => {
  const [feeds, setFeedsData] = useState([]);

  useEffect(() => {
    const fetchInstagramPost = async () => {
      try {
        const resp = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&access_token=${"IGQWRPdlJFdE1zWGNiLTB2MjZAWYzkxTVFvSGEzZAWM4U2NLTkFQUy1vNkE5M1o3ekgya1B3RkhSa1hIdzF4YW9FaWtxSlZAjdWhhd2tvTVNHU1BBVldqMzUyTGlGWjNTdFVMbVRRZAGJ6VmhSdy1tTDZAhNlo3bGJqTHMZD"}`
        );
        setFeedsData(resp.data.data);
      } catch (err) {
        console.log("error", err);
      }
    };

    fetchInstagramPost();
  }, [props.limit]);

  return (
    <div className="p-5">
      <Slider {...settings}>
        {feeds.map((feed) => (
          <Feed key={feed.id} feed={feed} />
        ))}
      </Slider>
    </div>
  );
};

export default InstaFeed;
