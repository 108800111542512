import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Carousel } from 'react-bootstrap';

const SkeletonCardHomeBannerSlider = () => {
  return (
    <div className="contentContainer container">
      <div className="homeFirstContainerWithImg">
        <div className="container col-lg-12">
          <Carousel
            className="w-100 border-0"
            indicators={false}
            interval={null}
          >
            {Array.from({ length: 5 }).map((_, index) => (
              <Carousel.Item key={index}>
                <div className="carslider-wrapper">
                  <Skeleton height={500} />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>

      <div className="homeSubFirstContainer">
        <div className="container">
          <Skeleton width={250} height={40} />
        </div>
      </div>
    </div>
  );
};

export default SkeletonCardHomeBannerSlider;
