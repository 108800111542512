import React from 'react';
import { URLS } from '../util/constants';
import VendorService from '../services/VendorService';
import defaultLogo from '../assets/images/default.jpeg';
import deleteLogo from '../assets/images/deletelogo.svg';
import uploadLogo from '../assets/images/uploadlogo.svg';
import './signup.css';
import Image from './Rectangle 486.png';
import Divider from './Divider.png';
import { ToastContent } from '../components/Toast';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
class NewUserProfileImage extends React.Component {
  state = {
    logoUrl: null,
  };

  componentDidMount() {
    this.setState({ logoUrl: this.props.vendorProfile?.pic_path });
  }

  changeHandler = async (event) => {
    try {
      let data = await VendorService.uploadVendorProfileMedia(
        event.target.files[0]
      );
      var pic_path = data.imagePath;
      if (data?.imagePath) {
        var params = {
          pic_path,
        };
        VendorService.updateVendorInfo(params);
        this.setState({ logoUrl: data.imagePath });
        toast.success('Image uploaded successfully');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
    }
  };

  handleDeleteImage = () => {
    this.setState({ logoUrl: null });
    toast.success('Image deleted successfully');
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="text-container">
            <div className="text-side">
              <div className="customFormGroup">
                <div className="d-flex mx-auto mt-3">
                  <div className="container margin-top-50">
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          className="dashboard-list-box margin-top-0"
                          style={{
                            boxShadow: '0 0 3px 0 rgb(0 0 0 / 6%)',
                          }}
                        >
                          <h4 className="gray text-center">
                            My Vendor Profile Image
                          </h4>
                          <div className="dashboard-list-box-static bg-light-gray">
                            <div className="edit-profile-photo">
                              <div className="change-photo-btn">
                                <div className="photoUpload">
                                  <span>
                                    <img
                                      src={uploadLogo}
                                      alt="Upload"
                                      style={{
                                        width: '42px',
                                        height: '42px',
                                        backgroundColor: 'transparent',
                                      }}
                                    />
                                  </span>

                                  <input
                                    type="file"
                                    className="upload"
                                    onChange={(e) => this.changeHandler(e)}
                                  />
                                </div>
                              </div>
                            </div>
                            {!this.state.logoUrl ? (
                              <img
                                src={defaultLogo}
                                width={200}
                                alt="Profile Logo"
                                onError={defaultLogo}
                              />
                            ) : (
                              <>
                                <img
                                  width={200}
                                  src={URLS.S3BucketURL + this.state.logoUrl}
                                  alt="Profile Logo"
                                />
                                {this.state.logoUrl && (
                                  <button
                                    className="delete-image-button"
                                    onClick={this.handleDeleteImage}
                                    style={{
                                      backgroundColor: 'transparent',

                                      border: 'none',
                                      padding: '10px 20px',
                                      cursor: 'pointer',
                                      fontSize: '12px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    <img
                                      src={deleteLogo}
                                      alt="Delete"
                                      style={{
                                        width: '42px',
                                        height: '42px',
                                      }}
                                    />
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <Link to="/signup/new/finish"> */}
                      <div className="customFormGroup">
                        <div className="">
                          <button
                            type="button"
                            className="signUpBtn-signup-NewUser bg-light text-dark border border-2 d-flex mx-auto"
                            name="cancel"
                            value="cancel"
                            onClick={() => {
                              this.props.navigate('/signup/new/finish');
                              // window.location = '/dashboard';
                            }}
                          >
                            Skip for now
                          </button>
                        </div>
                        <div className=" ">
                          <button
                            type="submit"
                            className="signUpBtn-signup-NewUser d-flex mx-auto"
                            name="register"
                            value="Register"
                            onClick={() => {
                              this.props.navigate('/signup/new/finish');
                            }}
                          >
                            <span>Save</span>
                          </button>
                        </div>
                      </div>
                      {/* <button
                        type="submit"
                        className="signUpBtn-signup d-flex w-25 mx-auto"
                        name="register"
                        value="Register"
                        onClick={() => {
                          this.props.navigate('/signup/new/finish');
                        }}
                      >
                        <span>Save</span>
                      </button>
                      {/* </Link> */}
                    </div>
                    {/* <button
                      type="submit"
                      className="signUpBtn-signup d-flex w-25 mx-auto bg-light text-dark border border-2"
                      name="cancel"
                      value="cancel"
                      onClick={() => {
                        // this.props.navigate('/dashboard');
                        window.location = '/dashboard';
                      }}
                    >
                      <span>Cancel</span>
                    </button>{' '} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="box-container">
            <div className="centered-content">
              <img src={Image} alt="Image" className="box-image" />
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <img
            src={Divider}
            alt="Divider"
            style={{ width: '100%', position: 'absolute', bottom: -3 }}
          />
        </div>
      </>
    );
  }
}

// export default NewUserProfileImage;

function HeaderNewUserProfileImage(props) {
  let navigate = useNavigate();
  return <NewUserProfileImage {...props} navigate={navigate} />;
}
export default HeaderNewUserProfileImage;
