import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContent } from "../components/Toast";
import "./home.css";
import { ApiRoute } from "../util/constants";

const VendorMyInformation = (props) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [categoryKeywords, setCategoryKeywords] = useState([]);

  useEffect(() => {
    console.log("VENDOR DATA", vendorProfile);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();

    const firstName = e.target.elements.firstname.value;
    const lastName = e.target.elements.lastname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const business = e.target.elements.business.value;
    const profile_url = business
      .toLowerCase()
      .replace(/[^\w\s@]/gi, "")
      .replace(/\s+/g, "-")
      .replace(/-{2,}/g, "-");

    console.log("profile", profile_url);
    console.log("selectedCategories", selectedCategories);
    let categories = selectedCategories.map(({ value }) => value);

    let keywords = selectedKeywords.map(({ value }) => value);

    if (!categories.length) {
      toast(() => ToastContent("Category required."), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return false;
    }

    const address = e.target.elements.address.value;
    let states = selectedStates.map(({ value }) => value);

    if (states.length === 0 && vendorProfile.states.length > 0) {
      states = vendorProfile.states.map(({ id }) => id);
    }
    if (!states.length) {
      toast(() => ToastContent("Atleast one state required."), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return false;
    }

    const willingToTravel = e.target.elements.willingtotravel.checked;
    const params = {
      firstName,
      lastName,
      email,
      phone,
      business,
      categories,
      address,
      profile_url,
      keywords,
      states,
      willingToTravel,
    };
    try {
      await props.updateVendorInfo(params); // Update vendor info on the server

      // Add a delay of 1 second before reloading the page
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000); // 1000 milliseconds = 1 second
    } catch (error) {
      // Handle any errors that occurred during the update
      console.error("Error updating vendor info:", error);
    }
  };

  const handleCategoryChange = (handleCategoryChange) => {
    let categoryData = handleCategoryChange;

    setSelectedCategories(categoryData);
    if (categoryData?.length > 0) {
      let categoryIds = categoryData
        .map((category) => category.value)
        .join("&categoryId=");
      const getCategoryKeywordUrl = ApiRoute.getCategoryKeyword(categoryIds);
      fetch(getCategoryKeywordUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          setCategoryKeywords(result);
        });
    } else {
      setCategoryKeywords([]);
    }
  };

  const handleStateChange = (handleStateChange) => {
    setSelectedStates(handleStateChange);
  };

  const handleKeywordChange = (selectedOptions) => {
    if (vendorProfile.position !== 0 && selectedOptions.length > 5) {
      toast(
        () =>
          ToastContent(
            "You can only select up to 5 keywords.Additional Keywords will be discarded"
          ),
        {
          toastId: "errorToast",
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1500,
        }
      );
      return;
    } else {
      setSelectedKeywords(selectedOptions);
    }
  };

  const { vendorProfile, allCategories, allStates } = props;

  const categoriesOptions = allCategories?.map(({ id, title }) => ({
    value: id,
    label: title,
  }));
  const stateOptions = allStates?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const selectedCategoriesOpts = vendorProfile?.categories?.map(
    ({ id, title }) => ({ value: id, label: title })
  );

  const selectedStatesOpts = vendorProfile?.states?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const selectedKeywordsOpts = vendorProfile?.keywords?.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  useEffect(() => {
    if (selectedCategoriesOpts != undefined && selectedCategoriesOpts != null) {
      handleCategoryChange(selectedCategoriesOpts);
    }
  }, []);

  return (
    <div className="editProfileContentWrap  mt-5">
      <div className="container">
        <form
          onSubmit={handleSubmit}
          className="generalFormWrap myInformationForm"
        >
          <h3 className="editFormHeading">My Information</h3>
          <div className="customFormGrp">
            <label className="fw-bold">First Name</label>
            <div className="formControlWrap ">
              <input
                type="text"
                name=""
                className="customFormControl "
                id="firstname"
                defaultValue={vendorProfile?.firstName}
                autoCapitalize="on"
                autoComplete="false"
              />
            </div>
          </div>
          <div className="customFormGrp">
            <label className="fw-bold">Last Name</label>
            <div className="formControlWrap">
              <input
                type="text"
                name=""
                className="customFormControl"
                id="lastname"
                defaultValue={vendorProfile?.lastName}
              />
            </div>
          </div>
          <div className="customFormGrp ">
            <label className="fw-bold">
              Email Address<span className="redDot">*</span>
            </label>
            <div className="formControlWrap">
              <input
                type="email"
                name=""
                className="customFormControl"
                defaultValue={vendorProfile?.email}
                id="email"
                // disabled={true}
                required
              />
            </div>
          </div>
          <div className="customFormGrp">
            <label className="fw-bold">
              Phone Number<span className="redDot">*</span>
            </label>
            <div className="formControlWrap">
              <input
                name=""
                className="customFormControl"
                type="phone"
                id="phone"
                defaultValue={vendorProfile?.phone}
                required
              />
            </div>
          </div>
          <div className="customFormGrp">
            <label className="fw-bold">
              Vendor Name<span className="redDot">*</span>
            </label>
            <div className="formControlWrap">
              <input
                name=""
                className="customFormControl"
                type="text"
                defaultValue={vendorProfile?.business}
                id="business"
                required
              />
            </div>
          </div>
          <div className="customFormGrp">
            <label className="fw-bold">
              Vendor Category<span className="redDot">*</span>
            </label>

            <div className="formControlWrap fs-3">
              <Select
                id="categories"
                options={categoriesOptions}
                isMulti={true}
                name="select_category"
                onChange={handleCategoryChange}
                className="vendorprofile"
                required
                defaultValue={selectedCategoriesOpts}
              />
            </div>
          </div>
          {vendorProfile?.isPaid ? (
            <div className="customFormGrp fs-3">
              <label className="fw-bold">Keywords</label>

              {vendorProfile?.position === 0 ? (
                <div className="formControlWrap">
                  <Select
                    id="keywords"
                    options={categoryKeywords?.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }))}
                    isMulti={true}
                    name="select_keyword"
                    onChange={handleKeywordChange}
                    defaultValue={selectedKeywordsOpts}
                  />
                </div>
              ) : (
                <div className="formControlWrap">
                  <Select
                    id="keywords"
                    options={categoryKeywords?.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }))}
                    isMulti={true}
                    name="select_keyword"
                    onChange={handleKeywordChange}
                    defaultValue={selectedKeywordsOpts}
                  />
                </div>
              )}
            </div>
          ) : null}
          <div className="customFormGrp">
            <label className="fw-bold">Address &amp; City</label>
            <div className="formControlWrap">
              <input
                type="text"
                name=""
                className="customFormControl"
                defaultValue={vendorProfile?.address}
                id="address"
              />
            </div>
          </div>
          <div className="customFormGrp fs-3">
            <label className="fw-bold">
              State<span className="redDot">*</span>
            </label>
            <div className="formControlWrap">
              <Select
                id="states"
                placeholder="Please select state"
                options={stateOptions}
                isMulti={true}
                name="select_state"
                onChange={handleStateChange}
                required
                defaultValue={selectedStatesOpts}
                className="vendorprofile"
              />
            </div>
          </div>
          <div className="customFormGrp">
            <label className="fw-bold">Willing To Travel</label>
            <div className="formControlWrap">
              <label className="switch">
                <input
                  type="checkbox"
                  name="willingtotravel"
                  id="willingtotravel"
                  defaultChecked={vendorProfile?.willingToTravel === true}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="customFormGrp btnFormGrp">
            <button className="editProfileSubmitBtn col-lg-3">
              Update Info
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VendorMyInformation;
