import React, { useState } from 'react';
import axios from 'axios';
import { Modal } from 'reactstrap';
import './reset.css';
import { Local_routes } from '../util/constants';
import { ToastContainer, toast } from 'react-toastify';
import { ToastContent } from '../components/Toast';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiRoute } from '../util/constants';
function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetToken, setResetToken] = useState(token);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!newPassword) {
      toast(() => ToastContent('Please provide a new password'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast(() => ToastContent('Passwords do not match'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
      return;
    }
    setLoading(true); // start the loading state
    try {
      const response = await axios.post(`${ApiRoute.resetPassword}`, {
        newPassword,
        token: resetToken,
      });
      if (response.status === 200) {
        toast(() => ToastContent('Password Successfully Changed '), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        navigate(Local_routes.home); // navigate to the home route
      } else {
        toast(() => ToastContent('ERROR OCCURED PLEASE TRY AGAIN'), {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast(() => ToastContent('SERVER ERROR'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
    setLoading(false); // stop the loading state
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div class="modal-content">
          <div class="modalInsideBodyWrap">
            <div class="modal-header">
              <h5 class="modal-title" id="signUpModalLabel">
                Create a new password for your Event Massi Account
              </h5>
            </div>
            <div class="modal-body">
              <div class="signUpBoxWrap">
                <form onSubmit={handleSubmit} class="signUpForm">
                  <div class="customGroup">
                    <input
                      type="password"
                      name=""
                      placeholder="Create a new password"
                      class="form-control"
                      onChange={(event) => setNewPassword(event.target.value)}
                    />
                  </div>
                  <br></br>
                  <div class="customGroup">
                    <input
                      type="password"
                      name=""
                      placeholder="Confirm new password"
                      class="form-control"
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                    />
                  </div>
                  <div class="customFormGroup">
                    <button class="signUp" type="submit">
                      {loading ? (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: '5px' }}
                        />
                      ) : (
                        <span>Reset Password</span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="Toastify"></div>
        </div>
      </Modal>
    </div>
  );
}

export default ResetPassword;
