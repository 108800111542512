import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Local_routes } from '../util/constants';
import Header from '../pages/layouts/Header';
import Home from '../pages/home';
import BrowseVendors from '../pages/browsevendors';
import ExploreVendor from '../pages/explorevendor';
import VendorProfilePage from '../pages/vendorprofile';
import Dashboard from '../pages/dashboard';
import ContactUs from '../pages/contactus';
import Footer from '../pages/layouts/Footer';
import Blogs from '../pages/blogs/Blogs';
import Completion from '../pages/Completion/Completion';
import Massi from '../pages/Why Massi/Massi';
import About from '../pages/About us/About';
import Policy from '../pages/Privacy Policy/Policy';
import Cookies from '../pages/Cookies Policy/Cookies';
import TermsandServices from '../pages/Terms&Services';
import ResetPassword from '../components/ResetPassword';
import CookieConsent from 'react-cookie-consent';
import ErrorPage from '../error_page';
import SignUpPage from '../components/SignUpPage';
import NewUserDataPage from '../components/NewUserDataPage';
import NewUserProfileImage from '../components/NewUserProfiileImage';
import FinishProfile from '../components/FinishProfile';
import HeaderDocumentDetail from '../components/HeaderDocumentDetail';

class MainRoutes extends React.Component {
  state = { loginFormShow: false };
  loginFormShow = (value) => {
    this.setState({ loginFormShow: value });
  };

  render() {
    return (
      <>
        <HeaderDocumentDetail
          title="EventMassi |Dashboard"
          description="Welcome to the Event Massi Dashboard! This page serves as a central hub for vendors, providing a range of features and functionalities to enhance your experience on the platform.

Upon logging in, you will be presented with a user-friendly dashboard interface that allows you to access various sections and manage your vendor account effectively. The dashboard is designed to provide a seamless and efficient workflow, enabling you to navigate between different tabs and perform essential tasks."
        />
        <Header
          loginFormShow={this.state.loginFormShow}
          closeLoginModal={() => this.setState({ loginFormShow: false })}
        />

        <div className="top-bar-length">
          <Routes>
            <Route path={Local_routes.home} element={<Home />} />
            <Route path={Local_routes.vendors} element={<BrowseVendors />} />
            <Route
              path={Local_routes.vendor_detail + '/:vendorId'}
              element={
                <VendorProfilePage
                  loginFormShow={(value) => this.loginFormShow(value)}
                />
              }
            />
            <Route path={Local_routes.dashboard} element={<Dashboard />} />
            <Route path={Local_routes.contact_us} element={<ContactUs />} />
            <Route path={Local_routes.blogs} element={<Blogs />} />
            <Route path={Local_routes.signup} element={<SignUpPage />} />
            <Route
              path={Local_routes.newUserData}
              element={<NewUserDataPage />}
            />
            <Route
              path={Local_routes.newUserProfileImage}
              element={<NewUserProfileImage />}
            />
            <Route
              path={Local_routes.finishProfile}
              element={<FinishProfile />}
            />

            <Route path={Local_routes.massi} element={<Massi />} />
            <Route path={Local_routes.completion} element={<Completion />} />
            <Route
              path={Local_routes.reset_password}
              element={<ResetPassword />}
            />
            <Route path={Local_routes.about_us} element={<About />} />
            <Route path={Local_routes.explore} element={<ExploreVendor />} />
            <Route path={Local_routes.privacy} element={<Policy />} />
            <Route path={Local_routes.cookies} element={<Cookies />} />
            <Route path={Local_routes.terms} element={<TermsandServices />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
        <CookieConsent
          buttonText="I UNDERSTAND"
          cookieName="EVENT MASSI"
          style={{ background: '#2B373B', fontSize: '17px' }}
          declineButtonStyle={{
            color: '#fff',
            fontSize: '13px',
            fontWeight: '600',
            background: '#ff0000',
            borderRadius: '4px',
            padding: '8px 16px',
            textTransform: 'uppercase',
            marginLeft: '16px',
            minWidth: '13rem',
          }}
          buttonStyle={{
            color: '#4e503b',
            fontSize: '13px',
            borderRadius: '4px',
            padding: '8px 16px',
            textTransform: 'uppercase',
            fontWeight: '600',
            minWidth: '13rem',
          }}
          expires={150}
          enableDeclineButton
          onDecline={() => window.location.reload()}
        >
          This website uses cookies to enhance the user experience.See our{' '}
          <a href="/cookies">Cookies Policy</a>
        </CookieConsent>
        <Footer
          loginFormShow={this.state.loginFormShow}
          closeLoginModal={() => this.setState({ loginFormShow: false })}
        />
      </>
    );
  }
}

export default MainRoutes;
