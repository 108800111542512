import React from 'react';
import VendorImageAssetUploader from './VendorImageAssetUploader';
import VendorVideoAssetUploader from './VendorVideoAssetUploader';
import VendorUploadedAssets from './VendorUploadedAssets';
import VendorService from '../services/VendorService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContent } from '../components/Toast';
import Swal from 'sweetalert2';

class VendorDashboardGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: localStorage.getItem('userData')
        ? JSON.parse(atob(localStorage.getItem('userData')))
        : null,
      isPaid: localStorage.getItem('isPaid'),

      videoUploadInProgress: false,
      videoProgress: 0,
      imageProgress: 0,
      imageUploadInProgress: false,
    };
  }

  componentDidMount() {}

  uploadVendorAssetVideo = async (video) => {
    const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // 100 MB

    const file = video.target.files[0];

    if (file.size > MAX_VIDEO_SIZE) {
      Swal.fire({
        title: 'File Size Exceeded',
        text: 'The selected video exceeds the maximum allowed size of 100 MB.',
        icon: 'error',
      });
      return;
    }
    try {
      this.setState({ videoUploadInProgress: true, videoProgress: 0 });
      var uploadImage = await VendorService.uploadVendorVideoAssets(
        video.target.files[0],
        (videoProgress) => {
          console.log('Upload Progress:', videoProgress);
          this.setState({ videoProgress });
        }
      );

      if (uploadImage?.imagePath) {
        var newvideo = {
          url: uploadImage.imagePath,
          type: 'video',
          Videothumbnail: uploadImage.thumbnailResult,
        };
        var updateAssets = this.props.galleryData;

        updateAssets.push(newvideo);
        var params = { assets: updateAssets };
        var updateUserProfile = await VendorService.updateVendorInfo(params);
        if (updateUserProfile.success) {
          toast(() => ToastContent('Video uploaded successfully.'), {
            toastId: 'successToast',
            hideProgressBar: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
          });
          this.props.updateUserProfile(updateUserProfile.data);
        }
      }
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
    } finally {
      this.setState({ videoUploadInProgress: false });
    }
  };

  uploadVendorAssetImage = async (image) => {
    try {
      this.setState({ imageUploadInProgress: true, imageProgress: 0 });
      var uploadImage = await VendorService.uploadVendorAssets(
        image.target.files[0],
        (imageProgress) => {
          console.log('Upload Progress:', imageProgress);
          this.setState({ imageProgress });
        }
      );
      if (uploadImage?.imagePath) {
        var newimage = { url: uploadImage?.imagePath, type: 'image' };
        var updateAssets = this.props.galleryData;
        updateAssets.push(newimage);
        var params = { assets: updateAssets };
        var updateUserProfile = await VendorService.updateVendorInfo(params);
        if (updateUserProfile.success) {
          toast(() => ToastContent('Image uploaded successfully.'), {
            toastId: 'successToast',
            hideProgressBar: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
          });
          this.props.updateUserProfile(updateUserProfile.data);
        }
      }
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
    } finally {
      this.setState({ imageUploadInProgress: false });
    }
  };

  deleteVendorAsset = async (asset) => {
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this asset?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const newAssetsList = await this.props.galleryData.filter(
            (item) => item._id !== asset._id
          );
          var params = { assets: newAssetsList };
          var updateUserProfile = await VendorService.updateVendorInfo(params);
          if (updateUserProfile.success) {
            toast(() => ToastContent('Successfully Deleted.'), {
              toastId: 'successToast',
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1500,
            });
            this.props.updateUserProfile(updateUserProfile.data);
          }
        } catch (err) {
          Swal.fire('Error', err.message, 'error');
        }
      }
    });
  };

  render() {
    const {
      videoProgress,
      videoUploadInProgress,
      imageUploadInProgress,
      imageProgress,
    } = this.state;
    return (
      <>
        {/* {this.state.userData?.isPaid === false ? (
          <div className="tabTopBar ">
            <p>
              <strong>Subscribe!</strong> to preferred membership &amp; enchance
              your business. To subscribe <a href="#_">Click Here</a>
            </p>
          </div>
        ) : ( */}
        <div className="container margin-top-10 margin-bottom-50">
          <div className="row">
            <div className="col-lg-12">
              <div className="padding-top-10 padding-bottom-10">
                <div className="margin-bottom-15" style={{ fontSize: '20px' }}>
                  <strong>
                    Upload photos and videos to be viewed on your vendor profile
                  </strong>
                </div>
                <div style={{ fontSize: '21px' }}>
                  Introduce yourself to the community to show what makes you
                  unique!
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-6 col-sm-12">
                <VendorImageAssetUploader
                  uploadVendorAssetImage={(image) =>
                    this.uploadVendorAssetImage(image)
                  }
                  imageProgress={imageProgress}
                  imageUploadInProgress={imageUploadInProgress}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <VendorVideoAssetUploader
                  uploadVendorAssetVideo={(video) =>
                    this.uploadVendorAssetVideo(video)
                  }
                  videoProgress={videoProgress}
                  videoUploadInProgress={videoUploadInProgress}
                />
              </div>
            </div>
          </div>
          <div className="row margin-top-50">
            <div className="col-md-12">
              <VendorUploadedAssets
                assets={this.props.galleryData}
                deleteVendorAsset={(asset) => this.deleteVendorAsset(asset)}
              />
            </div>
          </div>
        </div>
        {/* )} */}
      </>
    );
  }
}

export default VendorDashboardGallery;
