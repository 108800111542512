import React from 'react';
import HomeBannerSlider from '../../components/HomeBannerSlider';
import HomeFollowUsSection from '../../components/HomeFollowUsSection';
import HomeVendorListSection from '../../components/HomeVendorListSection';
import VendorService from '../../services/VendorService';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';

class Home extends React.Component {
  state = {
    massiTopPickVendors: [],
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    let vendors = await VendorService.getMassiTopPickVendores();
    this.setState({ massiTopPickVendors: vendors });
  }

  render() {
    return (
      <>
        <HeaderDocumentDetail
          title="EventMassi | Your Event Community"
          description="Event Massi was established in the summer of 2020, when two childhood friends from Long Island, NY were determined to help bridge the gap in the community. As we attended multiple family and friends’ weddings over the years, we noticed the frustrations faced by those planning their events. As well as small businesses not getting exposure beyond social media. Seeing issues firsthand arising with regards to all events, were a little shocking - who knew how much went into planning events!"
        />
        <HomeBannerSlider
          loginFormShow={this.state.loginFormShow}
          closeLoginModal={() => this.setState({ loginFormShow: false })}
        />
        <HomeVendorListSection vendors={this.state.massiTopPickVendors} />
        <HomeFollowUsSection />
      </>
    );
  }
}

export default Home;
