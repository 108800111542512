import React from 'react';
import Moment from 'react-moment';
class UserMessageContent extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className="message-content">
        <div
          className="dashboard-list-box margin-top-0"
          style={{ boxShadow: 'none' }}
        >
          <ul>
            <li className="pending-booking">
              <div className="list-box-listing bookings">
                <div className="inner">
                  <h3>
                    Email:
                    {data.email !== '' || data.email !== null ? (
                      <span className="booking-status pending">
                        {data.email}
                      </span>
                    ) : null}
                  </h3>

                  <div className="inner-booking-list">
                    <h5>Message Date:</h5>{' '}
                    <ul className="booking-list">
                      <li className="highlighted">
                        <Moment format="DD MMMM YYYY, dddd">
                          {data.createdAt}
                        </Moment>
                      </li>
                    </ul>
                  </div>
                  <div className="inner-booking-list">
                    <h5>Event Date:</h5>{' '}
                    <ul className="booking-list">
                      <li className="highlighted">
                        <Moment format="DD MMMM YYYY, dddd">
                          {data.desiredDate}
                        </Moment>
                      </li>
                    </ul>
                  </div>

                  <div className="inner-booking-list">
                    <h5>Booking Details:</h5>{' '}
                    <ul className="booking-list">
                      <li className="highlighted">
                        {data.guestCount}{' '}
                        {data.guestCount > 1 ? 'guests' : 'guest'}
                      </li>
                    </ul>
                  </div>
                  {/* <div className="inner-booking-list">
                    <h5>Client:</h5>{' '}
                    <ul className="booking-list">
                      <li>{data.email}</li>
                      {data.preferredContact.indexOf('phone') === 1 ? (
                        <li>{data.phone}</li>
                      ) : null}
                    </ul>
                  </div> */}
                  <div className="inner-booking-list">
                    <h5>Preferred Contact Method:</h5>{' '}
                    <ul className="booking-list">
                      {data.preferredContact.map((preffered, check) => {
                        return (
                          <li
                            className="fs-3"
                            style={{ textTransform: 'capitalize' }}
                            key={check}
                          >
                            {preffered}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="inner-booking-list">
                    <h5>Phone:</h5>{' '}
                    <ul className="booking-list">
                      <p>{data.phone}</p>
                    </ul>
                  </div>
                  <div className="inner-booking-list">
                    <h5>Message:</h5>{' '}
                    <ul className="booking-list">
                      <p>{data.message}</p>
                    </ul>
                  </div>
                </div>
                <div className="list-box-listing-content">
                  {/* <div class="btn-group">
                    <button
                      type="button"
                      class="btn dropdown-toggle bg-light "
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-three-dots-vertical"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                      </svg>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          href="#!"
                          className="button gray reject "
                          onClick={() =>
                            this.props.confirmDeleteMessage(data.id)
                          }
                        >
                          <i className="sl sl-icon-trash"></i> Delete
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="inner">
                    <h3>
                      {data.name}{' '}
                      {data.email !== '' || data.email !== null ? (
                        <span className="booking-status pending">
                          {data.email}
                        </span>
                      ) : null}
                    </h3>

                    <div className="inner-booking-list">
                      <h5>Event Date:</h5>{' '}
                      <ul className="booking-list">
                        <li className="highlighted">
                          <Moment format="DD MMMM YYYY, dddd">
                            {data.desiredDate}
                          </Moment>
                        </li>
                      </ul>
                    </div>

                    <div className="inner-booking-list">
                      <h5>Booking Details:</h5>{' '}
                      <ul className="booking-list">
                        <li className="highlighted">
                          {data.guestCount}{' '}
                          {data.guestCount > 1 ? 'guests' : 'guest'}
                        </li>
                      </ul>
                    </div>
                    <div className="inner-booking-list">
                      <h5>Client:</h5>{' '}
                      <ul className="booking-list">
                        <li>{data.email}</li>
                        {data.preferredContact.indexOf('phone') === 1 ? (
                          <li>{data.phone}</li>
                        ) : null}
                      </ul>
                    </div>
                    <div className="inner-booking-list">
                      <h5>Contact preferred ways:</h5>{' '}
                      <ul className="booking-list">
                        {data.preferredContact.map((preffered) => {
                          return (
                            <li
                              style={{ textTransform: 'capitalize' }}
                              key={preffered}
                            >
                              {preffered}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="inner-booking-list">
                      <h5>Message:</h5>{' '}
                      <ul className="booking-list">
                        <li>{data.message}</li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default UserMessageContent;
