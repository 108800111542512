import React from 'react';
import 'react-slideshow-image/dist/styles.css';
import { URLS, Local_routes } from '../util/constants';
import './home.css';
import SocialShareVendor from './SocialShareVendor';
import ContactVendorFormModal from './ContactVendorFormModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class MassiSelectedUserList extends React.Component {
  state = {
    books: [],
    showShareModal: false,
    showContactForm: false,
    selectedVendor: null,
  };
  closeBtn = () => (
    <i
      className="fa fa-close cursor-pointer fs-2"
      size={15}
      onClick={() => this.setState({ showShareModal: false })}
    />
  );
  closeBtn2 = () => (
    <i
      className="fa fa-close cursor-pointer fs-2"
      size={25}
      onClick={() => this.setState({ showContactForm: false })}
    />
  );

  htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vendorProfile !== null) {
    }
  }

  render() {
    const { vendorList } = this.props;

    return (
      <>
        <div className="row">
          {vendorList.map((vendor, index) => {
            return (
              <div
                key={index}
                className="col-lg-6 col-sm-12 normal-vendor-list-item vendor-list-item-container"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={Local_routes.vendor_detail + '/' + vendor.profile_url}
                >
                  <div className="vendorImgGallery">
                    {vendor.pic_path.isArray ? (
                      <div className="insideVendorGalleryWrap">
                        <LazyLoadImage
                          src="https://massi-bucket.s3.amazonaws.com/undefined"
                          effect="blur"
                          alt="Undefined Logo"
                          className="event"
                        />
                      </div>
                    ) : (
                      <LazyLoadImage
                        src={`${URLS.S3BucketURL + vendor.pic_path}`}
                        effect="blur"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '../../images/noImage.svg';
                        }}
                        alt={vendor.business}
                        className="event"
                      />
                    )}
                  </div>
                </a>

                <div className="row col-lg-8">
                  {vendor.business ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        Local_routes.vendor_detail + '/' + vendor.profile_url
                      }
                    >
                      <span className="vendor-name ">
                        {vendor.business}
                        <LazyLoadImage
                          effect="blur" // Add an effect (e.g., blur) for better visual loading feedback
                          className="mx-3 mb-3"
                          src="../../images/gold-star.svg"
                          alt="Gold Star Logo"
                          style={{ width: '20px', marginTop: '5px' }}
                        />
                        {vendor.deals.length > 0 ? (
                          <LazyLoadImage
                            effect="blur"
                            src="../../images/dealmassi.png"
                            style={{ height: '21px', width: '71px' }}
                            alt="Deal Icon"
                          />
                        ) : null}
                      </span>
                    </a>
                  ) : (
                    <div style={{ height: '20px' }}></div>
                  )}

                  <div className="selectedStateName">
                    {vendor.categories.length > 0
                      ? vendor.categories.map((category, index) => {
                          return (
                            <span className="stateName" key={index}>
                              {category.title}
                            </span>
                          );
                        })
                      : null}
                    {vendor.states.length > 0
                      ? vendor.states.map((state, index) => {
                          return (
                            <span className="stateName" key={index}>
                              {state.name}
                            </span>
                          );
                        })
                      : null}
                    &nbsp;
                    <span className="star-rating1" data-rating="3.5">
                      <div
                        className="rating-counter1"
                        style={{
                          color: 'black',
                          fontSize: '15px',
                        }}
                      >
                        {vendor.costly === '1D'
                          ? '$'
                          : vendor.costly === '2D'
                          ? '$$'
                          : vendor.costly === '3D'
                          ? '$$$'
                          : null}
                      </div>
                    </span>
                  </div>
                  <a
                    target="_blank"
                    href={Local_routes.vendor_detail + '/' + vendor.profile_url}
                  >
                    {/* {vendor.description !== null &&
                    vendor.description !== '' ? (
                      <div className="insideVendorRightSideDetails">
                        <div className="vendorShortInfo">
                          <p>{this.htmlDecode(vendor.description)}</p>
                        </div>
                      </div>
                    ) : null} */}
                    {vendor?.description !== null && vendor?.description !== '' ? (
  <div className="insideVendorRightSideDetails">
    <div className="vendorShortInfo">
      <p dangerouslySetInnerHTML={{ __html: decodeURIComponent(vendor?.description) }} />
    </div>
  </div>
) : null}

                  </a>
                  <div className="vendorMetaWrap2 fs-3  ">
                    <a href={`tel:${vendor.phone}`} className="phone">
                      <img src="../../images/call.svg" alt="Call" />
                      {/* <span className=" mx-3  text-dark">Call Now</span> */}
                    </a>

                    <a
                      className="vendorMetaBoxWrap border border-0 bg-dark"
                      onClick={() =>
                        this.setState({
                          showContactForm: true,
                          selectedVendor: vendor,
                        })
                      }

                      // data-tip={vendor.email}
                      // href={`mailto:${vendor.email}?cc=support@eventmassi.com`}
                    >
                      <img src="../../images/sms.svg" alt="Mail" width={25} />
                    </a>
                    <a
                      href="#!"
                      onClick={() =>
                        this.setState({
                          showShareModal: true,
                          selectedVendor: vendor,
                        })
                      }
                      className="vendorMetaBoxWrap border border-1 bg-white"
                    >
                      <img
                        src="../../images/share.svg"
                        alt="Share"
                        width={25}
                      />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
          <SocialShareVendor
            showShareModal={this.state.showShareModal}
            closeBtn={this.closeBtn()}
            title={this.state.selectedVendor?.business}
            // url={window.location.href}
            // url={`${window.location.origin}${Local_routes.vendor_detail}/${btoa(
            //   this.state.preferredVendor?.id
            // )}`}
            url={
              'https://www.eventmassi.com' +
              Local_routes.vendor_detail +
              '/' +
              this.state.selectedVendor?.profile_url
              // btoa(this.state.selectedVendor?.id)
            }
            image={URLS.S3BucketURL + this.state.selectedVendor?.pic_path}
            description={this.state.selectedVendor?.description}
            keywords={this.state.selectedVendor?.keywords}
            email={this.state.selectedVendor?.email}
          />
          <ContactVendorFormModal
            showContactForm={this.state.showContactForm}
            closeBtn={this.closeBtn2()}
            vendorProfile={this.state.selectedVendor}
            closeModal={() => this.setState({ showContactForm: false })}
          />
        </div>
      </>
    );
  }
}

export default MassiSelectedUserList;
