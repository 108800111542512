import React from 'react';
import 'react-slideshow-image/dist/styles.css';
import { URLS, Local_routes } from '../util/constants';
import SocialShareVendor from './SocialShareVendor';
import ContactVendorFormModal from './ContactVendorFormModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class MassiPreferredUserList extends React.Component {
  state = {
    showShareModal: false,
    preferredVendor: null,
    showContactForm: false,
  };
  closeBtn = () => (
    <i
      className="fa fa-close cursor-pointer fs-2"
      size={15}
      onClick={() => this.setState({ showShareModal: false })}
    />
  );
  closeBtn2 = () => (
    <i
      className="fa fa-close cursor-pointer fs-2"
      size={25}
      onClick={() => this.setState({ showContactForm: false })}
    />
  );
  htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  render() {
    const { vendorList } = this.props;

    return (
      <>
        {vendorList.length > 0
          ? vendorList.map((vendor, preferred) => {
              return (
                <div className="col-lg-3 col-md-12 mt-5" key={preferred}>
                  <div className="vendor-info-container">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        Local_routes.vendor_detail + '/' + vendor.profile_url
                      }
                    >
                      <div className="vendor-image-container">
                        {vendor.pic_path === '' ||
                        vendor.pic_path === null ||
                        vendor.pic_path === 'undefined' ? (
                          <LazyLoadImage
                            effect="blur"
                            src="../../images/noImage.svg"
                            alt={vendor.business}
                            className="prefer"
                          />
                        ) : (
                          <LazyLoadImage
                            effect="blur"
                            src={`${URLS.S3BucketURL + vendor.pic_path}`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '../../images/noImage.svg';
                            }}
                            className="prefer"
                            alt={'User Profile pic'}
                          />
                        )}
                      </div>
                    </a>

                    <div className="vendor-text-container">
                      {vendor.business ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            Local_routes.vendor_detail +
                            '/' +
                            vendor.profile_url
                          }
                        >
                          <h2 className="selectedHeading2">
                            {vendor.business}{' '}
                            <LazyLoadImage
                              effect="blur"
                              src="../../images/silver-star.svg"
                              alt="Silver Star Logo"
                              style={{ width: '20px', marginTop: '-5px' }}
                            />
                          </h2>
                        </a>
                      ) : (
                        <h2 className="selectedHeading2 ">
                          Mimi's Mocha Treats
                          <LazyLoadImage
                            effect="blur"
                            src="../../images/silver-star.svg"
                            alt="Silver Star Logo"
                            style={{ width: '20px', marginTop: '-5px' }}
                          />
                        </h2>
                      )}
                      {vendor.categories.length > 0
                        ? vendor.categories.map((category, index) => {
                            return (
                              <span
                                className="selectCatNamePreferred"
                                key={index}
                              >
                                {category.title}
                              </span>
                            );
                          })
                        : null}
                      <span className="selectedStateName">
                        {vendor.states.length > 0
                          ? vendor.states.map((state, index) => {
                              return (
                                <span className="stateName" key={index}>
                                  {state.name}
                                </span>
                              );
                            })
                          : null}

                        <span className="star-rating1" data-rating="3.5">
                          <div
                            className="rating-counter1"
                            style={{
                              color: 'black',
                              fontSize: '16px',
                              // fontWeight: 700,
                            }}
                          >
                            {vendor.costly === '1D'
                              ? '$'
                              : vendor.costly === '2D'
                              ? '$$'
                              : vendor.costly === '3D'
                              ? '$$$'
                              : null}
                          </div>
                        </span>
                      </span>
                      <div>
                        {vendor.deals.length > 0 ? (
                          <span>
                            <LazyLoadImage
                              src="../../images/dealmassi.png"
                              style={{
                                height: '30px',
                                width: '90px',
                                marginLeft: '5px',
                              }}
                              alt="Deal Icon"
                            />
                          </span>
                        ) : (
                          <div style={{ marginTop: '30px' }}></div>
                        )}
                      </div>

                      <div className="vendorMetaWrap3 d-flex mt-3  ">
                        <a href={`tel:${vendor.phone}`} className="phone">
                          <LazyLoadImage
                            src="../../images/call.svg"
                            alt="Call"
                          />
                          {/* <span className=" mx-3  text-dark">Call Now</span> */}
                        </a>
                        <a
                          href="#!"
                          className="email border border-0 bg-dark"
                          onClick={() =>
                            this.setState({
                              showContactForm: true,
                              preferredVendor: vendor,
                            })
                          }
                          // data-tip={vendor.email}
                          // href={`mailto:${vendor.email}?cc=support@eventmassi.com`}
                        >
                          <LazyLoadImage
                            src="../../images/sms.svg"
                            alt="Mail"
                            width={20}
                          />
                        </a>
                        <a
                          href="#!"
                          onClick={() =>
                            this.setState({
                              showShareModal: true,
                              preferredVendor: vendor,
                            })
                          }
                          className="share border border-0 bg-white"
                        >
                          <LazyLoadImage
                            src="../../images/share.svg"
                            alt="Share"
                            width={20}
                          />
                        </a>
                        <SocialShareVendor
                          showShareModal={this.state.showShareModal}
                          closeBtn={this.closeBtn()}
                          title={this.state.preferredVendor?.business}
                          // url={window.location.href}
                          url={
                            'https://www.eventmassi.com' +
                            Local_routes.vendor_detail +
                            '/' +
                            this.state.preferredVendor?.profile_url
                            // btoa(this.state.preferredVendor?.id)
                          }
                          image={
                            URLS.S3BucketURL +
                            this.state.preferredVendor?.pic_path
                          }
                          description={this.state.preferredVendor?.description}
                          keywords={this.state.preferredVendor?.keywords}
                          email={this.state.preferredVendor?.email}
                        />
                        <ContactVendorFormModal
                          showContactForm={this.state.showContactForm}
                          closeBtn={this.closeBtn2()}
                          vendorProfile={this.state.preferredVendor}
                          closeModal={() =>
                            this.setState({ showContactForm: false })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </>
    );
  }
}

export default MassiPreferredUserList;
