import React from 'react';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';
import { useEffect } from 'react';

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderDocumentDetail
        title="EventMassi | Privacy Policy"
        description="At EventMassi, we take your privacy seriously. Our Privacy Policy outlines how we collect, use, and protect your personal information when you use our event management platform. We are committed to maintaining the confidentiality and security of your data. Please review our Privacy Policy to understand your rights and how we handle your information."
      />

      <div className="contentContainer">
        <div className="privacyTopBoxWrap">
          <h2 className="privacyTopBoxHeading">www.eventmassi.com</h2>
          <p className="privacyTaglineText">Privacy Policy</p>
        </div>
        <div className="privacyContentWrap">
          <div className="insideGridContainer">
            <p>
              Eventmassi.com is a Website providing a vendor membership business
              directory. This Privacy Policy discloses our privacy practices and
              the possible uses of the information we gather (hereinafter the
              "Site and Services"). By using the Site and Services you consent
              to the terms of this Privacy Policy. If you do not agree to the
              terms and conditions of this Privacy Policy, including having your
              personally identifiable information (hereinafter "Personal
              Information") used in any of the ways described you may be denied
              use of certain features of our Site and Services, and in some
              instances, your membership may be revoked. In our sole discretion,
              Event Massi LLC may update this Privacy Policy from time-to-time.
              It is your responsibility to review the Privacy Policy, from time
              to time, for any changes or modifications. Your use of the Site
              and Services following any changes or modifications to the Privacy
              Policy signifies your agreement to and acceptance of the Privacy
              Policy as changed or modified.
            </p>
            <h3>Definitions</h3>
            <p>
              “Account” – Website Vendor profiles
              <br /> “Data” – any information stored on the site.
              <br /> “User Generated Content” – messages posts, comments,
              responses, reviews, and ratings posted on the site.
              <br /> “Vendors” – local entrepreneurs and businesses who are
              listed in the Directory on the site.
              <br /> “Visitors” – users of the site.
              <br /> “Site” – website: www.eventmassi.com
            </p>
            <p>
              Event Massi LLC collects information on our vendors in various
              ways, to include (i) your voluntary submissions, (ii) your
              participation in services provided via the Site and Services,
              (iii) from third parties with your consent, and (iv) through
              cookie and other tracking technology for use in your Account as
              follows:
            </p>
            <h3>1.Personal Information</h3>
            <p>
              {' '}
              We collect information in which to identify you such as, your
              name, phone number, email address, postal address, category,
              social media links, and possibly information relating to certain
              support or customer service issues.
            </p>
            <h1>2.Submission of Reviews, Ratings, and Comments</h1>
            <p>
              {' '}
              We collect User Generated Content you voluntarily provide as part
              of our Site and Services submitted through the Site. We may, in
              our sole discretion, share your User Generated Content, such as
              your business reviews, with other members or potential members. If
              you do not want certain information shared with others, do not
              submit User Generated Content containing information you do not
              want shared in this manner. Once you have submitted User Generated
              Content, we reserve the right to publish it in any medium for
              consumption by others.
            </p>
            <h3>3.Aggregate Information </h3>
            <p>
              {' '}
              We may collect general, non-personal, statistical information
              about the use of the Site and Services, such as how many visitors
              visit a specific page on the Site, how long they stay on that
              page, and which hyperlinks, if any, they click on. This
              information represents a generic overview of our visitors,
              including their collective viewing habits, and allows us to modify
              information, promotions, products and services based on user
              traffic. We collect this information through the use of
              technologies such as 'cookies' in order to help enhance the Site
              and Services to make for a better visitor experience.
            </p>
            <h1>4.Cookies</h1>

            <p>
              Event Massi LLC may automatically collect non-personally
              identifiable information and data through the use of cookies.
              Cookies are small text files a website uses to recognize repeat
              users, facilitate the user's ongoing access to the website and
              facilitate the use of the website. Cookies also allow a website to
              track usage behavior and compile site usage information allowing
              for the improvement of content and target advertising. Most
              browsers allow you to control cookies, including whether or not to
              accept them and how to remove them. You may set your browsers to
              notify you if you receive a cookie, or you may choose to block
              cookies with your browser. Please be aware some features of our
              Site and Services may not function or may be slower if you refuse
              cookies.
            </p>
            <p>
              Once you have submitted User Generated Content, we reserve the
              right to publish it in any medium for consumption by others.
            </p>
            <h3>Use of Information</h3>
            <p>
              The information Event Massi LLC collects is used in a variety of
              ways, including but not limited to: (i) for internal review, (ii)
              to improve the Site and Services, (iii) to verify the legitimacy
              of reviews and ratings (iv) to notify you about updates to the
              Site and Services, and (v) for marketing purposes and the
              marketing of discounts offered through our Site and Services by
              Vendors.
            </p>
            <p>
              Disclosures of InformationWe do not disclose Personal Information
              to third parties, except under one or more of the following
              conditions:
              <ul>
                <li>We have your permission to make the disclosure, or</li>
                <li>
                  The disclosure is necessary for the purpose for which the
                  personal information was obtained, or
                </li>
                <li>
                  The disclosure is to the service provider from whom you
                  purchased services, or
                </li>
                <li>
                  The disclosure is to financial service providers in order to
                  fulfill and carry out the purchase and provision of goods and
                  services requested by you, or
                </li>
                <li>The disclosure is permitted by relevant law, or</li>
                <li>
                  The information to be disclosed is otherwise publicly
                  available in accordance with the applicable law, or
                </li>
                <li>
                  The disclosure is, in our sole discretion, necessary for the
                  establishment or maintenance of legal claims or legal
                  compliance, to satisfy any law, regulation, subpoena or
                  government request, or in connection with litigation, or
                </li>

                <li>
                  The disclosure is, in our sole discretion, about users who we
                  believe are engaged in illegal activities or are otherwise in
                  violation of our Terms & Conditions Agreement, even without a
                  subpoena, warrant or court order.
                </li>
              </ul>
            </p>
            <h3>Data Security, Integrity, and Access</h3>
            <p>
              The Personal Information you provide, and we collect, is stored
              within databases we control directly or through our Administrative
              Service Providers. As we deem appropriate and as required by the
              applicable law, we use security measures appropriate for the
              sensitivity of the Personal Information consistent with industry
              standards, including physical, organizational and technological
              protections, such as firewalls, to protect your Personal
              Information. We also use industry standard encryption technology
              to protect credit card and password information. However, since no
              security system is impenetrable, we cannot guarantee the security
              of our databases, nor can we guarantee information you supply will
              not be intercepted while being transmitted to us over the
              Internet.
            </p>
            <p>
              Personal Information may only be accessed by persons within our
              organization, our third party service providers who require such
              access to provide you with the services indicated above, or any
              other party that you have provided us with authorization to share
              such information.
            </p>
            <p>
              We retain Personal Information collected only as long as
              reasonably necessary to fulfill the purposes for which it was
              collected or to otherwise meet any legal requirements.
            </p>
            <h3>Credit Card Information</h3>
            <p>
              We may share credit card information with trusted third parties in
              order to bill you for our service. We use third parties for secure
              credit card transaction processing, and we send billing
              information to those third parties to process your orders and
              payments or to validate your credit card information on file.
              Credit card information is encrypted during transmission. In
              addition to utilizing encryption that meets the US Government’s
              FIPS 140-2 Certification standard, we take care to store only the
              amount of information required to bill you for services.
            </p>
            <h3>Chlidren's Privacy</h3>
            <p>
              Event Massi LLC does not intend the Site and Services to be used
              by individuals under the age of majority within the jurisdiction
              within which they reside without the supervision of a parent or
              guardian of majority age. Event Massi LLC relies upon such parents
              or guardians to determine if the Site and Services is appropriate
              for the viewing, access, or participation by such individuals
              under the age of majority. If an individual under the age of
              majority uses the Site and Services, such individual may only use
              the Site and Services under the supervision of a parent or
              guardian of majority age.
            </p>
            <p>
              We do not seek or knowingly collect any Personal Information about
              children under fifteen (15) years of age. If we become aware we
              have unknowingly collected Personal Information from a child under
              the age of fifteen (15), we will make commercially reasonable
              efforts to delete such information from our database.
            </p>
            <h3>Governing Law</h3>
            <p>
              This Privacy Policy and the privacy practices of Event Massi LLC
              shall be construed and governed under the laws of the state of New
              York.
            </p>
          </div>
        </div>
        <div className="container mb-5 mt-5 feedback p-5">
          <h1 className="display-4 ">Give Us Your Feedback</h1>
          <h3 className="text-center mt-3 opacity-50">
            Do you have any questions or concerns?
          </h3>

          <a href="/contact_us" className="contact ">
            Contact Us
          </a>
        </div>
      </div>
    </>
  );
};

export default Policy;
