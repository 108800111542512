import React from 'react';
import { Link } from 'react-router-dom';
import { Local_routes } from './util/constants';

const ErrorPage = () => {
  const errorStyle = {
    color: '#000',
    background: '#fff',
    height: '80vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const imageStyle = {
    maxWidth: '300px',
  };

  return (
    <div style={errorStyle}>
      <img src="../images/error.avif" alt="Error" style={imageStyle} />
      <h2>404 | This page could not be found.</h2>

      <h4 className="mt-2">
        <Link to={Local_routes.home}>Go to Home Page</Link>
      </h4>
    </div>
  );
};

export default ErrorPage;
