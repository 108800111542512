import { ApiRoute , CONSTANTS } from "../util/constants";
export default {
  getAllKeywords,
};

async function getAllKeywords() {
  let response = await fetch(`${ApiRoute.getAllKeywords}`, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load keywords: ${response}`);
}

