import React from 'react';
import ReactTooltip from 'react-tooltip';
import SocialShareVendor from './SocialShareVendor';
import ContactVendorFormModal from './ContactVendorFormModal';
import './explore.css';
import { URLS, Local_routes } from '../util/constants';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MassiDeal from '../assets/images/MassiDeal.svg';
import ExploreImage from '../assets/images/Explore.svg';
import { ApiRoute } from '../util/constants';
import SkeletonExplore from './Skeltons/SkeltonCardExplore';
import defaultLogo from '../assets/images/default.jpeg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageGallery from 'react-image-gallery';
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class ExploreVendorDetail extends React.Component {
  // state = {
  //   showContactForm: false,
  //   showShareModal: false,
  //   data: null,
  //   currentIndex: 0,
  //   images: [],
  //   selectedVendor: null,
  //   assets: [],

  // };
  constructor(props) {
    super(props);
    this.playRef = React.createRef();
    this.state = {
      isPlaying: false,
      showContactForm: false,
      showShareModal: false,
      data: null,
      currentIndex: 0,
      images: [],
      selectedVendor: null,
      assets: [],
    };
  }

  async componentDidMount() {
    await fetch(`${ApiRoute.explore}`)
      .then((res) => res.json())
      .then((json) => {
        // console.log('====================================');
        // console.log('SELECTED AND PREFERRED VENDORS', json.data);
        // console.log('====================================');
        // json.data.splice(3);
        let imagesArray = [];
        console.log('====================================');
        console.log('FIRST ASSET', json.data[0].assets[0]);
        console.log('====================================');
        let url = '';
        const firstAsset = json.data[0].assets[0];
        if (firstAsset && firstAsset.type === 'image') {
          if (firstAsset.path) {
            url = URLS.S3BucketURL + firstAsset.path;
          } else if (firstAsset.url) {
            url = URLS.S3BucketURL + firstAsset.url;
          }
          if (url !== '') imagesArray.push({ original: url, thumbnail: url });
        } else if (firstAsset && firstAsset.type === 'video') {
          for (let i = 1; i < json.data[0].assets.length; i++) {
            const asset = json.data[0].assets[i];
            if (asset.type === 'image') {
              if (asset.path) {
                url = URLS.S3BucketURL + asset.path;
              } else if (asset.url) {
                url = URLS.S3BucketURL + asset.url;
              }
              if (url !== '') {
                imagesArray.push({ original: url, thumbnail: url });
                break;
              }
            }
          }
        }

        this.setState({
          data: json.data,
          selectedVendor: json.data[0],
          images: imagesArray,
        });
        // this.timer = setInterval(this.nextArrow, 6000);
      });

    await fetch(`${ApiRoute.exploreAssets}`)
      .then((response) => response.json())
      .then((data) => {
        const images = data.data.assets.filter(
          (asset) => asset.type === 'image'
        );
        this.setState({ assets: images });
      })
      .catch((error) => console.error(error));
    this.playOrPause();
  }

  playOrPause = () => {
    this.setState((prevState) => {
      if (this.playRef.current) {
        this.playRef.current[prevState.isPlaying ? 'pause' : 'play']();
      }
      return { isPlaying: !prevState.isPlaying };
    });
  };
  combinedFunction = () => {
    this.setState({ showContactForm: true });
    this.playOrPause();
  };

  combinedFunction2 = () => {
    this.setState({ showShareModal: true });
    this.playOrPause();
  };
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  nextArrow = (index) => {
    let imagesArray = [];
    const selectedVendor = this.state.data[index];

    const firstAsset = selectedVendor.assets.find(
      (asset) => asset.type === 'image'
    );

    if (firstAsset) {
      let url = '';
      if (firstAsset.path) {
        url = URLS.S3BucketURL + firstAsset.path;
      } else if (firstAsset.url) {
        url = URLS.S3BucketURL + firstAsset.url;
      }
      if (url !== '') imagesArray.push({ original: url, thumbnail: url });
    }

    this.setState({
      currentIndex: index,
      selectedVendor,
      images: imagesArray,
    });
  };

  async componentDidUpdate() {}
  previousArrow = () => {
    const { data, currentIndex } = this.state;
    const lastIndex = data.length - 1;
    let index = currentIndex - 1;
    if (index < 0) {
      index = lastIndex;
    } else if (index === lastIndex) {
      index = 0;
    } else {
      index = Math.floor(Math.random() * data.length);
    }
    const selectedVendor = data[index];

    const imagesArray = selectedVendor.assets
      .filter((asset) => asset.path && asset.type === 'image')
      .map((asset) => ({
        // URLS.S3BucketURL +

        original: URLS.S3BucketURL + asset.path,
        thumbnail: URLS.S3BucketURL + asset.path,
      }));
    this.setState({
      currentIndex: index,
      selectedVendor,
      images: imagesArray,
    });
  };

  closeBtn = () => (
    <i
      className="fa fa-close cursor-pointer fs-1"
      size={25}
      onClick={() => {
        this.setState({ showContactForm: false, showShareModal: false });
        this.playOrPause();
      }}
    />
  );

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 1,
      centerPadding: '0',
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    };
    const imgStyle = {
      height: '200px',
      width: '300px',
      objectFit: 'cover',
      padding: '10px',
    };

    const { images } = this.state;
    console.log('SELECTED VENDOR ', this.state.selectedVendor);

    const { data } = this.state;
    console.log('VENDORS DATA COMING ', data);

    if (!data)
      return (
        // <div style={{ display: 'flex', justifyContent: 'center' }}>
        //   {' '}
        //   <Rings
        //     height="200"
        //     width="200"
        //     radius="9"
        //     color="#edb300"
        //     ariaLabel="three-dots-loading"
        //   />
        // </div>
        <>
          <SkeletonExplore />
        </>
      );

    return (
      <div className="contentContainer exploreBgWrap">
        <LazyLoadImage
          className=" d-flex mx-auto mt-4"
          src={ExploreImage}
          alt="Massi Explore Logo"
        />
        <p className=" text-center">
          Welcome to Massi Explore, where we highlight some of our top vendors,
          showcase some of their best work and share deals and promotions for
          your next event
        </p>

        <div className=" mfp-gallery-container ">
          <ImageGallery
            items={data.map((item, index) => (
              <h2 key={index}>{item.id}</h2>
            ))}
            ref={this.playRef}
            autoPlay={true}
            showNav={false}
            showThumbnails={false}
            showBullets={true}
            slideDuration={3000}
            lazyLoad={true}
            showPlayButton={false}
            showFullscreenButton={false}
            onSlide={(currentIndex) => {
              console.log(`Current index: ${currentIndex}`);
              this.nextArrow(currentIndex);
            }}
          />
        </div>

        <div className="exploreTopImgGallery mt-5">
          <div className="container">
            <div className="exploreGalleryWrap ">
              {' '}
              <div className="col-md-12">
                {this.state.selectedVendor.assets.length > 0 ? (
                  <div className="mfp-gallery-container">
                    <ImageGallery
                      items={images}
                      autoPlay={false}
                      showNav={false}
                      showThumbnails={false}
                      showBullets={false}
                      slideDuration={3200}
                      lazyLoad={true}
                      showPlayButton={false}
                    />
                  </div>
                ) : (
                  <div
                    className="default-logo"
                    style={{ backgroundColor: '#383838', padding: '10px' }}
                  >
                    {/* Render your default logo here */}
                    <LazyLoadImage
                      className="mx-auto d-flex justify-content-center"
                      src={defaultLogo}
                      width={500} // Set the width you desire
                      alt="Default Logo"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="exploreContentWrap">
          <div className="container">
            <div className="row">
              <div className="col-md-8 exploreLeftHeading">
                <div className="row explorePosition">
                  {this.state.selectedVendor.position === 0 ? (
                    <div className="col-lg-12">
                      <h2 className="selectedHeadingYellowExplore ">
                        Massi Select
                      </h2>
                    </div>
                  ) : this.state.selectedVendor.position === 1 ? (
                    <div className="col-lg-12">
                      <h2 className="selectedHeadingSilverExplore">
                        Massi Preferred
                      </h2>
                    </div>
                  ) : null}
                  <span>
                    {this.state.selectedVendor.deals.length > 0 ? (
                      <img
                        className="dealImage"
                        src="../../images/deal-icon.svg"
                        alt="Deal Icon"
                      />
                    ) : null}
                  </span>
                </div>

                <h2> {this.state.selectedVendor.business}</h2>
                {/* <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.selectedVendor.description,
                  }}
                ></p> */}
                <p
  dangerouslySetInnerHTML={{
    __html: decodeURIComponent(this.state.selectedVendor.description || ''),
  }}
></p>


                {this.state.selectedVendor.states.length > 0 ? (
                  <>
                    <section className="margin-bottom-20 ">
                      <h3 className="listing-desc-headline margin-bottom-10">
                        <strong className="state ">State</strong>
                      </h3>

                      {this.state.selectedVendor.states.map((state, check) => {
                        return (
                          <div
                            key={check}
                            className="listing-address-explore text-dark "
                          >
                            {state.name}
                          </div>
                        );
                      })}
                    </section>
                  </>
                ) : null}
                <h3 className="description">Price</h3>
                <div
                  className="listing-price text-dark "
                  style={{
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.selectedVendor.costly === '1D'
                    ? '$'
                    : this.state.selectedVendor.costly === '2D'
                    ? '$$'
                    : this.state.selectedVendor.costly === '3D'
                    ? '$$$'
                    : null}
                </div>
                {this.state.selectedVendor.categories.length > 0 ? (
                  <>
                    <div className="vendorCat">
                      <div className="selectedCat">
                        <span className="MainCatLabel">Category</span>
                        <div className="selectCatName">
                          <div className="categoryList">
                            {this.state.selectedVendor.categories.map(
                              (category, index) => (
                                <span key={index} className="categoryItem">
                                  {category.title}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="col-md-4 exploreRightTopWrap">
                <div className="rightSideDetailsWrap">
                  <div className="col-md-12  inquireVendorExplore text-center">
                    <div className="row explorePosition">
                      <h3 style={{ fontSize: '18px', fontWeight: '600' }}>
                        Interested In{' '}
                        {` ${this.state.selectedVendor.business}?`}
                      </h3>
                    </div>

                    <div>
                      <ul className="share-buttons  margin-top-10 margin-bottom-20">
                        <div className="vendorMetaWrapInquireExplore fs-3 border border-0 ">
                          <a
                            href={`tel:${this.state.selectedVendor.phone}`}
                            data-tip={this.state.selectedVendor.phone}
                            className="phone"
                          >
                            <img src="../../images/call.svg" alt="Call" />
                          </a>
                          {/* <a
                            className="vendorMetaBoxWrap"
                            data-tip={this.state.selectedVendor.email}
                            href={`mailto:${this.state.selectedVendor.email}?cc=support@eventmassi.com`}
                          >
                            <img
                              src="../../images/mailer.jpg"
                              alt="Mail"
                              width={25}
                            />
                          </a> */}
                          <button
                            className="vendorMetaBoxWrap border border-0 bg-dark "
                            style={{ fontSize: '14px', fontWeight: '400' }}
                            onClick={this.combinedFunction}
                          >
                            {this.state.isPlaying ? (
                              <img
                                src="../../images/sms.svg"
                                alt="Mail"
                                width={25}
                              />
                            ) : (
                              <img
                                src="../../images/sms.svg"
                                alt="Mail"
                                width={25}
                              />
                            )}
                          </button>

                          <a
                            href="#!"
                            // onClick={() =>
                            //   this.setState({
                            //     showShareModal: true,
                            //   })
                            // }
                            onClick={this.combinedFunction2}
                            className="vendorMetaBoxWrap border border-1 bg-white"
                          >
                            <img
                              src="../../images/share.svg"
                              alt="Share"
                              width={25}
                            />
                          </a>
                        </div>
                        <ReactTooltip place={'left'} />
                      </ul>
                      <div className="clearfix"></div>
                    </div>
                    {/* <button
                      className="book-vendor2 w-75"
                      style={{ fontSize: '14px', fontWeight: '400' }}
                      onClick={this.combinedFunction}
                    >
                      {this.state.isPlaying ? 'INQUIRE' : 'INQUIRE'}
                    </button> */}
                  </div>
                </div>
              </div>

              <ContactVendorFormModal
                showContactForm={this.state.showContactForm}
                closeBtn={this.closeBtn()}
                vendorProfile={this.state.selectedVendor}
                closeModal={() => this.setState({ showContactForm: false })}
              />

              <SocialShareVendor
                showShareModal={this.state.showShareModal}
                closeBtn={this.closeBtn()}
                title={this.state.selectedVendor.business}
                url={
                  'https://www.eventmassi.com' +
                  Local_routes.vendor_detail +
                  '/' +
                  this.state.selectedVendor?.profile_url
                  // btoa(this.state.selectedVendor?.id)
                }
                image={URLS.S3BucketURL + this.state.selectedVendor.pic_path}
                description={this.state.selectedVendor.description}
                keywords={this.state.selectedVendor.keywords}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="exploreContentWrap">
            <div className="container">
              <div className="exploreDealWrap">
                <div className="container">
                  <div className="vendorDeals">
                    {this.state.selectedVendor.deals.length > 0 ? (
                      <div
                        id="listing-deals"
                        className="listing-section margin-bottom-30 margin-top-20 "
                      >
                        <ul style={{ listStyle: 'none' }}>
                          {this.state.selectedVendor.deals.map(
                            (deal, deals) => {
                              return (
                                <li key={deals}>
                                  <img
                                    className="border-bottom   border-3 border-warning"
                                    src="../../images/MassiDeal.svg"
                                    alt="Massi Deals Logo"
                                  />
                                  <div id="deal" className="vendorDeals">
                                    {deal.assets.map((asset, key) => {
                                      return (
                                        <div className="dealBoxWrap" key={key}>
                                          <a href="#!" key={asset.id}>
                                            <img
                                              height={'auto'}
                                              width="10%"
                                              src={URLS.S3BucketURL + asset.url}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                  './images/default.jpeg';
                                              }}
                                              alt={deal.name}
                                            />
                                          </a>
                                          <span>
                                            <h4
                                              dangerouslySetInnerHTML={{
                                                __html: deal.name,
                                              }}
                                            ></h4>

                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: deal.description,
                                              }}
                                            ></span>
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <div className="exploreContentWrapGallery">
            <div className="container">
              <div className="row">
                <div className="col-md-4 exploreLeftHeading">
                  <h2> Gallery</h2>
                </div>
              </div>

              <Slider {...settings}>
                {this.state.assets.map((asset, index) => (
                  <div key={index}>
                    {asset.url ? (
                      <img
                        src={`https://massi-bucket.s3.amazonaws.com/${asset.url}`}
                        alt={asset.title}
                        style={imgStyle}
                      />
                    ) : (
                      <img
                        src={`https://massi-bucket.s3.amazonaws.com/${asset.path}`}
                        alt={asset.title}
                        style={imgStyle}
                      />
                    )}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </>
        <div className="exploreCantFindWrap">
          <div className="container">
            <p>Cant find what you're looking for?</p>
            <a href="/browse-vendors">
              <button className="cantFindBtn">Browse other vendors</button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default ExploreVendorDetail;
