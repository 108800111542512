import React from 'react';
import Select from 'react-select';
import './home.css';
import { ApiRoute } from '../util/constants';

import BrowseLiveSearch from '../pages/layouts/BrowseLiveSearch';

class VendorSearchForm extends React.Component {
  defaultSearchCategory = [];
  defaultSearchKeyword = [];

  state = {
    selectedCategory: '',
    selectedState: '',
    selectedCostly: '',
    selectedKeyword: '',
    searchText: '',
    categoryKeywords: '',
  };

  handleCategoryChange = (handleCategoryChange) => {
    let categoryData = handleCategoryChange;
    console.log('categoryData', categoryData);
    this.setState({ selectedCategory: categoryData });
    const getCategoryKeywordUrl = ApiRoute.getCategoryKeywordByValue(
      categoryData.value
    );

    fetch(getCategoryKeywordUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        const keywordsOptions = result.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        this.setState({ categoryKeywords: keywordsOptions });
      });
  };

  componentDidMount() {}

  searchSubmit = () => {
    const obj = {
      category: this.state.selectedCategory?.value
        ? this.state.selectedCategory.value
        : '',
      keyword: this.state.selectedKeyword?.value
        ? this.state.selectedKeyword.value
        : '',
      state: this.state.selectedState?.value
        ? this.state.selectedState?.value
        : '',
      costly: this.state.selectedCostly?.value
        ? this.state.selectedCostly?.value
        : '',
      query: this.state.searchText,
    };
    const result = '&' + new URLSearchParams(obj).toString();
    this.props.getVendorList(result);
  };

  resetSearch = () => {
    this.setState({
      selectedCategory: '',
      selectedState: '',
      selectedCostly: '',
      selectedKeyword: '',
      searchText: '',
      categoryKeywords: '',
    });
    this.props.getVendorList(null, 1);
  };

  render() {
    const searchParams = new URLSearchParams(document.location.search);
    const { categoriesList, statesList, keywordsList } = this.props;
    const categoriesOptions = [];
    const stateOptions = [];
    this.defaultSearchCategory = [];
    this.defaultSearchKeyword = [];

    const keywordsOptions = [];
    if (categoriesList?.success && categoriesList?.data.length > 0) {
      categoriesList.data.map((category) => {
        return categoriesOptions.push({
          value: category.id,
          label: category.title,
        });
      });
    }

    categoriesOptions.map((category) => {
      if (category.value === searchParams.get('category'))
        return this.defaultSearchCategory.push(category);
    });

    // console.log('default', this.props.selectedCategory);

    if (keywordsList?.success && keywordsList?.data.length > 0) {
      keywordsList.data.map((keyword) => {
        return keywordsOptions.push({
          value: keyword.id,
          label: keyword.name,
        });
      });
    }
    keywordsOptions.map((keyword) => {
      if (keyword.value === searchParams.get('keyword'))
        return this.defaultSearchKeyword.push(keyword);
    });
    // console.log('default', this.props.selectedKeyword);

    if (statesList?.success && statesList?.data.length > 0) {
      statesList.data.map((state) => {
        return stateOptions.push({ value: state.id, label: state.name });
      });
    }

    const costlyOptions = [
      { value: '1D', label: '$' },
      { value: '2D', label: '$$' },
      { value: '3D', label: '$$$' },
    ];
    return (
      <div className="vendorForm searchContainer  searchbar p-4  ">
        {/* <h4>{searchParams.get('category')}</h4> */}

        {/* <img
          src="../../images/searching.svg"
          className="fa fa-search"
          style={{
            position: 'absolute',
            top: '31px',
            left: '40px',
          }}
        ></img> */}

        <div className="main-search-input  justify-content-center   margin-top-0 ">
          {/* <input
            type="text"
            className="vendorSearch"
            placeholder="Search "
            onChange={(e) => this.setState({ searchText: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // prevent default behavior
                this.searchSubmit();
              }
            }}
            value={this.state.searchText}
          /> */}

          <BrowseLiveSearch />

          {/* <span className="vertical-line"></span> */}

          <Select
            className="vendorSelect1 col-md-6"
            placeholder="Category"
            options={categoriesOptions}
            isMulti={false}
            name="select_category"
            onChange={this.handleCategoryChange}
            value={this.state.selectedCategory}
            // defaultInputValue={
            //   this.props.selectedCategory != null &&
            //   this.props.selectedCategory.length > 0
            //     ? this.props.selectedCategory[0].label
            //     : ''
            // }

            // noOptionsMessage="No category listed"
            isDisabled={categoriesOptions.length === 0 ? true : false}
            // onChange={(option) => this.setState({ selectedCategory: option })}
          />
          <Select
            className="vendorSelect4 col-lg-6"
            placeholder="Keyword"
            // options={keywordsOptions}
            options={
              this.state.selectedCategory
                ? this.state.categoryKeywords
                : keywordsOptions
            }
            isMulti={false}
            name="select_keyword"
            // noOptionsMessage="No keyword listed"
            isDisabled={keywordsOptions.length === 0 ? true : false}
            onChange={(option) => this.setState({ selectedKeyword: option })}
            value={this.state.selectedKeyword}
          />
          {/* <Select
            className="vendorSelect4 col-lg-6"
            placeholder="Keywords"
            options={keywordsOptions}
            // options={
            //   this.state.selectedCategory
            //     ? this.state.categoryKeywords
            //     : keywordsOptions
            // }
            // options={this.state.categoryKeywords}
            isMulti={false}
            name="select_keyword"
            noOptionsMessage="No keyword listed"
            isDisabled={this.state.categoryKeywords.length === 0 ? true : false}
            onChange={(option) => this.setState({ selectedKeyword: option })}
            value={this.state.selectedKeyword}
          /> */}
          <Select
            className="vendorSelect2 col-lg-2  "
            placeholder="State/City"
            options={stateOptions}
            isMulti={false}
            name="select_state"
            // noOptionsMessage="No state listed"
            isDisabled={stateOptions.length === 0 ? true : false}
            onChange={(option) => this.setState({ selectedState: option })}
            value={this.state.selectedState}
          />
          <Select
            className="vendorSelect3 "
            placeholder="Price"
            options={costlyOptions}
            isMulti={false}
            name="select_costly"
            onChange={(option) => this.setState({ selectedCostly: option })}
            value={this.state.selectedCostly}
          />
          <div style={{ textAlign: 'center' }}>
            <button
              className="searchVendorBtn mx-1 "
              onClick={() => this.searchSubmit()}
            >
              {/* <img
                src="../../images/search.svg"
                alt="searchimage"
                className="search-icon"
              /> */}

              <span className="search-text text-dark fs-4 ">Search</span>
            </button>

            <button
              // style={{ fontSize: '10px' }}
              className="resetVendorBtn mx-1 fs-4"
              onClick={() => this.resetSearch()}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default VendorSearchForm;
