import React from 'react';
import './signup.css';
import Image from './Rectangle 486.png';
import Divider from './Divider.png';

function FinishProfile() {
  return (
    <>
      <div className="container-fluid">
        <div className="text-container">
          <div className="text-side">
            <p
              style={{
                fontWeight: '600',
                fontSize: '24px',

                maxWidth: '63rem',
                marginTop: '10rem',
              }}
            >
              Your Event Massi profile is now live!
              <br></br> <br></br>Please finish filling out remaining information
              (such as social media links) or complete it later in your Vendor
              Dashboard
            </p>
            <div className="customFormGroup">
              <button
                type="button"
                className="signUpBtn-signup-NewUser bg-light text-dark border border-2"
                name="cancel"
                value="cancel"
                onClick={() => {
                  window.location = '/dashboard';
                }}
              >
                Skip for Now
              </button>
              <button
                type="submit"
                className="signUpBtn-signup-NewUser"
                name="register"
                value="Register"
                onClick={() => {
                  window.location = '/dashboard';
                }}
              >
                <span>Finish My Profile</span>
              </button>
            </div>
          </div>
        </div>

        <div className="box-container">
          <div className="centered-content">
            <img src={Image} alt="ImageFinish" className="box-image" />
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <img
          src={Divider}
          alt="Divider"
          style={{ width: '100%', position: 'absolute', bottom: -3 }}
        />
      </div>
    </>
  );
}

export default FinishProfile;
