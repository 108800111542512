import React from 'react';
import UploadVendorLogo from './UploadVendorLogo';
import VendorMyInformation from './VendorMyInformation';
import VendorCompanyAndServiceInfo from './VendorCompanyAndServiceInfo';
import VendorSocialMediaProfile from './VendorSocialMediaProfile';
import VendorDeleteMyProfile from './VendorDeleteMyProfile';
import SkeletonCardEditProfile from './Skeltons/SkeletonCardEditProfile';

class LoginVendorProfile extends React.Component {
  render() {
    const { vendorProfile, allCategories, allStates, allKeywords } = this.props;
    return (
      <>
        {vendorProfile == null ? (
          <>
            <SkeletonCardEditProfile />
          </>
        ) : (
          <>
            <UploadVendorLogo
              vendorProfile={vendorProfile}
              updateVendorInfo={(params) => this.props.updateVendorInfo(params)}
            />
            <VendorMyInformation
              vendorProfile={vendorProfile}
              allCategories={allCategories}
              allStates={allStates}
              updateVendorInfo={(params) => this.props.updateVendorInfo(params)}
            />

            <VendorCompanyAndServiceInfo
              vendorProfile={vendorProfile}
              allKeywords={allKeywords}
              updateVendorInfo={(params) => this.props.updateVendorInfo(params)}
            />
            <VendorSocialMediaProfile
              vendorProfile={vendorProfile}
              updateVendorInfo={(params) => this.props.updateVendorInfo(params)}
            />
            <VendorDeleteMyProfile vendorProfile={vendorProfile} />
          </>
        )}
      </>
    );
  }
}

export default LoginVendorProfile;
