import React, { useEffect, useState } from 'react';

import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import './Checkout.css';
import { ApiRoute } from '../util/constants';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
export default function CheckoutForm(props) {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState(props.clientSecret);
  const { paymentMethod, setPaymentMethod } = useState();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   if (stripe) {
  //     const pr = stripe.paymentRequest({
  //       country: 'US',
  //       currency: 'usd',
  //       total: {
  //         label: 'Demo total',
  //         amount: 1099,
  //       },
  //       requestPayerName: true,
  //       requestPayerEmail: true,
  //     });
  //     pr.on('paymentmethod', async (e) => {
  //       e.complete('success');
  //     });
  //     pr.canMakePayment().then((result) => {
  //       if (result) {
  //         setPaymentRequest(pr);
  //       }
  //     });
  //   }
  // }, [stripe]);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/completion',
      },
      // Uncomment below if you only want redirect for redirect-based payments
      redirect: 'if_required',
    });
    console.log('====================================');
    console.log(
      'CONFIRMPAYMENT RESPONSE',
      paymentIntent,

      error
    );
    console.log('====================================');
    if (error !== undefined && error !== null) {
      setMessage(error.message);
    } else if (
      paymentIntent !== null &&
      paymentIntent !== undefined &&
      paymentIntent.status === 'succeeded'
    ) {
      setMessage('SUCCESS');
      let token = AuthService.getToken();

      axios
        .post(
          `${ApiRoute.paymentComplete}`,
          {
            paymentIntent: paymentIntent,
            paymentData: props.paymentData,
            subscription: props.subscription,
          },

          {
            headers: {
              Authorization: `Bearer ` + token,
            },
          }
        )
        .then((response) => {
          console.log('====================================');
          console.log(response);
          console.log('====================================');
          navigate('/completion');
        });
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )} */}
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
