import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAsrWvktkUc1cupLvHtS5h8I_USbrch_xE',
  authDomain: 'massi-885d7.firebaseapp.com',
  projectId: 'massi-885d7',
  storageBucket: 'massi-885d7.appspot.com',
  messagingSenderId: '220596489831',
  appId: '1:220596489831:web:62ac3a7c5750cf0ad5cec2',
  measurementId: 'G-E4D8KPNRC8',
};

initializeApp(firebaseConfig);
export const auth = getAuth();
export const google = new GoogleAuthProvider();
export const facebook = new FacebookAuthProvider();
export const apple = new OAuthProvider('apple.com');
