import React from "react";
import "./layout.css";
import LoginSignupModal from "../../components/LoginSignupModal";
import SignupModal from "../../components/LoginSignupModal2";
import Logo from "../../assets/images/Massi Footer Logo.svg";
import { Link } from "react-router-dom";
import { Local_routes } from "../../util/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Tiktok from "../../assets/images/Tiktok copy.svg";
import Facebook from "../../assets/images/fbicon.svg";
import Instagram from "../../assets/images/IG.svg";
import Twitter from "../../assets/images/twitter.svg";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Drawer: false,
      signinModal: false,
      signupModal: false,
      userAuth: localStorage.getItem("userToken")
        ? atob(localStorage.getItem("userToken"))
        : null,
      userData: localStorage.getItem("userData")
        ? JSON.parse(atob(localStorage.getItem("userData")))
        : null,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginFormShow !== this.props.loginFormShow) {
      this.setState({ signupModal: this.props.loginFormShow });
    }
  }

  toogledrawer = (value) => {
    this.setState({ Drawer: value });
  };

  loginModalClose = (redirect = false) => {
    this.props.closeLoginModal();
    this.setState({
      signinModal: false,
      signupModal: false,
      userAuth: localStorage.getItem("userToken")
        ? atob(localStorage.getItem("userToken"))
        : null,
      userData: localStorage.getItem("userData")
        ? JSON.parse(atob(localStorage.getItem("userData")))
        : null,
    });
    if (redirect) {
      this.props.navigate("/dashboard", {
        userAuth: this.state.userAuth,
        userData: this.state.userData,
      });
    }
  };

  render() {
    const { userData, userAuth } = this.state;
    return (
      <>
        <footer className="customFooter">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <LazyLoadImage
                  effect="blur"
                  className="footerLogo"
                  src={Logo}
                  width={300}
                  height={100}
                  alt="EM Footer Logo"
                />
                <p className="footer1stPara">
                  Your one stop shop to find all of your favorite event vendors
                  and venues in your area! Want to get listed?
                </p>

                {/* <button
                    onClick={() =>
                      this.setState({
                        signinModal: false,
                        signupModal: true,
                      })
                    }
                    className="joinUsBtn"
                  >
                    Join Us
                  </button> */}
                {/* {!userAuth && !userData ? ( */}
                <button className="joinUsBtn">
                  <Link className="text-light" to={Local_routes.signup}>
                    Join Us
                  </Link>
                </button>
                {/* ) : null} */}
              </div>
              <div className="col-md-4">
                <div className="footerLinks">
                  <h1>Useful Links</h1>
                  <ul>
                    <li>
                      <Link to="/about_us">About us</Link>
                    </li>
                    <li>
                      <Link to="/contact_us">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/massi">Why Massi</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footerFollowLinks">
                  <h6>Follow Us</h6>
                  <ul>
                    <li>
                      {" "}
                      <a
                        href="https://twitter.com/eventmassi?lang=en"
                        rel="noreferrer"
                        target={"_blank"}
                      >
                        <img
                          width={300}
                          height={40}
                          src={Twitter}
                          alt="Twitter Logo"
                        />{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://www.facebook.com/eventmassi/"
                        rel="noreferrer"
                        target={"_blank"}
                      >
                        <img
                          width={300}
                          height={40}
                          src={Facebook}
                          alt="Facebook Logo"
                        />{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://www.instagram.com/eventmassi/?hl=en"
                        rel="noreferrer"
                        target={"_blank"}
                      >
                        <img
                          width={300}
                          height={40}
                          src={Instagram}
                          alt="Instagram Logo"
                        />{" "}
                      </a>
                    </li>

                    <li>
                      {" "}
                      <a
                        href="https://www.tiktok.com/@eventmassi"
                        rel="noreferrer"
                        target={"_blank"}
                      >
                        <img
                          width={300}
                          height={40}
                          src={Tiktok}
                          alt="Tiktok Logo"
                        />{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footerBottomLinks">
              <ul>
                <li>
                  <Link to="/terms">Terms & Services</Link>
                </li>
                <li>
                  <span className="separator">|</span>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
                <li>
                  <span className="separator">|</span>
                  <Link to="/cookies">Cookies Policy</Link>
                </li>
              </ul>

              <p>© 2022 Event Massi LLC. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
        <LoginSignupModal
          signinupModal={this.state.signinModal}
          loginModalClose={(redirect) => this.loginModalClose(redirect)}
        />
        <SignupModal
          signinupModal={this.state.signupModal}
          loginModalClose={(redirect) => this.loginModalClose(redirect)}
        />
      </>
    );
  }
}

export default Footer;
