import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonCardEditProfile = () => {
  return (
    <div>
      {/* Loader for Profile Image */}
      <div className="container margin-top-50">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="dashboard-list-box margin-top-0"
              style={{ boxShadow: '0 0 3px 0 rgb(0 0 0 / 6%)' }}
            >
              <h4 className="gray">My Vendor Profile Image</h4>
              <div className="dashboard-list-box-static bg-light-gray">
                <div className="edit-profile-photo">
                  <div className="change-photo-btn">
                    <div className="photoUpload">
                      <span>
                        <i className="fa fa-upload"></i> Upload Photo
                      </span>
                    </div>
                  </div>
                </div>
                <Skeleton height={200} width={200} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Loader for My Information */}
      <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form className="generalFormWrap myInformationForm">
            <h3 className="editFormHeading">My Information</h3>
            <div className="customFormGrp">
              <label className="fw-bold">
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label className="fw-bold">
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label className="fw-bold">
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label className="fw-bold">
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label className="fw-bold">
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp fs-3">
              <label className="fw-bold">
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={40} width={'100%'} />
              </div>
            </div>

            <div className="customFormGrp">
              <label className="fw-bold">
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>

            <div className="customFormGrp">
              <label className="fw-bold">
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>

            <div className="customFormGrp btnFormGrp">
              <Skeleton height={40} width={200} />
            </div>
          </form>
        </div>
      </div>

      {/* <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form className="generalFormWrap myInformationForm">
            <h3 className="editFormHeading">My Information</h3>
            <div className="customFormGrp">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp ">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp ">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp ">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp ">
              <Skeleton height={20} width={200} />
            </div>
          </form>
        </div>
      </div> */}
      {/* Loader for Company Info */}

      <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form className="generalFormWrap myInformationForm">
            <h3 className="editFormHeading">
              Tell us about your company &amp; services
            </h3>
            <div className="customFormGrp">
              <label>
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={200} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'30%'} />
              </div>
            </div>

            <div className="customFormGrp">
              <label>
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp btnFormGrp">
              <Skeleton height={40} width={200} />
            </div>
          </form>
        </div>
      </div>

      {/* Loader for Social Media */}

      <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form className="generalFormWrap myInformationForm">
            <h3 className="editFormHeading">My Social media profiles</h3>
            <div className="customFormGrp">
              <label>
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                <Skeleton height={20} width={200} />
              </label>
              <div className="formControlWrap">
                <Skeleton height={30} width={'100%'} />
              </div>
            </div>
            <div className="customFormGrp btnFormGrp">
              <Skeleton height={40} width={200} />
            </div>
          </form>
        </div>
      </div>

      {/* <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form className="generalFormWrap myInformationForm editMyInfo">
            <h3 className="editFormHeading">My Social media profiles</h3>
            <div className="customFormGrp">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp">
              <Skeleton height={20} width={200} />
            </div>
            <div className="customFormGrp">
              <Skeleton height={20} width={200} />
            </div>
          </form>
        </div>
      </div> */}
      {/* Loader for Delete Profile */}

      <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form className="generalFormWrap myInformationForm">
            <h3 className="editFormHeading">Delete My Profile</h3>
            <p className="text-center mx-auto ">
              This will delete your business listing from Event Massi, you will
              no longer be listed in our directory.
            </p>
            <div className="customFormGrp btnFormGrp">
              <Skeleton height={40} width={200} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCardEditProfile;
