import React from 'react';
import ReactPaginate from 'react-paginate';
class BottomPagination extends React.Component {
  render() {
    return (
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        forcePage={this.props.currentPage - 1}
        onPageChange={(page) => this.props.handlePagination(page.selected + 1)}
        pageCount={this.props.totalPages}
        breakLabel="..."
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        activeClassName="current-page"
        activeLinkClassName="current-page"
        pageClassName="page-item"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        nextLinkClassName="page-link"
        nextClassName="page-item next"
        previousClassName="page-item prev"
        previousLinkClassName="page-link"
        pageLinkClassName="page-link"
        containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
      />
    );
  }
}

export default BottomPagination;
