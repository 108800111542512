import React from "react";
import HeaderDocumentDetail from "../../components/HeaderDocumentDetail";
import CommonService from "../../services/CommonService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContent } from "../../components/Toast";
import "./Contact.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import ContactImage from "../../assets/images/contactusPic.svg";
import SkeltonCardContactUs from "../../components/Skeltons/SkeltonCardContactUs";

class Dashboard extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }

  submitContactForm = async (e) => {
    e.preventDefault();
    toast(() => ToastContent("Sending......."), {
      toastId: "infoToast",
      hideProgressBar: false,
      type: toast.TYPE.INFO,
      autoClose: 1000,
    });
    try {
      var firstName = e.target.elements.firstname.value;
      var lastName = e.target.elements.lastname.value;
      var phone = e.target.elements.phone.value;
      var email = e.target.elements.email.value;
      var category = e.target.elements.category.value;
      var message = e.target.elements.comments.value;
      var params = { firstName, lastName, phone, email, category, message };
      let contactus = await CommonService.contactUsForm(params);
      toast.dismiss();
      if (contactus.success) {
        toast(() => ToastContent("Your message has been sent successfully"), {
          toastId: "successToast",
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        });
        e.target.reset();
      } else {
        toast(() => ToastContent("Error on sending message"), {
          toastId: "errorToast",
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1500,
        });
      }
    } catch (err) {
      toast.dismiss();
      toast(() => ToastContent("Something went wrong"), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <HeaderDocumentDetail
          title="EventMassi | Contact Us"
          description="Welcome to the Event Massi Contact Us page! If you have any questions, feedback, or inquiries regarding our event management platform, we're here to help. This page serves as a hub for contacting our dedicated support team and getting in touch with us directly.

At Event Massi, we prioritize excellent customer service and aim to provide prompt and effective assistance to our users. Whether you're an event organizer, attendee, or someone interested in learning more about our platform, we encourage you to reach out to us with any queries or concerns you may have."
        />
        <div className="container margin-top-70 margin-bottom-50">
          {isLoading && (
            <>
              <SkeltonCardContactUs />
            </>
          )}

          {!isLoading && (
            <div className="container ">
              <LazyLoadImage
                className="d-flex mx-auto"
                width={500}
                height={300}
                src={ContactImage}
                alt="Contact Icon"
              />
              <h1 className="Massi">Contact Us</h1>
              <div className="container ">
                <p className="text-center">
                  Any Question Or Remarks? Just Write Us A Message!
                </p>
              </div>
              <div className="row mt-5">
                <div className="col-md-4">
                  <div className="sidebar-textbox mx-lg-4 text-light   ">
                    <h4 className="headline text-light  margin-bottom-20">
                      Contact Information
                    </h4>
                    <p>
                      Fill up the form and our Team will get back to you within
                      24 hours.
                    </p>

                    <ul className="contact-details  ">
                      <li>
                        <i className="im text-light im-icon-Map-Marker2" />
                        <h3 className="text-light mt-3">Long Island, NY </h3>
                      </li>

                      <li>
                        <i className="im text-light im-icon-Envelope"></i>{" "}
                        <h3 className="text-light mt-3">
                          support@eventmassi.com
                        </h3>
                      </li>
                      <li>
                        <i className="im text-light im-icon-Telephone"></i>{" "}
                        <h3 className="text-light mt-3">(516) 350-0095</h3>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-8 details  ">
                  <section id="contact">
                    <form onSubmit={this.submitContactForm}>
                      <div className="row mt-5">
                        <div className="col-md-6 ">
                          <div>
                            <label>
                              First Name<sup>*</sup>
                            </label>
                            <input
                              name="firstname"
                              type="text"
                              className="contact-form"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-md-6  ">
                          <div>
                            <label>
                              Last Name<sup>*</sup>
                            </label>
                            <input
                              name="lastname"
                              type="text"
                              className="contact-form"
                              required="required"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-md-6">
                          <div>
                            <label>
                              Email <sup>*</sup>
                            </label>
                            <input
                              name="email"
                              type="email"
                              className="contact-form"
                              pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <label>
                              Phone<sup>*</sup>
                            </label>
                            <input
                              name="phone"
                              type="number"
                              className="contact-form"
                              id="phone"
                              required="required"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label className="fw-bold">Category</label>
                          <div className="radioButtonGrp">
                            <label className="customRadioBtnContainer">
                              <input
                                type="radio"
                                name="category"
                                value={"General Inquiry"}
                              />
                              <span>General Inquiry</span>
                              <span className="checkmark"></span>
                            </label>
                            <label className="customRadioBtnContainer">
                              <input
                                type="radio"
                                name="category"
                                value={"Technical Issue"}
                              />
                              <span>Technical Issue</span>
                              <span className="checkmark"></span>
                            </label>
                            <label className="customRadioBtnContainer">
                              <input
                                type="radio"
                                name="category"
                                value={"Suggestion"}
                              />
                              <span>Suggestion</span>
                              <span className="checkmark"></span>
                            </label>
                            <label className="customRadioBtnContainer">
                              <input
                                type="radio"
                                name="category"
                                value={"Other"}
                              />
                              <span>Other</span>
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="mt-5">
                          Message<sup>*</sup>
                        </label>
                        <textarea
                          name="comments"
                          type="text"
                          cols="40"
                          rows="3"
                          id="comments"
                          className="contact-form "
                          placeholder="Type your Message"
                          spellCheck="true"
                          required="required"
                        ></textarea>
                      </div>

                      <input
                        type="submit"
                        className="submit button "
                        id="submit"
                        value="Submit Now!"
                      />
                    </form>
                  </section>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Dashboard;
