import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonCardDeals = () => {
  return (
    <div className="row margin-top-25 margin-bottom-50">
      <div className="dealsTopContentWrap">
        <h6>
          <Skeleton width={150} />
        </h6>
        <p>
          <Skeleton count={1} />
        </p>
      </div>

      <div className="dealsFormWrap">
        <form className="dealsForm">
          <div className="dealFormGrp">
            <label className="">Deal Name</label>
            <input type="text" className="dealFormControl" />
          </div>
          <div className="dealFormGrp">
            <label>Description</label>
            <input type="text" className="dealFormControl" />
          </div>
          <div className="text-center justify-content-center mx-auto">
            <Skeleton height={150} />
          </div>
          <div className="upload-btn-wrapper mt-3">
            <button className="btn">
              <img alt="Upload Button" />
            </button>
            <input
              type="file"
              name="myfile"
              className="upload_deal_image"
              accept="image/*"
            />
          </div>
          <div className="dealFormGrp dealBtnGrp">
            <Skeleton width={150} />
          </div>
        </form>
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12">
        <div
          className="dashboard-list-box margin-top-20"
          style={{ boxShadow: "0 0 4px 0 rgb(0 0 0 / 6%)" }}
        >
          <ul>
            <li>
              <div className="list-box-listing">
                <div className="list-box-listing-img">
                  <Skeleton height={150} width={150} />
                </div>
                <div className="list-box-listing-content">
                  <div className="inner">
                    <h3>
                      <Skeleton width={200} />
                    </h3>
                    <span>
                      <Skeleton count={3} />
                    </span>
                    <div className="star-rating" data-rating="5.0">
                      <div className="rating-counter">
                        <Skeleton width={100} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttons-to-right">
                <Skeleton width={80} />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCardDeals;
