import React from "react";
import "./vendor.css";
import FacebookDashboard from "../assets/images/FacebookDashBoard..svg";
import TwitterDashboard from "../assets/images/TwitterDashBoard.svg";
import PinterestDashboard from "../assets/images/PinterestDashBoard..svg";
import TiktokDashboard from "../assets/images/TikTokDashBoard..svg";
import YoutubeDashboard from "../assets/images/youtube.png";
import InstagramDashboard from "../assets/images/GroupDashBoard..svg";
class VendorSocialMediaProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      facebook: this.props.vendorProfile?.socials?.facebook || "",
      instagram: this.props.vendorProfile?.socials?.instagram || "",
      pinterest: this.props.vendorProfile?.socials?.pinterest || "",
      youtube: this.props.vendorProfile?.socials?.youtube || "",
      tiktok: this.props.vendorProfile?.socials?.tiktok || "",
      twitter: this.props.vendorProfile?.socials?.twitter || "",
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();

    var socials = {
      website: this.props.vendorProfile?.socials?.website,
      facebook: e.target.elements.facebook.value,
      instagram: e.target.elements.instagram.value,
      pinterest: e.target.elements.pinterest.value,
      youtube: e.target.elements.youtube.value,
      tiktok: e.target.elements.tiktok.value,
      twitter: e.target.elements.twitter.value,
    };
    var params = {
      socials,
    };
    this.props.updateVendorInfo(params);
  };
  render() {
    const { vendorProfile } = this.props;
    return (
      <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form
            onSubmit={this.handleSubmit}
            className="generalFormWrap myInformationForm editMyInfo"
          >
            <h3 className="editFormHeading">My Social media profiles</h3>
            <div className="customFormGrp">
              <label>
                Facebook Username <img src={FacebookDashboard} alt="Fb Icon" />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="facebook"
                  value={this.state.facebook}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value) {
                      this.setState({ facebook: "" });
                      return;
                    }
                    if (
                      !value.startsWith("http://") &&
                      !value.startsWith("https://")
                    ) {
                      value = "https://www.facebook.com/" + value;
                    } else if (
                      value === "https://" ||
                      value === "https://www.facebook.com/"
                    ) {
                      value = "";
                    }
                    this.setState({ facebook: value });
                  }}
                />

                {/* <input
                  type="text"
                  className="customFormControl"
                  id="facebook"
                  value={this.state.facebook}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value) {
                      this.setState({ facebook: '' });
                      return;
                    }
                    if (
                      !value.startsWith('http://') &&
                      !value.startsWith('https://')
                    ) {
                      value = 'https://' + value;
                    } else if (value === 'https://') {
                      value = '';
                    }
                    this.setState({ facebook: value });
                  }}
                  // id="facebook"
                  // defaultValue={vendorProfile?.socials?.facebook}
                /> */}
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Instagram Username{" "}
                <img src={InstagramDashboard} alt="Instagram icon" />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="instagram"
                  value={this.state.instagram}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value) {
                      this.setState({ instagram: "" });
                      return;
                    }
                    if (
                      !value.startsWith("http://") &&
                      !value.startsWith("https://")
                    ) {
                      value = "https://www.instagram.com/" + value;
                      // value = 'https://' + value;
                    } else if (
                      value === "https://" ||
                      value === "https://www.instagram.com/"
                    ) {
                      value = "";
                    }

                    this.setState({ instagram: value });
                  }}
                  // id="instagram"
                  // defaultValue={vendorProfile?.socials?.instagram}
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Pinterest Username{" "}
                <img src={PinterestDashboard} alt="Pinterest Icon" />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="pinterest"
                  value={this.state.pinterest}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value) {
                      this.setState({ pinterest: "" });
                      return;
                    }
                    if (
                      !value.startsWith("http://") &&
                      !value.startsWith("https://")
                    ) {
                      // value = 'https://' + value;
                      value = "https://www.pinterest.com/" + value;
                    }
                    // else if (value === 'https://')
                    else if (
                      value === "https://" ||
                      value === "https://www.pinterest.com/"
                    ) {
                      value = "";
                    }
                    this.setState({ pinterest: value });
                  }}
                  // id="pinterest"
                  // defaultValue={vendorProfile?.socials?.pinterest}
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Youtube Username{" "}
                <img src={YoutubeDashboard} alt="Youtube Icon" />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="youtube"
                  value={this.state.youtube}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value) {
                      this.setState({ youtube: "" });
                      return;
                    }
                    if (
                      !value.startsWith("http://") &&
                      !value.startsWith("https://")
                    ) {
                      // value = 'https://' + value;
                      value = "https://www.youtube.com/" + value;
                    }

                    // else if (value === 'https://')
                    else if (
                      value === "https://" ||
                      value === "https://www.youtube.com/"
                    ) {
                      value = "";
                    }
                    this.setState({ youtube: value });
                  }}
                  // id="youtube"
                  // defaultValue={vendorProfile?.socials?.youtube}
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Tiktok Username <img src={TiktokDashboard} alt="Tiktok Icon" />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="tiktok"
                  value={this.state.tiktok}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value) {
                      this.setState({ tiktok: "" });
                      return;
                    }
                    if (
                      !value.startsWith("http://") &&
                      !value.startsWith("https://")
                    ) {
                      // value = 'https://' + value;
                      value = "https://www.tiktok.com/" + value;
                    } else if (
                      value === "https://" ||
                      value === "https://www.tiktok.com/"
                    ) {
                      // else if (value === 'https://')
                      value = "";
                    }
                    this.setState({ tiktok: value });
                  }}
                  // id="tiktok"
                  // defaultValue={vendorProfile?.socials?.tiktok}
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Twitter Username{" "}
                <img src={TwitterDashboard} alt="Twitter Icon" />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="twitter"
                  value={this.state.twitter}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (!value) {
                      this.setState({ twitter: "" });
                      return;
                    }
                    if (
                      !value.startsWith("http://") &&
                      !value.startsWith("https://")
                    ) {
                      // value = 'https://' + value;
                      value = "https://www.twitter.com/" + value;
                    }
                    // else if (value === 'https://')
                    else if (
                      value === "https://" ||
                      value === "https://www.twitter.com/"
                    ) {
                      value = "";
                    }
                    this.setState({ twitter: value });
                  }}
                  // id="twitter"
                  // defaultValue={vendorProfile?.socials?.twitter}
                />
              </div>
            </div>
            <div className="customFormGrp btnFormGrp">
              <button className="editProfileSubmitBtn">
                Update Social Media
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default VendorSocialMediaProfile;
