import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withRouter from "../../helper/withRouter";
import HeaderDocumentDetail from "../../components/HeaderDocumentDetail";
import VendorService from "../../services/VendorService";
import VendorMessages from "../../components/VendorMessages";
import LoginVendorProfile from "../../components/LoginVendorProfile";
import { ToastContent } from "../../components/Toast";
import CategoriesService from "../../services/CategoriesService";
import StateService from "../../services/StateService";
import getAllKeywords from "../../services/KeywordsService";
import VendorDashboardDeals from "../../components/VendorDashboardDeals";
import { Modal, ModalBody } from "reactstrap";
import VendorDashboardSubscription from "../../components/VendorDashboardSubscription";
import VendorDashboardGallery from "../../components/VendorDashboardGallery";
import ErrorPage from "../../error_page";

class Dashboard extends React.Component {
  state = {
    // tabView: 'dashboard',
    tabView: "profile",
    userAuth: localStorage.getItem("userToken")
      ? atob(localStorage.getItem("userToken"))
      : null,

    userData: localStorage.getItem("userData")
      ? JSON.parse(atob(localStorage.getItem("userData")))
      : null,
    isPaid: localStorage.getItem("isPaid"),
    vendorMessages: null,
    vendorProfile: null,
    allCategories: null,
    allStates: null,
    allKeywords: null,
    dealsData: null,
    showDealModal: false,
    dealImage: null,
    subscriptionData: null,
    networkErrorMessage: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getPageData(this.state.tabView);
  }

  setTabStatus = (tabname) => {
    if (this.state.tabView !== tabname) {
      this.setState({ tabView: tabname });
      this.getPageData(tabname);
    }
  };

  getPageData = async (tabname) => {
    if (tabname === "dashboard") {
      this.getMessageData();
    } else if (tabname === "profile" || tabname === "gallery") {
      var allcategories =
        this.state.allCategories === null
          ? await this.getCategoriesList()
          : this.state.allCategories;
      var allstates =
        this.state.allStates === null
          ? await this.getStateList()
          : this.state.allStates;
      var allkeywords =
        this.state.allKeywords === null
          ? await this.getAllKeywords()
          : this.state.allKeywords;
      var profiledata =
        this.state.vendorProfile === null
          ? await this.getProfileData()
          : this.state.vendorProfile;
      if (allcategories && allstates && allkeywords && profiledata) {
        this.setState({
          vendorProfile: profiledata,
          allCategories: allcategories,
          allStates: allstates,
          allKeywords: allkeywords,
        });
      } else {
        <ErrorPage />;
        // toast(() => ToastContent('Data not load. Network error'), {
        //   toastId: 'errorToast',
        //   hideProgressBar: false,
        //   type: toast.TYPE.ERROR,
        //   autoClose: 1000,
        // });
      }
      // } else if (tabname === 'gallery') {
      //   var gallerydata =
      //     this.state.vendorProfile === null
      //       ? await this.getProfileData()
      //       : this.state.vendorProfile;
      //   if (gallerydata) {
      //     this.setState({
      //       vendorProfile: gallerydata,
      //     });
      //   }
    } else if (tabname === "subscription") {
      let data =
        this.state.subscriptionData === null ||
        this.state.subscriptionData.length <= 0
          ? await this.getSubscriptionData()
          : this.state.subscriptionData;
      this.setState({ subscriptionData: data });
      let profiledata =
        this.state.vendorProfile === null
          ? await this.getProfileData()
          : this.state.vendorProfile;
      this.setState({ vendorProfile: profiledata });
    } else if (tabname === "deals") {
      this.getDealsData();
    }
  };

  getMessageData = async () => {
    try {
      let data = await VendorService.getVendorContactMessages();
      this.setState({ vendorMessages: data });
    } catch (err) {
      this.setState({ vendorMessages: [], networkErrorMessage: err.message });
      toast(() => ToastContent(err.message), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
    }
  };

  getSubscriptionData = async () => {
    try {
      let data = await VendorService.getSubscriptionList();
      return data.data;
    } catch (err) {
      return [];
    }
  };

  getProfileData = async () => {
    try {
      let data = await VendorService.getLoginVendorProfileData();
      return data.data;
    } catch (err) {
      console.error("Profile error msg: ", err.message);
      return false;
    }
  };

  getDealsData = async () => {
    try {
      let data = await VendorService.getLoginVendorProfileData();
      this.setState({ dealsData: data.deals });
      return true;
    } catch (err) {
      console.error("Deals error msg: ", err.message);
      return false;
    }
  };

  getCategoriesList = async () => {
    try {
      let categorieslist = await CategoriesService.getCategories();
      return categorieslist.data;
    } catch (err) {
      console.error("My profile categories error: ", err.message);
      return false;
    }
  };

  getStateList = async () => {
    try {
      let statelist = await StateService.getStates();
      return statelist.data;
    } catch (err) {
      console.error("My profile states error: ", err.message);
      return false;
    }
  };

  getAllKeywords = async () => {
    try {
      let keywordslist = await getAllKeywords.getAllKeywords();
      return keywordslist.data;
    } catch (err) {
      console.error("My profile keywords error: ", err.message);
      return false;
    }
  };

  deleteUserMessage = async (msgId) => {
    try {
      let deleteMsg = await VendorService.deleteVendorContactMessages(msgId);
      const newList = this.state.vendorMessages.data.filter(
        (item) => item.id !== msgId
      );
      this.setState((this.state.vendorMessages.data = newList));
      toast(() => ToastContent(deleteMsg.message), {
        toastId: "infoToast",
        hideProgressBar: false,
        type: toast.TYPE.SUCCESS,
        autoClose: 1000,
      });
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  updateVendorInfo = async (params) => {
    toast(() => ToastContent("Please wait....."), {
      toastId: "infoToast",
      hideProgressBar: false,
      type: toast.TYPE.INFO,
      autoClose: 1000,
    });
    try {
      let updateProfileInfo = await VendorService.updateVendorInfo(params);
      toast.dismiss();
      if (updateProfileInfo.success) {
        this.setState({
          vendorProfile: updateProfileInfo.data,
          userData: updateProfileInfo.data,
        });
        localStorage.setItem(
          "userData",
          btoa(JSON.stringify(updateProfileInfo.data))
        );

        toast(() => ToastContent("Profile updated successfully"), {
          toastId: "successToast",
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        if (
          updateProfileInfo.error &&
          updateProfileInfo.error
            .toLowerCase()
            .includes("email is already in use")
        ) {
          toast(
            () =>
              ToastContent(
                "Email is already in use. Please use a different email."
              ),
            {
              toastId: "dangerToast",
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            }
          );
        } else {
          toast(() => ToastContent("Error while updating profile"), {
            toastId: "dangerToast",
            hideProgressBar: false,
            type: toast.TYPE.ERROR,
            autoClose: 1000,
          });
        }
      }

      // else {
      //   toast(() => ToastContent('Error while updating profile'), {
      //     toastId: 'dangerToast',
      //     hideProgressBar: false,
      //     type: toast.TYPE.ERROR,
      //     autoClose: 1000,
      //   });
      // }
    } catch (err) {
      toast.dismiss();
      toast(() => ToastContent(err), {
        toastId: "infoToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  uploadNewDeal = async (e) => {
    e.preventDefault();
    try {
      var name = e.target.elements.dealname.value;
      var description = e.target.elements.dealdescription.value;
      var dealMedia = await VendorService.uploadVendorProfileMedia(
        this.state.dealImage
      );
      var params = {
        name,
        description,
        assets: { type: "photo", url: dealMedia.imagePath },
      };
      var uploadDeal = await VendorService.uploadVendorDeal(params);
      if (uploadDeal.success) {
        var oldDeals = this.state.dealsData;
        oldDeals.push(uploadDeal.deal);
        this.setState({ dealsData: oldDeals, showDealModal: false });
        toast(() => ToastContent("Deal has been created successfully"), {
          toastId: "successToast",
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
      } else {
        toast(() => ToastContent("Deal not created."), {
          toastId: "errorToast",
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } catch (err) {
      toast(() => ToastContent("Deal not created."), {
        toastId: "errorToast",
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  onChangeDealImage = async (event) => {
    let imagePath = event.target.files[0];
    this.setState({ dealImage: imagePath });
  };

  //render method
  render() {
    const {
      tabView,
      vendorMessages,
      vendorProfile,
      allCategories,
      allStates,
      allKeywords,

      dealsData,
      subscriptionData,
      networkErrorMessage,
    } = this.state;

    return (
      <>
        <HeaderDocumentDetail
          title="EventMassi |Dashboard"
          description="Welcome to the Event Massi Dashboard! This page serves as a central hub for vendors, providing a range of features and functionalities to enhance your experience on the platform.

Upon logging in, you will be presented with a user-friendly dashboard interface that allows you to access various sections and manage your vendor account effectively. The dashboard is designed to provide a seamless and efficient workflow, enabling you to navigate between different tabs and perform essential tasks."
        />
        <ul className="vendorNavTabs nav nav-tabs">
          <li className="nav nav-tabs nav-item">
            <a
              href="#!"
              className={`"tab-pane fade show false nav-link " + ${
                tabView === "profile" ? "active" : null
              }`}
              onClick={() => this.setTabStatus("profile")}
            >
              Edit Profile
            </a>
          </li>
          <li className="nav nav-tabs nav-item">
            <a
              href="#!"
              className={`"tab-pane fade show false nav-link " + ${
                tabView === "dashboard" ? "active" : null
              }`}
              onClick={() => this.setTabStatus("dashboard")}
            >
              MESSAGES
            </a>
          </li>

          <li className="nav nav-tabs nav-item">
            <a
              href="#!"
              className={`"tab-pane fade show false nav-link " + ${
                tabView === "gallery" ? "active" : null
              }`}
              onClick={() => this.setTabStatus("gallery")}
            >
              Gallery
            </a>
          </li>
          <li className="nav nav-tabs nav-item">
            <a
              href="#!"
              className={`"tab-pane fade show false nav-link " + ${
                tabView === "subscription" ? "active" : null
              }`}
              onClick={() => this.setTabStatus("subscription")}
            >
              Subscription
            </a>
          </li>

          {this.state.userData?.isPaid === true ? (
            <li className="nav nav-tabs nav-item">
              <a
                href="#!"
                className={`"tab-pane fade show false nav-link " + ${
                  tabView === "deals" ? "active" : null
                }`}
                onClick={() => this.setTabStatus("deals")}
              >
                Deals &amp; Specials
              </a>
            </li>
          ) : null}
        </ul>
        <div className="clearfix"></div>
        <section style={{ backgroundColor: "#e5e5e5" }}>
          <div className="container">
            {tabView === "dashboard" ? (
              <VendorMessages
                vendorMessages={vendorMessages}
                deleteUserMessage={(msgId) => this.deleteUserMessage(msgId)}
                networkErrorMessage={networkErrorMessage}
              />
            ) : tabView === "profile" ? (
              <LoginVendorProfile
                vendorProfile={vendorProfile}
                allCategories={allCategories}
                allStates={allStates}
                allKeywords={allKeywords}
                updateVendorInfo={(params) => this.updateVendorInfo(params)}
              />
            ) : tabView === "deals" ? (
              <VendorDashboardDeals
                dealsData={dealsData}
                showDealModal={(value) =>
                  this.setState({ showDealModal: value })
                }
              />
            ) : tabView === "subscription" ? (
              <VendorDashboardSubscription
                subscriptionData={subscriptionData}
              />
            ) : tabView === "gallery" ? (
              <VendorDashboardGallery
                galleryData={vendorProfile?.assets}
                updateUserProfile={(data) => {
                  let oldProfile = vendorProfile;
                  oldProfile.assets = data.assets;
                  this.setState({
                    userData: data,
                    vendorProfile: oldProfile,
                  });
                }}
              />
            ) : null}
          </div>
        </section>
        <Modal
          isOpen={this.state.showDealModal}
          id="sign-in-dialog"
          onClose={() => this.setState({ showDealModal: false })}
          style={{ width: "800px" }}
        >
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.setState({ showDealModal: false })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody style={{ backgroundColor: "#fff" }}>
            <div className="sign-in-form style-1">
              <ul className="tabs-nav">
                <li className="active">
                  <a href="#!">Add New Deal</a>
                </li>
              </ul>

              <div className="tabs-container alt">
                <div className="tab-content">
                  <form className="login" onSubmit={this.uploadNewDeal}>
                    <p className="form-row form-row-wide">
                      <label htmlFor="dealname">
                        Deal Name
                        <input
                          type="text"
                          className="input-text"
                          name="dealname"
                          id="dealname"
                          defaultValue={""}
                          placeholder=""
                          style={{ paddingLeft: "2px !important" }}
                        />
                      </label>
                    </p>

                    <p className="form-row form-row-wide">
                      <label htmlFor="dealdescription">
                        Description:
                        <textarea
                          className="WYSIWYG"
                          textareaname="dealdescription"
                          cols="40"
                          rows="3"
                          id="dealdescription"
                          spellCheck="true"
                        ></textarea>
                      </label>
                    </p>
                    <p className="form-row form-row-wide">
                      <input
                        type="file"
                        className="upload_deal_image"
                        accept="image/*"
                        onChange={(e) => this.onChangeDealImage(e)}
                      />
                    </p>
                    <div className="form-row">
                      <input
                        type="submit"
                        className="button border margin-top-5"
                        name="Upload Now!"
                        value="Upload Now!"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withRouter(Dashboard);
