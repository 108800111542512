// import React from 'react';
// import './Stripe.css';
// import { Modal } from 'react-bootstrap';
// import AuthService from '../services/AuthService';
// import CheckoutForm from './CheckoutForm';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import Swal from 'sweetalert2';
// import Moment from 'react-moment';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
// import { ApiRoute } from '../util/constants';
// import SkeletonCardSubscription from './Skeltons/SkeletonCardSubscription';
// // const stripePromise = loadStripe(
// //   'pk_test_51HEoMOL4WzBwsDIqhzDcfOc4qDatQDXuhp28dcZGXskdCCHC7iQmCZyhTGti4ztAGmN2qAARkZkRgfwCYgUb4Krh00Os0E6Dfa'
// // );
// const stripePromise = loadStripe(
//   'pk_live_51HEoMOL4WzBwsDIq2fkWLAAglKvGVE7fPBWY9vw0lwJjzbQbyVLHtc9G7ZxBltuFPYqKjVxuSRakS6XeR9ruWl2N00wKlI1mKa'
// );
// class VendorDashboardSubscription extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       subscriptionData: this.props.subscriptionData,
//       data: [],
//       subscriptionDuration: 'Monthly',
//       paymentData: {},
//       updateData: {},
//       subscriptionId: {},
//       paymentId: {},
//       priceId: {},
//       sub: {},
//       position: {},
//       subscription: null,
//       interval: {},

//       isOpen: false,
//       clientSecret: '',
//       userData: localStorage.getItem('userData')
//         ? JSON.parse(atob(localStorage.getItem('userData')))
//         : null,
//       isPaid: localStorage.getItem('isPaid'),

//       show: false,
//     };
//   }

//   handleChangePlanClick = () => {
//     this.setState({ show: true });
//     console.log('Interval of vendor', this.state.interval);
//     console.log('Price ID of vendor', this.state.priceId);
//   };

//   vendorData = () => {
//     let token = AuthService.getToken();
//     fetch(`${ApiRoute.getLoginVendorProfile}`, {
//       headers: {
//         Authorization: `Bearer ` + token,
//       },
//     })
//       .then((res) => res.json())
//       .then((json) => {
//         let interval = 'Monthly';
//         if (json.payment?.interval === 'year') {
//           interval = 'Anually';
//         }
//         console.log('data', json);
//         this.setState({
//           data: json.payment?.subscription?.plan,
//           interval: interval,
//           priceId: json.payment?.subscription?.plan?.id,
//           subscriptionId: json.payment?.subscription?.id,
//           paymentId: json.payment?._id,
//           position: json.data?.position,
//           sub: json.payment?.subscription,
//         });
//       });
//   };

//   componentDidMount() {
//     this.vendorData();
//     console.log('SUBSCRIPTION', this.props.subscriptionData);
//   }

//   handleDelete = async (event) => {
//     let token = AuthService.getToken();

//     const confirmation = await Swal.fire({
//       title: 'Are you sure?',
//       text: 'Do you want to cancel Your Subscription?',
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, cancel it!',
//     });

//     if (confirmation.value) {
//       const response = await fetch(`${ApiRoute.cancelSubscription}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',

//           Authorization: `Bearer ` + token,
//         },
//         body: JSON.stringify({
//           _id: this.state.paymentId,
//         }),
//       });

//       const result = await response.json();
//       console.log(result);

//       if (response.status === 200) {
//         Swal.fire({
//           title: 'Subscription Cancelled',
//           text: 'Your subscription  has been cancelled successfully.',
//           icon: 'success',
//           showConfirmButton: false,
//         });
//         let userData = localStorage.getItem('userData')
//           ? JSON.parse(atob(localStorage.getItem('userData')))
//           : null;
//         if (userData !== null) {
//           userData.isPaid = false;

//           localStorage.setItem('userData', btoa(JSON.stringify(userData)));
//         }
//         setTimeout(() => {
//           window.location.reload();
//         }, 3000);
//       } else {
//         Swal.fire({
//           title: 'Error',
//           text: 'An error occurred while changing the subscription package.',
//           icon: 'error',
//         });
//       }

//       return result;
//     }
//   };

//   handleChange = async (event, item) => {
//     let token = AuthService.getToken();
//     let selectedPackage = null;
//     let allpackages = item.packages;
//     for (let i = 0; i < allpackages.length; i++) {
//       if (allpackages[i].name.trim() === this.state.subscriptionDuration) {
//         selectedPackage = allpackages[i];
//       }
//     }
//     console.log('SELECTED', selectedPackage);
//     this.setState(
//       {
//         updateData: selectedPackage.price_id,
//       },
//       async () => {
//         const confirmation = await Swal.fire({
//           title: 'Are you sure?',
//           text: 'Do you want to change your subscription package?',
//           icon: 'warning',
//           showCancelButton: true,
//           confirmButtonColor: '#3085d6',
//           cancelButtonColor: '#d33',
//           confirmButtonText: 'Yes, change it!',
//         });

//         if (confirmation.value) {
//           console.log(this.state.updateData);

//           const response = await fetch(`${ApiRoute.updateSubscription}`, {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',

//               Authorization: `Bearer ` + token,
//             },
//             body: JSON.stringify({
//               price_id: this.state.updateData,
//               subscription_id: this.state.subscriptionId,
//               _id: this.state.paymentId,
//             }),
//           });

//           const result = await response.json();
//           console.log(result);

//           if (response.status === 200) {
//             Swal.fire({
//               title: 'Package Changed',
//               text: 'Your subscription package has been changed successfully.',
//               icon: 'success',
//             });
//             this.setState({ show: false });
//             this.vendorData();
//             // window.location.href = '/dashboard';
//           } else {
//             Swal.fire({
//               title: 'Error',
//               text: 'An error occurred while changing the subscription package.',
//               icon: 'error',
//             });
//           }

//           return result;
//         }
//       }
//     );
//   };

//   handleSubmit = async (event, item) => {
//     let token = AuthService.getToken();
//     event.preventDefault();
//     // console.log('Item is', item);
//     let selectedPackage = null;
//     let allpackages = item.packages;
//     for (let i = 0; i < allpackages.length; i++) {
//       if (allpackages[i].name.trim() === this.state.subscriptionDuration) {
//         selectedPackage = allpackages[i];
//       }
//     }
//     console.log(selectedPackage);
//     this.setState({
//       paymentData: {
//         name: item.name,
//         _id: item._id,
//         price_id: selectedPackage.price_id,
//         price: selectedPackage.price,
//       },
//     });

//     fetch(`${ApiRoute.paymentIntent}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ` + token,
//       },

//       body: JSON.stringify({
//         paymentData: {
//           name: item.name,
//           _id: item._id,
//           price_id: selectedPackage.price_id,
//           price: selectedPackage.price,
//         },
//       }),
//     })
//       .then((res) => res.json())
//       .then((data) =>
//         this.setState({
//           subscription: data.subscription,
//           clientSecret: data.clientSecret,
//         })
//       );

//     this.setState({ isOpen: true });
//   };

//   componentDidUpdate(prevProps, prevState) {
//     if (
//       prevProps.subscriptionData === null &&
//       this.props.subscriptionData !== null
//     ) {
//       this.setState({ subscriptionData: this.props.subscriptionData });
//     }
//   }

//   changeDuration = (value) => {
//     value
//       ? this.setState({ subscriptionDuration: 'Annually' })
//       : this.setState({ subscriptionDuration: 'Monthly' });
//   };
//   openModal = () => this.setState({ isOpen: true });
//   closeModal = () => this.setState({ isOpen: false });

//   priceTitle = (packages, price_id) => {
//     let priceBtnTitle = 'Upgrade';

//     packages.map((price) => {
//       console.log(
//         'hello',
//         price.price_id,
//         this.state.priceId,
//         this.state.interval,
//         price.name.trim()
//       );
//       if (
//         price.price_id === this.state.priceId &&
//         this.state.interval === this.state.subscriptionDuration
//       ) {
//         priceBtnTitle = 'Current';
//       }
//     });
//     return priceBtnTitle;
//   };

//   render() {
//     const { subscriptionData } = this.state;

//     const { data, sub, position } = this.state;

//     const appearance = {
//       theme: 'stripe',
//     };
//     const options = {
//       clientSecret: this.state.clientSecret,
//       appearance,
//     };

//     console.log(
//       'SUBSCRIPTION DATA HERE',
//       subscriptionData,
//       this.state.subscriptionDuration
//     );

//     return (
//       <>
//         <Modal show={this.state.isOpen} onHide={this.closeModal}>
//           <div className="App">
//             {this.state.clientSecret && (
//               <Elements options={options} stripe={stripePromise}>
//                 <CheckoutForm
//                   paymentData={this.state.paymentData}
//                   subscription={this.state.subscription}
//                 />
//               </Elements>
//             )}
//           </div>
//           {/* </div> */}
//         </Modal>

//         {subscriptionData == null ? (
//           <div
//             className="col-lg-12"
//             style={{ display: 'flex', justifyContent: 'center' }}
//           >
//             <SkeletonCardSubscription />
//           </div>
//         ) : (
//           <>
//             {this.state.show ? (
//               <div className="row ">
//                 <div className="col-md-12 ">
//                   <div className="pricing-container margin-top-10 mx-0 text-center">
//                     <div className="col-lg-12 text-center">
//                       <h2>Upgrade Your Package</h2>

//                       <div className="row text-center subscription-duration">
//                         <label className="switch mx-auto d-flex">
//                           <label className="monthlylabel mt-3">Monthly</label>
//                           <input
//                             type="checkbox"
//                             onChange={(e) =>
//                               this.changeDuration(e.target.checked)
//                             }
//                           />
//                           <span className="slider round"></span>
//                           <label className=" annuallyylabel mt-3">
//                             Annually
//                           </label>
//                         </label>
//                       </div>
//                     </div>

//                     {subscriptionData.length > 0 ? (
//                       <>
//                         {subscriptionData.map((packagedata, pData) => {
//                           return (
//                             <div className="plan" key={pData}>
//                               {packagedata.name !== 'FREE Basic' ? (
//                                 <>
//                                   <div className="plan-price text-left">
//                                     <h5 className="fw-bold business">
//                                       {packagedata.business}
//                                     </h5>
//                                     <h1 className="fw-bold">
//                                       {packagedata.name}
//                                     </h1>
//                                     <span className="value fw-bold">
//                                       {packagedata.packages.map((price) => {
//                                         return price.name.trim() ===
//                                           this.state.subscriptionDuration
//                                           ? '$' + price.price
//                                           : null;
//                                       })}

//                                       <span className="fs-5 monthly text-left">
//                                         <span className="mx-2">/</span>{' '}
//                                         {this.state.subscriptionDuration}
//                                       </span>
//                                     </span>
//                                   </div>
//                                 </>
//                               ) : null}

//                               {packagedata.services.length > 0 &&
//                               packagedata.name !== 'FREE Basic' ? (
//                                 <div className="plan-features">
//                                   <h4 className="text-left mb-2 fw-bold">
//                                     What's Included
//                                   </h4>
//                                   <ul>
//                                     {packagedata.services.map(
//                                       (service, services) => {
//                                         return (
//                                           <li
//                                             className="text-left fw-bold"
//                                             key={services}
//                                           >
//                                             <img
//                                               className="mx-3"
//                                               style={{ width: '20px' }}
//                                               src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Eo_circle_yellow_checkmark.svg/2048px-Eo_circle_yellow_checkmark.svg.png"
//                                               alt="im"
//                                             />
//                                             {service.description}
//                                           </li>
//                                         );
//                                       }
//                                     )}
//                                   </ul>

//                                   <button
//                                     onClick={(e) =>
//                                       this.handleChange(e, packagedata)
//                                     }
//                                     className="button text-light sub-btn border w-100"
//                                   >
//                                     {this.priceTitle(
//                                       packagedata.packages,
//                                       this.state.priceId,
//                                       this.state.interval
//                                     )}
//                                   </button>
//                                 </div>
//                               ) : null}
//                             </div>
//                           );
//                         })}
//                       </>
//                     ) : null}
//                   </div>
//                 </div>
//               </div>
//             ) : (
//               <div>
//                 {this.state.userData?.isPaid === true ? (
//                   <>
//                     <div className="padding-30">
//                       <div className="mx-auto d-flex justify-content-center">
//                         <div className="col-lg-6  bg-light text-center  p-4  rounded-4 shadow-lg justify-content-center  mx-auto  ">
//                           <div className=" text-left  ">
//                             {data.amount / 100 === 500 ||
//                             data.amount / 100 === 49.99 ||
//                             this.state.userData.position === 0 ? (
//                               <h3 className="fw-bolder">
//                                 Current Plan:
//                                 <span style={{ marginLeft: '9rem' }}>
//                                   SELECT
//                                 </span>
//                               </h3>
//                             ) : data.amount / 100 === 300 ||
//                               data.amount / 100 === 29.99 ||
//                               this.state.userData.position === 1 ? (
//                               <h3 className="fw-bolder">
//                                 Current Plan:
//                                 <span style={{ marginLeft: '9rem' }}>
//                                   PREFERRED
//                                 </span>
//                               </h3>
//                             ) : (
//                               <h3 className="fw-bolder">
//                                 <span className="d-flex gap-3">
//                                   <Skeleton width={150} />
//                                   <Skeleton width={150} />
//                                 </span>
//                               </h3>
//                             )}

//                             {this.state.userData.override === true ? (
//                               <div></div>
//                             ) : (
//                               <div>
//                                 <h3 className="fw-bolder">
//                                   Payment Method{' '}
//                                   <span style={{ marginLeft: '6rem' }}>
//                                     Card
//                                   </span>
//                                 </h3>
//                                 <h3 className="fw-bolder">
//                                   Next Billing Date{' '}
//                                   <span style={{ marginLeft: '6rem' }}>
//                                     <Moment unix format="DD-MM-YYYY">
//                                       {sub.current_period_end}
//                                     </Moment>
//                                   </span>
//                                 </h3>
//                               </div>
//                             )}
//                           </div>
//                           {this.state.userData.override === true ? (
//                             <div></div>
//                           ) : (
//                             <div>
//                               <button
//                                 onClick={this.handleChangePlanClick}
//                                 className="contact bg-dark w-50 text-center justify-content-center  "
//                               >
//                                 Change Plan
//                               </button>
//                               <button
//                                 onClick={(e) => this.handleDelete(e)}
//                                 className="contact bg-dark w-50 text-center justify-content-center  "
//                               >
//                                 Cancel Plan
//                               </button>
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                       <div className="mx-auto d-flex mt-5 justify-content-center">
//                         <div className="col-lg-6  bg-light text-center  p-4  rounded-4 shadow-lg justify-content-center  mx-auto  ">
//                           {data.amount / 100 === 500 ||
//                           data.amount / 100 === 49.99 ||
//                           this.state.userData.position === 0 ? (
//                             <h2 className=" text-center fw-bolder lh-lg  ">
//                               <img
//                                 style={{ width: '30px' }}
//                                 src="https://www.beta.eventmassi.com/_next/static/media/gold-star-icon.846ea658.svg"
//                                 alt="im"
//                               ></img>
//                               <span>Select</span>
//                               <p>Perfect for Small Business</p>
//                             </h2>
//                           ) : data.amount / 100 === 300 ||
//                             data.amount / 100 === 29.99 ||
//                             this.state.userData.position === 1 ? (
//                             <h2 className=" text-center fw-bolder lh-lg  ">
//                               <img
//                                 style={{ width: '30px' }}
//                                 src="https://www.beta.eventmassi.com/_next/static/media/silver-star-icon.1d3735b4.svg"
//                                 alt="im"
//                               ></img>
//                               <span>Preferred</span>
//                               <p>For Large Event Businesses</p>
//                             </h2>
//                           ) : (
//                             <h2 className=" text-center fw-bolder lh-lg  ">
//                               <Skeleton width={150} />
//                               <Skeleton width={150} />
//                             </h2>
//                           )}

//                           {this.state.userData.override === true ? (
//                             <div></div>
//                           ) : (
//                             <h2 className=" text-center  fw-bolder lh-lg  ">
//                               <span
//                                 style={{ fontSize: '40px' }}
//                                 className=" money mx-1 "
//                               >
//                                 $
//                               </span>
//                               <span style={{ fontSize: '40px' }}>
//                                 {data.amount / 100}
//                               </span>
//                               <span className="mx-1">/</span>
//                               <span className="mx-1">{data.interval}</span>
//                             </h2>
//                           )}

//                           {data.amount / 100 === 500 ||
//                           data.amount / 100 === 49.99 ||
//                           this.state.userData.position === 0 ? (
//                             <div>
//                               {subscriptionData
//                                 .filter(
//                                   (selectPackageSelect) =>
//                                     selectPackageSelect.name === 'Massi Select'
//                                 )
//                                 .map((packagedata, pData) => {
//                                   return (
//                                     <div
//                                       className="mx-auto d-flex justify-content-center "
//                                       key={pData}
//                                     >
//                                       {packagedata.services.length > 0 && (
//                                         <div className="plan-feature">
//                                           <h4 className="text-left mb-2 fw-bold">
//                                             What's Included
//                                           </h4>
//                                           <ul className="fs-4 text-center lh-lg list-unstyled">
//                                             {packagedata.services.map(
//                                               (service, services) => {
//                                                 return (
//                                                   <li
//                                                     className="text-left fw-bold"
//                                                     key={services}
//                                                   >
//                                                     <img
//                                                       className="mx-3"
//                                                       style={{ width: '20px' }}
//                                                       src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Eo_circle_yellow_checkmark.svg/2048px-Eo_circle_yellow_checkmark.svg.png"
//                                                       alt="im"
//                                                     />
//                                                     {service.description}
//                                                   </li>
//                                                 );
//                                               }
//                                             )}
//                                           </ul>
//                                         </div>
//                                       )}
//                                     </div>
//                                   );
//                                 })}
//                             </div>
//                           ) : data.amount / 100 === 300 ||
//                             data.amount / 100 === 29.99 ||
//                             this.state.userData.position === 1 ? (
//                             <div>
//                               {subscriptionData
//                                 .filter(
//                                   (packagedata) =>
//                                     packagedata.name === 'Massi Preferred'
//                                 )
//                                 .map((packagedata, pData) => {
//                                   return (
//                                     <div
//                                       className="mx-auto d-flex justify-content-center "
//                                       key={pData}
//                                     >
//                                       {packagedata.services.length > 0 && (
//                                         <div className="plan-feature">
//                                           <h4 className="text-left mb-2 fw-bold">
//                                             What's Included
//                                           </h4>
//                                           <ul className="fs-4 text-center lh-lg list-unstyled">
//                                             {packagedata.services.map(
//                                               (service, services) => {
//                                                 return (
//                                                   <li
//                                                     className="text-left fw-bold"
//                                                     key={services}
//                                                   >
//                                                     <img
//                                                       className="mx-3"
//                                                       style={{ width: '20px' }}
//                                                       src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Eo_circle_yellow_checkmark.svg/2048px-Eo_circle_yellow_checkmark.svg.png"
//                                                       alt="im"
//                                                     />
//                                                     {service.description}
//                                                   </li>
//                                                 );
//                                               }
//                                             )}
//                                           </ul>
//                                         </div>
//                                       )}
//                                     </div>
//                                   );
//                                 })}
//                             </div>
//                           ) : (
//                             <>
//                               {' '}
//                               <Skeleton
//                                 width={100}
//                                 style={{ marginRight: '25rem' }}
//                               />
//                               <Skeleton
//                                 width={200}
//                                 height={15}
//                                 style={{ marginRight: '10rem' }}
//                               />
//                               <Skeleton
//                                 width={200}
//                                 height={15}
//                                 style={{ marginRight: '10rem' }}
//                               />
//                               <Skeleton
//                                 width={200}
//                                 height={15}
//                                 style={{ marginRight: '10rem' }}
//                               />
//                               <Skeleton
//                                 width={200}
//                                 height={15}
//                                 style={{ marginRight: '10rem' }}
//                               />
//                               <Skeleton
//                                 width={200}
//                                 height={15}
//                                 style={{ marginRight: '10rem' }}
//                               />
//                               <Skeleton
//                                 width={200}
//                                 height={15}
//                                 style={{ marginRight: '10rem' }}
//                               />
//                             </>
//                           )}
//                         </div>
//                       </div>
//                       <div className="container">
//                         <button
//                           onClick={() => (window.location.href = '/contact_us')}
//                           className="contact bg-dark"
//                         >
//                           Contact Us
//                         </button>
//                       </div>
//                     </div>
//                   </>
//                 ) : (
//                   <div>
//                     <div className="container margin-bottom-35">
//                       <div className="col-md-12">
//                         <div className="row padding-top-15">
//                           <div className="col-lg-12 text-center">
//                             <h2>Vendor Subscriptions</h2>
//                           </div>
//                         </div>
//                         <div className="row text-center subscription-duration">
//                           <label className="switch mx-auto d-flex">
//                             <label className="monthlylabel mt-3">Monthly</label>
//                             <input
//                               type="checkbox"
//                               onChange={(e) =>
//                                 this.changeDuration(e.target.checked)
//                               }
//                             />
//                             <span className="slider round"></span>
//                             <label className=" annuallyylabel mt-3">
//                               Annually
//                             </label>
//                           </label>
//                         </div>

//                         <div className="row">
//                           <div className="col-md-12 ">
//                             <div className="pricing-container margin-top-10">
//                               {subscriptionData.length > 0 ? (
//                                 <>
//                                   {subscriptionData.map(
//                                     (packagedata, pData) => {
//                                       return (
//                                         <div className="plan" key={pData}>
//                                           <div className="plan-price text-left">
//                                             <h5 className="fw-bold business">
//                                               {packagedata.business}
//                                             </h5>
//                                             <h1 className="fw-bold">
//                                               {packagedata.name}
//                                             </h1>
//                                             <span className="value fw-bold">
//                                               {packagedata.packages.map(
//                                                 (price) => {
//                                                   return packagedata.name ===
//                                                     'Free'
//                                                     ? 'Free'
//                                                     : price.name.trim() ===
//                                                       this.state
//                                                         .subscriptionDuration
//                                                     ? '$' + price.price
//                                                     : null;
//                                                 }
//                                               )}
//                                               <span className="fs-5 monthly text-left">
//                                                 <span className="mx-2  ">
//                                                   /
//                                                 </span>{' '}
//                                                 {packagedata.name === 'Basic'
//                                                   ? null
//                                                   : this.state
//                                                       .subscriptionDuration}
//                                               </span>
//                                             </span>
//                                           </div>

//                                           {packagedata.services.length > 0 ? (
//                                             <div className="plan-features">
//                                               <h4 className="text-left mb-2  fw-bold">
//                                                 What's Included
//                                               </h4>

//                                               <ul>
//                                                 {packagedata.services.map(
//                                                   (service, services) => {
//                                                     return (
//                                                       <li
//                                                         className="text-left  fw-bold"
//                                                         key={services}
//                                                       >
//                                                         <img
//                                                           className="mx-3"
//                                                           style={{
//                                                             width: '20px',
//                                                           }}
//                                                           src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Eo_circle_yellow_checkmark.svg/2048px-Eo_circle_yellow_checkmark.svg.png"
//                                                           alt="im"
//                                                         ></img>
//                                                         {service.description}
//                                                       </li>
//                                                     );
//                                                   }
//                                                 )}
//                                               </ul>

//                                               {packagedata.name ===
//                                               'FREE Basic' ? (
//                                                 <button
//                                                   className="button bg-dark text-light sub-btn border w-100"
//                                                   // href="#!"
//                                                 >
//                                                   Current
//                                                 </button>
//                                               ) : (
//                                                 <button
//                                                   onClick={(e) =>
//                                                     this.handleSubmit(
//                                                       e,
//                                                       packagedata
//                                                     )
//                                                   }
//                                                   className="button text-light sub-btn border w-100"
//                                                   // href="#!"
//                                                 >
//                                                   Buy Now
//                                                 </button>
//                                               )}
//                                             </div>
//                                           ) : null}
//                                         </div>
//                                       );
//                                     }
//                                   )}
//                                 </>
//                               ) : null}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             )}
//           </>
//         )}
//       </>
//     );
//   }
// }

// export default VendorDashboardSubscription;

import React from "react";
import "./Stripe.css";
import { Modal } from "react-bootstrap";
import AuthService from "../services/AuthService";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Swal from "sweetalert2";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ApiRoute } from "../util/constants";
import GoldStartIcon from "../assets/images/goldStarIcon.svg";
import SilverStartIcon from "../assets/images/silverStarIcon.svg";
import SkeletonCardSubscription from "./Skeltons/SkeletonCardSubscription";
// const stripePromise = loadStripe(
//   'pk_test_51HEoMOL4WzBwsDIqhzDcfOc4qDatQDXuhp28dcZGXskdCCHC7iQmCZyhTGti4ztAGmN2qAARkZkRgfwCYgUb4Krh00Os0E6Dfa'
// );
const stripePromise = loadStripe(
  "pk_live_51HEoMOL4WzBwsDIq2fkWLAAglKvGVE7fPBWY9vw0lwJjzbQbyVLHtc9G7ZxBltuFPYqKjVxuSRakS6XeR9ruWl2N00wKlI1mKa"
);
class VendorDashboardSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionData: this.props.subscriptionData,
      data: [],
      subscriptionDuration: "Monthly",
      paymentData: {},
      updateData: {},
      subscriptionId: {},
      paymentId: {},
      priceId: {},
      sub: {},
      position: {},
      subscription: null,
      interval: {},

      isOpen: false,
      clientSecret: "",
      userData: localStorage.getItem("userData")
        ? JSON.parse(atob(localStorage.getItem("userData")))
        : null,
      isPaid: localStorage.getItem("isPaid"),

      show: false,
    };
  }

  handleChangePlanClick = () => {
    this.setState({ show: true });
    console.log("Interval of vendor", this.state.interval);
    console.log("Price ID of vendor", this.state.priceId);
  };

  vendorData = () => {
    let token = AuthService.getToken();
    fetch(`${ApiRoute.getLoginVendorProfile}`, {
      headers: {
        Authorization: `Bearer ` + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        let interval = "Monthly";
        if (json.payment?.interval === "year") {
          interval = "Anually";
        }
        console.log("data", json);
        this.setState({
          data: json.payment?.subscription?.plan,
          interval: interval,
          priceId: json.payment?.subscription?.plan?.id,
          subscriptionId: json.payment?.subscription?.id,
          paymentId: json.payment?._id,
          position: json.data?.position,
          sub: json.payment?.subscription,
        });
      });
  };

  componentDidMount() {
    this.vendorData();
    console.log("SUBSCRIPTION", this.props.subscriptionData);
  }

  handleDelete = async (event) => {
    let token = AuthService.getToken();

    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to cancel Your Subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    });

    if (confirmation.value) {
      const response = await fetch(`${ApiRoute.cancelSubscription}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ` + token,
        },
        body: JSON.stringify({
          _id: this.state.paymentId,
        }),
      });

      const result = await response.json();
      console.log(result);

      if (response.status === 200) {
        Swal.fire({
          title: "Subscription Cancelled",
          text: "Your subscription  has been cancelled successfully.",
          icon: "success",
          showConfirmButton: false,
        });
        let userData = localStorage.getItem("userData")
          ? JSON.parse(atob(localStorage.getItem("userData")))
          : null;
        if (userData !== null) {
          userData.isPaid = false;

          localStorage.setItem("userData", btoa(JSON.stringify(userData)));
        }
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        Swal.fire({
          title: "Error",
          text: "An error occurred while changing the subscription package.",
          icon: "error",
        });
      }

      return result;
    }
  };

  handleChange = async (event, item) => {
    let token = AuthService.getToken();
    let selectedPackage = null;
    let allpackages = item.packages;
    for (let i = 0; i < allpackages.length; i++) {
      if (allpackages[i].name.trim() === this.state.subscriptionDuration) {
        selectedPackage = allpackages[i];
      }
    }
    console.log("SELECTED", selectedPackage);
    this.setState(
      {
        updateData: selectedPackage.price_id,
      },
      async () => {
        const confirmation = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to change your subscription package?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, change it!",
        });

        if (confirmation.value) {
          console.log(this.state.updateData);

          const response = await fetch(`${ApiRoute.updateSubscription}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",

              Authorization: `Bearer ` + token,
            },
            body: JSON.stringify({
              price_id: this.state.updateData,
              subscription_id: this.state.subscriptionId,
              _id: this.state.paymentId,
            }),
          });

          const result = await response.json();
          console.log(result);

          if (response.status === 200) {
            Swal.fire({
              title: "Package Changed",
              text: "Your subscription package has been changed successfully.",
              icon: "success",
            });
            this.setState({ show: false });
            this.vendorData();
            // window.location.href = '/dashboard';
          } else {
            Swal.fire({
              title: "Error",
              text: "An error occurred while changing the subscription package.",
              icon: "error",
            });
          }

          return result;
        }
      }
    );
  };

  handleSubmit = async (event, item) => {
    let token = AuthService.getToken();
    event.preventDefault();
    // console.log('Item is', item);
    let selectedPackage = null;
    let allpackages = item.packages;
    for (let i = 0; i < allpackages.length; i++) {
      if (allpackages[i].name.trim() === this.state.subscriptionDuration) {
        selectedPackage = allpackages[i];
      }
    }
    console.log(selectedPackage);
    this.setState({
      paymentData: {
        name: item.name,
        _id: item._id,
        price_id: selectedPackage.price_id,
        price: selectedPackage.price,
      },
    });

    fetch(`${ApiRoute.paymentIntent}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },

      body: JSON.stringify({
        paymentData: {
          name: item.name,
          _id: item._id,
          price_id: selectedPackage.price_id,
          price: selectedPackage.price,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          subscription: data.subscription,
          clientSecret: data.clientSecret,
        })
      );

    this.setState({ isOpen: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.subscriptionData === null &&
      this.props.subscriptionData !== null
    ) {
      this.setState({ subscriptionData: this.props.subscriptionData });
    }
  }

  changeDuration = (value) => {
    value
      ? this.setState({ subscriptionDuration: "Annually" })
      : this.setState({ subscriptionDuration: "Monthly" });
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  priceTitle = (packages, price_id) => {
    let priceBtnTitle = "Upgrade";

    packages.map((price) => {
      console.log(
        "hello",
        price.price_id,
        this.state.priceId,
        this.state.interval,
        price.name.trim()
      );
      if (
        price.price_id === this.state.priceId &&
        this.state.interval === this.state.subscriptionDuration
      ) {
        priceBtnTitle = "Current";
      }
    });
    return priceBtnTitle;
  };

  render() {
    const { subscriptionData } = this.state;

    const { data, sub, position } = this.state;

    const appearance = {
      theme: "stripe",
    };
    const options = {
      clientSecret: this.state.clientSecret,
      appearance,
    };

    console.log(
      "SUBSCRIPTION DATA HERE",
      subscriptionData,
      this.state.subscriptionDuration
    );

    return (
      <>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <div className="App">
            {this.state.clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  paymentData={this.state.paymentData}
                  subscription={this.state.subscription}
                />
              </Elements>
            )}
          </div>
          {/* </div> */}
        </Modal>

        {subscriptionData == null ? (
          <div
            className="col-lg-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <SkeletonCardSubscription />
          </div>
        ) : (
          <>
            {this.state.show ? (
              <div className="row ">
                <div className="col-md-12 ">
                  <div className="pricing-container margin-top-10 mx-0 text-center">
                    <div className="col-lg-12 text-center">
                      <h2>Upgrade Your Package</h2>

                      <div className="row text-center subscription-duration">
                        <label className="switch mx-auto d-flex">
                          <label className="monthlylabel mt-3">Monthly</label>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              this.changeDuration(e.target.checked)
                            }
                          />
                          <span className="slider round"></span>
                          <label className=" annuallyylabel mt-3">
                            Annually
                          </label>
                        </label>
                      </div>
                    </div>

                    {subscriptionData.length > 0 ? (
                      <>
                        {subscriptionData.map((packagedata, pData) => {
                          return (
                            <div className="plan" key={pData}>
                              {packagedata.name !== "FREE Basic" ? (
                                <>
                                  <div className="plan-price text-left">
                                    <h5 className="fw-bold business">
                                      {packagedata.business}
                                    </h5>
                                    <h1 className="fw-bold">
                                      {packagedata.name}
                                    </h1>
                                    <span className="value fw-bold">
                                      {packagedata.packages.map((price) => {
                                        return price.name.trim() ===
                                          this.state.subscriptionDuration
                                          ? "$" + price.price
                                          : null;
                                      })}

                                      <span className="fs-5 monthly text-left">
                                        <span className="mx-2">/</span>{" "}
                                        {this.state.subscriptionDuration}
                                      </span>
                                    </span>
                                  </div>
                                </>
                              ) : null}

                              {packagedata.services.length > 0 &&
                              packagedata.name !== "FREE Basic" ? (
                                <div className="plan-features">
                                  <h4 className="text-left mb-2 fw-bold">
                                    What's Included
                                  </h4>
                                  <ul>
                                    {packagedata.services.map(
                                      (service, services) => {
                                        return (
                                          <li
                                            className="text-left fw-bold"
                                            key={services}
                                          >
                                            <img
                                              className="mx-3"
                                              style={{ width: "20px" }}
                                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Eo_circle_yellow_checkmark.svg/2048px-Eo_circle_yellow_checkmark.svg.png"
                                              alt="im"
                                            />
                                            {service.description}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>

                                  <button
                                    onClick={(e) =>
                                      this.handleChange(e, packagedata)
                                    }
                                    className="button text-light sub-btn border w-100"
                                  >
                                    {this.priceTitle(
                                      packagedata.packages,
                                      this.state.priceId,
                                      this.state.interval
                                    )}
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {this.state.userData?.isPaid === true ? (
                  <>
                    <div className="padding-30">
                      <div className="mx-auto d-flex justify-content-center">
                        <div className="col-lg-6  bg-light text-center  p-4  rounded-4 shadow-lg justify-content-center  mx-auto  ">
                          <div className=" text-left  ">
                            {(data?.amount &&
                              (data.amount / 100 === 500 ||
                                data.amount / 100 === 49.99)) ||
                            this.state.userData.position === 0 ? (
                              <h3 className="fw-bolder">
                                Current Plan:
                                <span style={{ marginLeft: "9rem" }}>
                                  SELECT
                                </span>
                              </h3>
                            ) : (data?.amount &&
                                (data.amount / 100 === 300 ||
                                  data.amount / 100 === 29.99)) ||
                              this.state.userData.position === 1 ? (
                              <h3 className="fw-bolder">
                                Current Plan:
                                <span style={{ marginLeft: "9rem" }}>
                                  PREFERRED
                                </span>
                              </h3>
                            ) : (
                              <h3 className="fw-bolder">
                                <span className="d-flex gap-3">
                                  <Skeleton width={150} />
                                  <Skeleton width={150} />
                                </span>
                              </h3>
                            )}

                            {this.state.userData.override === true ? (
                              <div>
                                <h3 className="fw-bolder">Manually Override</h3>
                              </div>
                            ) : (
                              <div>
                                <h3 className="fw-bolder">
                                  Payment Method{" "}
                                  <span style={{ marginLeft: "6rem" }}>
                                    Card
                                  </span>
                                </h3>
                                <h3 className="fw-bolder">
                                  Next Billing Date{" "}
                                  <span style={{ marginLeft: "6rem" }}>
                                    <Moment unix format="DD-MM-YYYY">
                                      {sub?.current_period_end}
                                    </Moment>
                                  </span>
                                </h3>
                              </div>
                            )}
                          </div>
                          {this.state.userData?.override === true ? (
                            <div></div>
                          ) : (
                            <div>
                              <button
                                onClick={this.handleChangePlanClick}
                                className="contact bg-dark w-50 text-center justify-content-center  "
                              >
                                Change Plan
                              </button>
                              <button
                                onClick={(e) => this.handleDelete(e)}
                                className="contact bg-dark w-50 text-center justify-content-center  "
                              >
                                Cancel Plan
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mx-auto d-flex mt-5 justify-content-center">
                        <div className="col-lg-6  bg-light text-center  p-4  rounded-4 shadow-lg justify-content-center  mx-auto  ">
                          {(data?.amount &&
                            (data.amount / 100 === 500 ||
                              data.amount / 100 === 49.99)) ||
                          this.state.userData.position === 0 ? (
                            <h2 className=" text-center fw-bolder lh-lg  ">
                              <img
                                style={{ width: "30px" }}
                                src={GoldStartIcon}
                                alt="im"
                              ></img>
                              <span>Select</span>
                              <p>Perfect for Small Business</p>
                            </h2>
                          ) : (data?.amount &&
                              (data.amount / 100 === 300 ||
                                data.amount / 100 === 29.99)) ||
                            this.state.userData.position === 1 ? (
                            <h2 className=" text-center fw-bolder lh-lg  ">
                              <img
                                style={{ width: "30px" }}
                                src={SilverStartIcon}
                                alt="im"
                              ></img>
                              <span>Preferred</span>
                              <p>For Large Event Businesses</p>
                            </h2>
                          ) : (
                            <h2 className=" text-center fw-bolder lh-lg  ">
                              <Skeleton width={150} />
                              <Skeleton width={150} />
                            </h2>
                          )}

                          {this.state.userData?.override === true ? (
                            <div></div>
                          ) : (
                            <h2 className=" text-center  fw-bolder lh-lg  ">
                              <span
                                style={{ fontSize: "40px" }}
                                className=" money mx-1 "
                              >
                                $
                              </span>
                              <span style={{ fontSize: "40px" }}>
                                {data && data.amount ? data.amount / 100 : null}
                              </span>
                              <span className="mx-1">/</span>
                              <span className="mx-1">{data.interval}</span>
                            </h2>
                          )}

                          {(data?.amount &&
                            (data.amount / 100 === 500 ||
                              data.amount / 100 === 49.99)) ||
                          this.state.userData.position === 0 ? (
                            <div>
                              {subscriptionData
                                .filter(
                                  (selectPackageSelect) =>
                                    selectPackageSelect.name === "Massi Select"
                                )
                                .map((packagedata, pData) => {
                                  return (
                                    <div
                                      className="mx-auto d-flex justify-content-center "
                                      key={pData}
                                    >
                                      {packagedata.services.length > 0 && (
                                        <div className="plan-feature">
                                          <h4 className="text-left mb-2 fw-bold">
                                            What's Included
                                          </h4>
                                          <ul className="fs-4 text-center lh-lg list-unstyled">
                                            {packagedata.services.map(
                                              (service, services) => {
                                                return (
                                                  <li
                                                    className="text-left fw-bold"
                                                    key={services}
                                                  >
                                                    <img
                                                      className="mx-3"
                                                      style={{ width: "20px" }}
                                                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Eo_circle_yellow_checkmark.svg/2048px-Eo_circle_yellow_checkmark.svg.png"
                                                      alt="im"
                                                    />
                                                    {service.description}
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                            </div>
                          ) : (data?.amount &&
                              (data.amount / 100 === 300 ||
                                data.amount / 100 === 29.99)) ||
                            this.state.userData.position === 1 ? (
                            <div>
                              {subscriptionData
                                .filter(
                                  (packagedata) =>
                                    packagedata.name === "Massi Preferred"
                                )
                                .map((packagedata, pData) => {
                                  return (
                                    <div
                                      className="mx-auto d-flex justify-content-center "
                                      key={pData}
                                    >
                                      {packagedata.services.length > 0 && (
                                        <div className="plan-feature">
                                          <h4 className="text-left mb-2 fw-bold">
                                            What's Included
                                          </h4>
                                          <ul className="fs-4 text-center lh-lg list-unstyled">
                                            {packagedata.services.map(
                                              (service, services) => {
                                                return (
                                                  <li
                                                    className="text-left fw-bold"
                                                    key={services}
                                                  >
                                                    <img
                                                      className="mx-3"
                                                      style={{ width: "20px" }}
                                                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Eo_circle_yellow_checkmark.svg/2048px-Eo_circle_yellow_checkmark.svg.png"
                                                      alt="im"
                                                    />
                                                    {service.description}
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                            </div>
                          ) : (
                            <>
                              {" "}
                              <Skeleton
                                width={100}
                                style={{ marginRight: "25rem" }}
                              />
                              <Skeleton
                                width={200}
                                height={15}
                                style={{ marginRight: "10rem" }}
                              />
                              <Skeleton
                                width={200}
                                height={15}
                                style={{ marginRight: "10rem" }}
                              />
                              <Skeleton
                                width={200}
                                height={15}
                                style={{ marginRight: "10rem" }}
                              />
                              <Skeleton
                                width={200}
                                height={15}
                                style={{ marginRight: "10rem" }}
                              />
                              <Skeleton
                                width={200}
                                height={15}
                                style={{ marginRight: "10rem" }}
                              />
                              <Skeleton
                                width={200}
                                height={15}
                                style={{ marginRight: "10rem" }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="container">
                        <button
                          onClick={() => (window.location.href = "/contact_us")}
                          className="contact bg-dark"
                        >
                          Contact Us
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <div className="container margin-bottom-35">
                      <div className="col-md-12">
                        <div className="row padding-top-15">
                          <div className="col-lg-12 text-center">
                            <h2>Vendor Subscriptions</h2>
                          </div>
                        </div>
                        <div className="row text-center subscription-duration">
                          <label className="switch mx-auto d-flex">
                            <label className="monthlylabel mt-3">Monthly</label>
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                this.changeDuration(e.target.checked)
                              }
                            />
                            <span className="slider round"></span>
                            <label className=" annuallyylabel mt-3">
                              Annually
                            </label>
                          </label>
                        </div>

                        <div className="row">
                          <div className="col-md-12 ">
                            <div className="pricing-container margin-top-10">
                              {subscriptionData.length > 0 ? (
                                <>
                                  {subscriptionData.map(
                                    (packagedata, pData) => {
                                      return (
                                        <div className="plan" key={pData}>
                                          <div className="plan-price text-left">
                                            <h5 className="fw-bold business">
                                              {packagedata.business}
                                            </h5>
                                            <h1 className="fw-bold">
                                              {packagedata.name}
                                            </h1>
                                            <span className="value fw-bold">
                                              {packagedata.packages.map(
                                                (price) => {
                                                  return packagedata.name ===
                                                    "Free"
                                                    ? "Free"
                                                    : price.name.trim() ===
                                                      this.state
                                                        .subscriptionDuration
                                                    ? "$" + price.price
                                                    : null;
                                                }
                                              )}
                                              <span className="fs-5 monthly text-left">
                                                <span className="mx-2  ">
                                                  /
                                                </span>{" "}
                                                {packagedata.name === "Basic"
                                                  ? null
                                                  : this.state
                                                      .subscriptionDuration}
                                              </span>
                                            </span>
                                          </div>

                                          {packagedata.services.length > 0 ? (
                                            <div className="plan-features">
                                              <h4 className="text-left mb-2  fw-bold">
                                                What's Included
                                              </h4>

                                              <ul>
                                                {packagedata.services.map(
                                                  (service, services) => {
                                                    return (
                                                      <li
                                                        className="text-left  fw-bold"
                                                        key={services}
                                                      >
                                                        <img
                                                          className="mx-3"
                                                          style={{
                                                            width: "20px",
                                                          }}
                                                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Eo_circle_yellow_checkmark.svg/2048px-Eo_circle_yellow_checkmark.svg.png"
                                                          alt="im"
                                                        ></img>
                                                        {service.description}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>

                                              {packagedata.name ===
                                              "FREE Basic" ? (
                                                <button
                                                  className="button bg-dark text-light sub-btn border w-100"
                                                  // href="#!"
                                                >
                                                  Current
                                                </button>
                                              ) : (
                                                <button
                                                  onClick={(e) =>
                                                    this.handleSubmit(
                                                      e,
                                                      packagedata
                                                    )
                                                  }
                                                  className="button text-light sub-btn border w-100"
                                                  // href="#!"
                                                >
                                                  Buy Now
                                                </button>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default VendorDashboardSubscription;
