import React from "react";
import { URLS, Local_routes } from "../util/constants";
import SocialShareVendor from "./SocialShareVendor";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SkeletonCardHomeVendorListSection from "./Skeltons/SkeletonCardHomeVendorListSection";
import ContactVendorFormModal from "./ContactVendorFormModal";
import "./home.css";
import MassiTopPicks from "../assets/images/Vector.svg";
let navbar;
let sticky;
class HomeVendorListSection extends React.Component {
  state = {
    showContactForm: false,
    vendorProfile: null,
    selectedVendor: null,
    showShareModal: false,
  };
  closeBtn2 = () => (
    <i
      className="fa fa-close cursor-pointer fs-2"
      size={25}
      onClick={() => this.setState({ showShareModal: false })}
    />
  );
  htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.vendorProfile !== null) {
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleSticky);
  }
  closeBtn = () => (
    <i
      className="fa fa-close cursor-pointer fs-1 text-dark"
      size={25}
      onClick={() => this.setState({ showContactForm: false })}
    />
  );
  handleSticky = () => {
    console.log(window.scrollY, sticky);
    if (window.scrollY > sticky) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  };
  render() {
    const { vendors } = this.props;

    return (
      <div>
        <div className="homeTopPicksWrap">
          <div className="container">
            <div className="topPicksHeading">
              <LazyLoadImage
                className="img "
                width={500}
                height={100}
                src={MassiTopPicks}
                alt="img"
              />
            </div>

            {!vendors.success ? (
              <>
                <SkeletonCardHomeVendorListSection />
              </>
            ) : (
              vendors?.data?.map((vendor) => {
                return (
                  <div
                    className="topPicksBoxWrap "
                    key={`${vendor.profile_url}`}
                  >
                    <div className="topPicksContentWrap ">
                      <div className="topPickPrdImg ">
                        <a
                          target="_self"
                          rel="noreferrer"
                          href={
                            Local_routes.vendor_detail +
                            "/" +
                            vendor.profile_url
                          }
                        >
                          <LazyLoadImage
                            effect="blur"
                            src={`${URLS.S3BucketURL + vendor.pic_path}`}
                            alt="Vendor Pic"
                            className=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "./images/default.jpeg";
                            }}
                          />
                        </a>
                      </div>

                      <div className="topPickPrdContent col-6">
                        <div className="cursor-pointers ">
                          <a
                            target="_self"
                            rel="noreferrer"
                            href={
                              Local_routes.vendor_detail +
                              "/" +
                              vendor.profile_url
                            }
                          >
                            <h1 className="vendor ">{vendor.business}</h1>
                          </a>
                          <span className="vendorCat">
                            <div className="selectedCat">
                              <span className="selectStateName">
                                {vendor.states.length > 0 ? (
                                  <span>{vendor.states[0].name}</span>
                                ) : null}
                                <div className="rating-counter1 ">
                                  <span className="text-dark">
                                    {vendor.costly === "1D"
                                      ? "$"
                                      : vendor.costly === "2D"
                                      ? "$$"
                                      : vendor.costly === "3D"
                                      ? "$$$"
                                      : null}
                                  </span>
                                </div>
                              </span>
                            </div>
                          </span>

                          <div className="topPickBoxBtnsWrap">
                            <div className="btn-container">
                              <button className="contactBtn">
                                <a
                                  href={`tel:${vendor.phone}`}
                                  className="phone"
                                >
                                  <LazyLoadImage
                                    src="../../images/call.svg"
                                    alt="Call"
                                  />
                                  {/* <span className=" mx-3  text-dark">Call Now</span> */}
                                </a>
                              </button>
                              <div className="vendorMetaWrapHomeListVendor d-flex">
                                <button
                                  className="vendorMetaBoxWrap border border-0 bg-dark"
                                  onClick={() =>
                                    this.setState({
                                      showContactForm: true,
                                      vendorProfile: vendor,
                                    })
                                  }
                                  // data-tip={vendor.email}
                                  // href={`mailto:${vendor.email}?cc=support@eventmassi.com`}
                                >
                                  {/* <LazyLoadImage
                                    src="../../images/share.svg"
                                    alt="Share"
                                    width={23}
                                    height={23}
                                  /> */}
                                  <LazyLoadImage
                                    src="../../images/sms.svg"
                                    alt="Share"
                                    width={23}
                                    height={23}
                                  />
                                </button>
                                <a
                                  href="#!"
                                  className="vendorMetaBoxWrap border border-1 bg-white"
                                  onClick={() =>
                                    this.setState({
                                      showShareModal: true,
                                      selectedVendor: vendor,
                                    })
                                  }
                                >
                                  <LazyLoadImage
                                    src="../../images/share.svg"
                                    alt="Mail"
                                    width={23}
                                    height={23}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <SocialShareVendor
            showShareModal={this.state.showShareModal}
            closeBtn={this.closeBtn2()}
            title={this.state.selectedVendor?.business}
            // url={window.location.href}
            // url={`${window.location.origin}${Local_routes.vendor_detail}/${btoa(
            //   this.state.preferredVendor?.id
            // )}`}
            url={
              "https://www.eventmassi.com" +
              Local_routes.vendor_detail +
              "/" +
              this.state.selectedVendor?.profile_url
              // btoa(this.state.selectedVendor?.id)
            }
            image={URLS.S3BucketURL + this.state.selectedVendor?.pic_path}
            description={this.state.selectedVendor?.description}
            keywords={this.state.selectedVendor?.keywords}
            email={this.state.selectedVendor?.email}
          />
          <ContactVendorFormModal
            showContactForm={this.state.showContactForm}
            closeBtn={this.closeBtn()}
            vendorProfile={this.state.vendorProfile}
            closeModal={() => this.setState({ showContactForm: false })}
          />
        </div>
      </div>
    );
  }
}

export default HomeVendorListSection;
