import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  LinkedinIcon,
  PinterestIcon,
  FacebookMessengerIcon,
  WhatsappIcon,
  TwitterIcon,
} from 'react-share';

class SocialShareVendor extends React.Component {
  state = {};
  render() {
    const {
      url,
      title,
      description,
      image,
      keywords,
      showShareModal,
      closeBtn,
      email,
    } = this.props;

    return (
      <>
        {/* Share popup modal */}
        <Modal
          isOpen={showShareModal}
          className="sidebar-lg"
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader className="mb-1" tag="div" close={closeBtn}>
            <h4>Share with others</h4>
          </ModalHeader>
          <ModalBody className="text-center padding-top-20 padding-bottom-20">
            <FacebookShareButton
              url={url}
              title={title}
              image={image}
              description={description}
              hashtags={keywords}
              data-tip={'Facebook'}
              className="margin-right-5"
            >
              <FacebookIcon size={62} round />
            </FacebookShareButton>
            <TwitterShareButton
              title={title}
              url={url}
              image={image}
              // hashtags={keywords}
              data-tip={'Twitter'}
              className="margin-right-5"
            >
              <TwitterIcon size={62} round />
            </TwitterShareButton>
            <LinkedinShareButton
              title={title}
              url={url}
              image={image}
              data-tip={'Linkedin'}
              className="margin-right-5"
            >
              <LinkedinIcon size={62} round />
            </LinkedinShareButton>
            <PinterestShareButton
              url={url}
              media={image}
              data-tip={'Pinterest'}
              className="margin-right-5"
            >
              <PinterestIcon size={62} round />
            </PinterestShareButton>
            <WhatsappShareButton
              url={url}
              title={title}
              image={image}
              description={description}
              windowWidth={660}
              windowHeight={460}
              data-tip={'WhatsApp'}
              className="margin-right-5"
            >
              <WhatsappIcon size={62} round />
            </WhatsappShareButton>
            <FacebookMessengerShareButton
              url={url}
              title={title}
              image={image}
              description={description}
              data-tip={'FacebookMessenger'}
              className="margin-right-5"
            >
              <FacebookMessengerIcon size={62} round />
            </FacebookMessengerShareButton>

            <TelegramShareButton
              url={url}
              title={title}
              data-tip={'Telegram'}
              className="margin-right-5"
            >
              <TelegramIcon size={62} round />
            </TelegramShareButton>
            <EmailShareButton
              url={url}
              subject={title}
              // body={description}
              data-tip={'Email'}
              className="margin-right-5"
            >
              <EmailIcon size={62} round />
            </EmailShareButton>
          </ModalBody>
          <ReactTooltip place={'bottom'} />
        </Modal>
      </>
    );
  }
}

export default SocialShareVendor;
