/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { auth, google, facebook, apple } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import { ToastContent } from '../components/Toast';
import AuthService from '../services/AuthService';
import ReCAPTCHA from 'react-google-recaptcha';
import { ApiRoute } from '../util/constants';

class SignupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signinTab: false,
      signupTab: true,
      loading: false,
      recaptchaToken: [],
    };
  }
  fetchData = () => {
    this.setState({ loading: true });

    //Faking API call here
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };
  signUpWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, google);
      const user = res.user;
      console.log('====================================');
      console.log('user', user);
      console.log('====================================');
      await axios
        .post(`${ApiRoute.register}`, {
          email: user.email,
          provider: 'google',
        })
        .then((response) => {
          // console.log('checking token in response ', response.data.token);

          if (response.status === 200) {
            toast(() => ToastContent('Successfully Signed Up'), {
              toastId: 'successToast',
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
            localStorage.setItem('userToken', btoa(response.data.token));
            localStorage.setItem('userData', btoa(JSON.stringify(response)));
            this.props.loginModalClose(1);
          } else {
            toast(() => ToastContent('USER ALREADY EXIST'), {
              toastId: 'dangerToast',
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            });
          }
        })
        .catch((e) => {
          toast(() => ToastContent(e?.response?.data?.message), {
            toastId: 'dangerToast',
            hideProgressBar: false,
            type: toast.TYPE.ERROR,
            autoClose: 1000,
          });
        });
    } catch (err) {
      // CATCH ERROR SAYING SIGNUP POPUP CLOSED
      toast(() => ToastContent(err.message), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };
  signUpWithFacebook = async () => {
    try {
      const res = await signInWithPopup(auth, facebook);
      const user = res.user;
      console.log('====================================');
      console.log('user', user);
      console.log('====================================');
      await axios
        .post(`${ApiRoute.register}`, {
          email: user.email,
          provider: 'facebook',
        })
        .then((response) => {
          // console.log('checking token in response ', response.data.token);

          if (response.status === 200) {
            toast(() => ToastContent('Successfully Signed Up'), {
              toastId: 'successToast',
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
            localStorage.setItem('userToken', btoa(response.data.token));
            localStorage.setItem('userData', btoa(JSON.stringify(response)));
            this.props.loginModalClose(1);
          }
          if (response.status === 400) {
            toast(() => ToastContent('USER ALREADY EXIST'), {
              toastId: 'dangerToast',
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            });
          }
        });
    } catch (err) {
      // CATCH ERROR SAYING SIGNUP POPUP CLOSED
      toast(() => ToastContent(err.message), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };
  signUpWithApple = async () => {
    try {
      const res = await signInWithPopup(auth, apple);
      const user = res.user;
      console.log('====================================');
      console.log('user', user);
      console.log('====================================');
      await axios
        .post(`${ApiRoute.register}`, {
          email: user.email,
          provider: 'apple',
        })
        .then((response, err) => {
          // console.log('checking token in response ', response.data.token);

          if (response.status === 200) {
            toast(() => ToastContent('Successfully Signed Up'), {
              toastId: 'successToast',
              hideProgressBar: false,
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
            localStorage.setItem('userToken', btoa(response.data.token));
            localStorage.setItem('userData', btoa(JSON.stringify(response)));
            this.props.loginModalClose(1);
          }
          if (response.status === 400) {
            toast(() => ToastContent(err.message), {
              toastId: 'dangerToast',
              hideProgressBar: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
            });
          }
        })
        .catch((e) => {
          toast(() => ToastContent(e?.response?.data?.message), {
            toastId: 'dangerToast',
            hideProgressBar: false,
            type: toast.TYPE.ERROR,
            autoClose: 1000,
          });
        });
    } catch (err) {
      // CATCH ERROR SAYING SIGNUP POPUP CLOSED
      toast(() => ToastContent(err.message), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  submitRegisterForm = async (event) => {
    toast.dismiss();
    event.preventDefault();
    var email = event.target.elements.registeremail.value;
    var password = event.target.elements.registerpassword.value;
    // var repeatpassword = event.target.elements.repeatpassword.value;
    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (!validateEmail) {
      toast(() => ToastContent('Please enter valid email address'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }

    if (!password) {
      toast(() => ToastContent('Please enter Password'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }
    if (password.length < 6) {
      toast(
        () => ToastContent('Password Should not be Shorter than 6 letters'),
        {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1500,
        }
      );
      return;
    }

    if (!this.state.recaptchaToken || this.state.recaptchaToken.length === 0) {
      toast(() => ToastContent('Please solve the reCAPTCHA'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }
    if (validateEmail && password && this.state.recaptchaToken) {
      toast(() => ToastContent('Please wait....'), {
        toastId: 'infoToast',
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
      let loginData = await AuthService.register(
        email,
        password,
        this.state.recaptchaToken
      );
      toast.dismiss();
      if (loginData.success) {
        toast(() => ToastContent('Successfully Registered'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        await localStorage.setItem('userToken', btoa(loginData.token));
        await localStorage.setItem(
          'userData',
          btoa(JSON.stringify(loginData.user))
        );
        localStorage.removeItem('_grecaptcha');
        this.setState({ recaptchaToken: null });
        this.props.loginModalClose(1);
      } else {
        toast(() => ToastContent(loginData.message), {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    }
  };

  handleRecaptchaChange = (token) => {
    this.setState({ recaptchaToken: token });
  };

  handleRecaptchaExpire = () => {
    this.setState({ recaptchaToken: null });
  };
  componentDidMount() {}
  render() {
    const { loading } = this.state;
    return (
      <>
        <ToastContainer />
        <Modal
          isOpen={this.props.signinupModal}
          id="sign-in-dialog"
          onClose={() => {
            this.props.loginModalClose();
            this.setState({ signinTab: false, signupTab: true });
          }}
        >
          {/* <div className="modal-header  mx-auto"> */}

          {/* </div> */}

          <ModalBody style={{ backgroundColor: '#fff' }}>
            {/* <button
              type="button"
              className="close  text-light"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.props.loginModalClose();
                this.setState({ signinTab: false, signupTab: true });
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
            <div className="tab-content" onSubmit={this.submitRegisterForm}>
              <h1 className="text-center vendorName">Create An Account </h1>
              {/* <span className="already">
                Already have an account?
                <NavLink className="mx-3 fw-bolder text-dark text-decoration-underline ">
                  Sign in
                </NavLink>
              </span> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.props.loginModalClose();
                  this.setState({ signinTab: false, signupTab: true });
                }}
              >
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/close-icon.6ef7d21d.svg"
                  alt=""
                />
              </button>
              <form className="register">
                <p className="form-row form-row-wide">
                  Email Address:
                  <input
                    type="text"
                    className="input-text"
                    name="email"
                    id="registeremail"
                    placeholder="Enter Email "
                    defaultValue=""
                    onInput={(event) =>
                      (event.target.value = event.target.value.toLowerCase())
                    }
                  />
                </p>
                <p className="form-row form-row-wide">
                  Password:
                  <input
                    className="input-text"
                    type="password"
                    name="registerpassword"
                    placeholder="Create password"
                    id="registerpassword"
                    defaultValue=""
                  />
                </p>
                {/* <p className="form-row form-row-wide">
                  Repeat Password:
                  <input
                    className="input-text"
                    type="password"
                    name="repeatpassword"
                    placeholder="Repeat password"
                    id="repeatpassword"
                    defaultValue=""
                  />
                </p> */}
                <div className="d-flex mx-auto justify-content-center mt-3">
                  <ReCAPTCHA
                    sitekey="6LcRwfglAAAAAPavkk-U_CgSm8EMRECqXdQQLzVH"
                    // onChange={handleRecaptcha}
                    onChange={this.handleRecaptchaChange}
                    onExpired={this.handleRecaptchaExpire}
                  />
                </div>
                <p className="formShortInfo mt-4 mx-5">
                  This information is used to create your account and WILL NOT
                  BE DISPLAYED ON YOUR PROFILE
                </p>

                <div className="customFormGroup">
                  <div className="d-flex mx-auto w-100 mt-3">
                    <button
                      type="submit"
                      className="signUpBtn  d-flex mx-auto"
                      name="register"
                      value="Register"
                      onClick={this.fetchData}
                    >
                      {loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: '5px' }}
                        />
                      )}

                      {!loading && <span>Sign up</span>}
                    </button>
                  </div>
                  {/* <button
                    type="submit"
                    className="signUpBtn mt-4 d-flex mx-auto"
                    name="register"
                    value="Register"
                  >
                    Sign up
                  </button> */}
                </div>
                <p className="formShortInfo  mx-5">
                  By signing up, you agree with the Terms of Service and Privacy
                  Policy
                </p>
              </form>
            </div>

            <div className="signUpWithBox">
              <span>Or Sign Up With</span>
            </div>
            <div className="socialLoginBoxesSignup mt-3">
              <button
                onClick={() => this.signUpWithApple(apple)}
                className="appleLogin"
              >
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/apple-icon.18d9951b.svg"
                  alt=""
                />
                <span>Apple ID</span>
              </button>
              <button onClick={() => this.signUpWithGoogle(google)}>
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/g-plus-icon.c93f23d6.svg"
                  alt=""
                />
                <span>Google</span>
              </button>
              {/* <button
                onClick={() => this.signUpWithFacebook(facebook)}
                className="facebookLogin"
              >
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/fb-fav-icon.0e844166.svg"
                  alt=""
                />
                <span>Facebook</span>
              </button> */}
            </div>
            <p className="formShortInfo mx-5 mt-5">
              We dont post anything to your social media, they are just used to
              create your account.
            </p>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default SignupModal;
